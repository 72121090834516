<template>
  <div class="loader">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
      color="#3f51b5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="wio-svg-loader"
    >
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0%" stop-opacity="0" stop-color="currentColor" />
          <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0%" stop-opacity="1" stop-color="currentColor" />
          <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
        </linearGradient>
      </defs>

      <g :stroke-width="size">
        <path
          stroke="url(#spinner-secondHalf)"
          :d="`M ${s} 100 A ${100 - s} ${100 - s} 0 0 1 ${200 - s} 100`"
        />
        <path
          stroke="url(#spinner-firstHalf)"
          :d="`M ${200 - s} 100 A ${100 - s} ${100 - s} 0 0 1 ${s} 100`"
        />

        <!-- 1deg extra path to have the round end cap -->
        <path
          stroke="currentColor"
          stroke-linecap="round"
          :d="`M ${s} 100 A ${100 - s} ${100 - s} 0 0 1 ${s} 98`"
        />
      </g>

      <animateTransform
        from="0 0 0"
        to="360 0 0"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1300ms"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "LoaderComponent",
  props: {
    w: {
      default: false,
    },
    h: {
      default: false,
    },
    size: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    s() {
      return this.size / 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.wio-svg-loader {
  color: var(--widget-osago-primary);
}
.loader {
  // border: 16px solid $white;
  // border-top: 16px solid rgba($white, 0.5);
  // border-radius: 50%;
  width: 150px;
  height: 150px;
  // animation: spin 1.5s linear infinite;

  // @keyframes spin {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }

  &.wio-input-loader {
    // position: absolute;
    // top: calc(50% - 15px);
    // right: 7px;
    // border: 4px solid $primary;
    // border-top: 4px solid transparent;
    // border-radius: 50%;
    // width: 30px;
    // height: 30px;
    // opacity: .5;

    // &:before{
    //   content: '';
    //   position: absolute;
    //   top: -4px;
    //   left: -4px;
    //   right: -4px;
    //   bottom: -4px;
    //   border: 4px solid $primary;
    //   border-radius: 50%;
    //   opacity: 0.5;
    // }
  }
}
</style>
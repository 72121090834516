<template>
  <div class="speed-of-payment">
    <span class="speed-of-payment__icon icon-clock" />
    <div class="speed-of-payment__text">
      <span class="grey">{{ text }}</span>
      <span class="value">
        {{ value }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpeedOfPayment',
  props: {
    dataObject: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    text () {
      return this.dataObject?.text
    },
    value () {
      return this.dataObject?.value
    }
  }
}
</script>

<style lang="scss" scoped>
.speed-of-payment {
  font-size: $base-font-size;
  line-height: 1.5;
  display: flex;
  margin-bottom: 25px;

  &__icon {
    font-size: 17px;
    margin-right: 10px;
    margin-top: 2px;
  }

  .grey {
    color: $grey-800;
  }
}
</style>

<template>
  <div class="wio-step wio-step-1">
    <div class="wio-step__content">
      <div class="wio-step-head" :class="{ 'loading-disabled': loading }">
        <h4 v-if="stepData.title" class="wio-h4" v-html="stepData.title" />
        <InfoLine v-if="stepData.infoLine" :lineData="stepData.infoLine" />
      </div>
      <UIStepHeading
        :items="stepList"
        :active-index="0"
        :way-flag="dataUser.wayIndex"
        :class="{ 'loading-disabled': loading }"
      />
      <TabsMenu
        v-if="tabsText"
        :way-index="dataUser.wayIndex"
        :data-object="tabsText"
        :class="{ 'loading-disabled': loading }"
      />
      <div ref="formWrapper" class="wio-form-wrapper">
        <div v-if="dataUser.wayIndex === 1" class="wio-form-row">
          <UIInput
            :id="stepData.fields.carNumber.id"
            v-model="$v.formInfo.carNumber.$model"
            :label="stepData.fields.carNumber.label || 'test'"
            :placeholder="stepData.fields.carNumber.placeholder"
            class="form-col-4"
            :class="{ disabled: loading }"
            :required="true"
            :show-required="true"
            data-key="carNumber"
            :error="!!errors.carNumber || $v.formInfo.carNumber.$error"
            :errorText="
              errors.carNumber
                ? errors.carNumber.messages[0]
                : stepData.fields.carNumber.error
            "
            @input="delayTouch($v.formInfo.carNumber, 'carNumber')"
          />
          <div
            :class="[
              'wio-input-wrapper form-col-4',
              { filled: formInfo.type },
              { error: $v.formInfo.type.$error },
              { disabled: loading },
            ]"
            data-key="type"
          >
            <label v-if="stepData.fields.type.label">
              {{ stepData.fields.type.label }}
              <span class="required">*</span>
            </label>
            <v-select
              v-model="$v.formInfo.type.$model"
              :searchable="false"
              :placeholder="stepData.fields.type.placeholder"
              :options="stepData.fields.type.values"
              label="name"
            >
              <div slot="no-options">
                {{ "notFaund" }}
              </div>
            </v-select>
            <span class="error-text">{{ stepData.fields.type.error }}</span>
          </div>
          <UIInput
            :id="stepData.fields.promo.id"
            v-model="$v.formInfo.promo.$model"
            :label="stepData.fields.promo.label || 'test'"
            :placeholder="stepData.fields.promo.placeholder"
            class="form-col-4"
            :class="{ disabled: loading }"
            :error="!!errors.promo || $v.formInfo.promo.$error"
            :error-text="
              errors.promo
                ? errors.promo.messages[0]
                : stepData.fields.promo.error
            "
            :loader="promoLoading"
            @input="delayTouch($v.formInfo.promo, 'promo')"
            @blur="checkPromo"
          />
        </div>
        <div v-if="!dataUser.wayIndex" class="wio-form-row">
          <div
            :class="[
              'wio-input-wrapper',
              'form-col-3',
              { 'read-only read-only-opacity': formInfo.euroNumber },
              { filled: formInfo.city },
              { error: $v.formInfo.city.$error },
              { disabled: loading },
            ]"
            data-key="city"
          >
            <label
              v-if="stepData.fields.city.label"
              :for="stepData.fields.city.id"
            >
              {{ stepData.fields.city.label }}
              <span class="required">*</span>
            </label>
            <v-select
              :id="stepData.fields.city.id"
              v-model="$v.formInfo.city.$model"
              push-tags
              :placeholder="stepData.fields.city.placeholder"
              :options="placeOptions"
              label="name"
              :filterable="false"
              @search="onSearch"
            >
              <div slot="no-options">
                {{ "notFaund" }}
              </div>
            </v-select>
            <span class="error-text">{{ stepData.fields.city.error }}</span>
          </div>
          <div
            :class="[
              'wio-input-wrapper',
              'select-list',
              'form-col-3',
              { filled: formInfo.vehicle },
              { error: $v.formInfo.vehicle.$error },
              { disabled: loading },
            ]"
            data-key="vehicle"
          >
            <label
              v-if="stepData.fields.vehicle.label"
              :for="stepData.fields.vehicle.id"
            >
              {{ stepData.fields.vehicle.label }}
              <span class="required">*</span>
            </label>
            <v-select
              :id="stepData.fields.vehicle.id"
              v-model="$v.formInfo.vehicle.$model"
              :searchable="false"
              :placeholder="stepData.fields.vehicle.placeholder"
              :options="getIngineList"
              label="name"
            >
              <template #option="option">
                <div v-if="option.listTitle" class="options-group-title">
                  {{ option.listTitle }}
                </div>
                <div class="option-wrapper">
                  <div
                    :class="[
                      'option-icon',
                      `icon-category_${setVehicleIcon(option.icon)}`,
                    ]"
                  />
                  <div class="option-text">
                    {{ option.name }}
                  </div>
                </div>
              </template>
              <template #selected-option="{ icon, name }">
                <div class="option-wrapper selected">
                  <div
                    :class="[
                      'option-icon',
                      `icon-category_${setVehicleIcon(icon)}`,
                    ]"
                  />
                  <div class="option-text">
                    {{ name }}
                  </div>
                </div>
              </template>
            </v-select>
            <span class="error-text">{{ stepData.fields.vehicle.error }}</span>
          </div>
          <div
            :class="[
              'wio-input-wrapper form-col-3',
              { filled: formInfo.type },
              { error: $v.formInfo.type.$error },
              { disabled: loading },
            ]"
            data-key="type"
          >
            <label v-if="stepData.fields.type.label">
              {{ stepData.fields.type.label }}
              <span class="required">*</span>
            </label>
            <v-select
              v-model="$v.formInfo.type.$model"
              :searchable="false"
              :placeholder="stepData.fields.type.placeholder"
              :options="stepData.fields.type.values"
              label="name"
            />
            <span class="error-text">{{ stepData.fields.type.error }}</span>
          </div>
          <UIInput
            :id="stepData.fields.promo.id"
            v-model="$v.formInfo.promo.$model"
            :label="stepData.fields.promo.label || 'test'"
            :placeholder="stepData.fields.promo.placeholder"
            class="form-col-3"
            :class="{ disabled: loading }"
            :error="!!errors.promo || $v.formInfo.promo.$error"
            :error-text="
              errors.promo
                ? errors.promo.messages[0]
                : stepData.fields.promo.error
            "
            data-key="promo"
            :loader="promoLoading"
            @input="delayTouch($v.formInfo.promo, 'promo')"
            @blur="checkPromo"
          />
        </div>
        <div class="wio-form-row">
          <div class="wio-custom-input-wrapper">
            <UIInput
              :id="stepData.fields.privilegesBox.id"
              v-model="formInfo.privilegesBox"
              :class="classListForPrivilegesBox"
              :label="`${stepData.fields.privilegesBox.label} ${getPrivilegesValue}`"
              type="checkbox"
              :tooltip="stepData.fields.privilegesBox.tooltip"
            />
            <div
              :class="[
                'input-wrapper select-wrapper',
                { 'read-only': ifTypeLegal },
                { filled: formInfo.privileges },
                { error: $v.formInfo.privileges.$error },
                { disabled: loading },
              ]"
            >
              <v-select
                v-model="$v.formInfo.privileges.$model"
                :searchable="false"
                :placeholder="stepData.fields.privileges.placeholder"
                :options="stepData.fields.privileges.values"
                label="name"
              />
            </div>
          </div>
          <UIInput
            v-if="stepData.fields.euroNumber"
            :id="stepData.fields.euroNumber.id"
            v-model="formInfo.euroNumber"
            :label="stepData.fields.euroNumber.label"
            type="checkbox"
            class="form-col-auto checkbox-new-style"
            :class="{ disabled: loading }"
            :tooltip="stepData.fields.euroNumber.tooltip"
          />
          <UIInput
            v-if="stepData.fields.taxi"
            :id="stepData.fields.taxi.id"
            v-model="formInfo.taxi"
            :label="stepData.fields.taxi.label"
            type="checkbox"
            class="form-col-auto checkbox-new-style"
            :class="{ disabled: loading }"
            :tooltip="stepData.fields.taxi.tooltip"
          />
        </div>
        <div class="wio-btn-wrapper btn-center">
          <UIButton
            :text="stepData.btnText"
            class="btn-green"
            @click="submitHandler"
            :loader="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import InfoLine from "@/components/UI/InfoLine.vue";
import TabsMenu from "@/components/UI/TabsMenu.vue";
import { debounce, cloneDeep } from "lodash";
import axios from "axios";
import CONSTANTS from "@/helper/constants";

const touchMap = new WeakMap();

export default {
  name: "wioStep1",
  components: {
    InfoLine,
    TabsMenu,
  },
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isValidation: true,
      formInfo: {},
      placeOptions: null,
      promoLoading: false,
    };
  },
  created() {
    // this.isValidation = this?.stepData?.validation
    this.userStore.setUserOne({ key:'filter3step', payload: null });
    this.placeOptions = this.stepData?.fields?.city?.values;
    this.formInfo = cloneDeep(this.dataUser);

    if(this.formInfo?.promo){
      this.checkPromo(this.formInfo.promo)
    }
  },
  watch: {
    "formInfo.privilegesBox": {
      handler() {
        if (this.formInfo.privilegesBox) {
          this.formInfo.taxi = false;
          this.formInfo.euroNumber = false;

          const ifHas = this.getIngineList?.some((item) => {
            return (
              this.formInfo.vehicle && item.id === this.formInfo.vehicle.id
            );
          });

          if (!ifHas) {
            this.formInfo.vehicle = null;
          }
        }
      },
    },
    "formInfo.taxi": {
      handler(value) {
        if (value) {
          this.formInfo.privilegesBox = false;
          this.formInfo.euroNumber = false;
          this.formInfo.privileges = null;
        }
      },
    },
    "formInfo.euroNumber": {
      handler(value) {
        if (value) {
          this.formInfo.privilegesBox = false;
          this.formInfo.taxi = false;
          this.formInfo.privileges = null;
          this.formInfo.city = this.stepData?.fields?.city?.valueEvroNumber;
        } else if(this.formInfo.city?.id === this.stepData?.fields?.city?.valueEvroNumber?.id){
          this.formInfo.city = null
        }
      },
    },
    "formInfo.type.id": {
      handler(value) {
        if (value === "LEGAL") {
          this.formInfo.privilegesBox = false;
        }
      },
    },
    "dataUser.wayIndex": {
      handler(value) {
        this.formInfo.wayIndex = value || 0;
      },
    },
    "formInfo.privileges": {
      handler() {
        this.formInfo.privilegesBox =
          this.formInfo?.privileges?.name &&
          this.formInfo?.privileges?.id !== "noChoice";
      },
    },
  },
  methods: {
    ...mapActions(userData, ["setBackError"]),
    delayTouch($v, key) {
      if (key === "carNumber") {
        this.formInfo.carNumber = this.formInfo.carNumber.toUpperCase();
      }

      if (this.errors[key]) {
        this.setBackError({
          payload: false,
          key,
        });
      }
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.search(loading, search, this);
      } else if (!search.length) {
        this.placeOptions = this.stepData.fields.city.values;
      }
    },
    search: debounce((loading, search, vm) => {
      axios
        .post(`${CONSTANTS.BASE_URL}city`, { params: { city: search } })
        .then((res) => {
          vm.placeOptions = res.data;
          loading(false);
        })
        .catch((error) => console.log(error));
    }, 350),
    setVehicleIcon(icon) {
      if (!icon) {
        return "b";
      }
      if (icon === "E") {
        return "f";
      }
      return icon.toLowerCase();
    },
    submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        const element = this.$refs.formWrapper;

        if (!element) {
          return;
        }

        setTimeout(() => {
          const errorElement = element.querySelectorAll(
            ".input-wrapper.error"
          )[0];

          if (!errorElement) {
            return;
          }

          const { top } = errorElement.getBoundingClientRect();

          if (top > 30) {
            return;
          }

          window.scroll(0, window.scrollY + top - 30);
        }, 10);
        console.log("error");
      } else {
        Object.keys(this.formInfo).forEach((key) => {
          this.userStore.setUserOne({ key, payload: this.formInfo[key] });
        });

        this.$emit("changeStep");
      }
    },
    async checkPromo() {
      if (!this.formInfo?.promo) {
        return;
      }

      this.promoLoading = true;
      this.userStore.setUserOne({ key: "promo", payload: this.formInfo.promo });
      try {
        await axios
          .post(`${CONSTANTS.BASE_URL}promo`, {
            params: {
              userData: { ...this.dataUser },
              key: userData().getUserKey, 
              lang: "ua",
              internetSalesToken: userData().getInternetSalesToken,
              token: userData()?.getTokenId,
              telegramToken: userData().getTelegramToken,
              telegramSign: userData().telegramSign,
              telegramGroup: userData().telegramGroup
            },
          })
          .then(({ data }) => {
            this.promoLoading = false
            if(data.errors){
              this.setBackError({
                payload: {
                  messages: [data.errors?.messages?.[0]],
                },
                key: "promo",
              });
            }
          });
      } catch (error) {
        this.promoLoading = false
        console.log(error);
      }
    },
  },
  computed: {
    dataUser() {
      return this.userStore.getUser;
    },
    errors() {
      return this.userStore?.getBackErrors;
    },
    stepData() {
      return this.stepStore.getStep1;
    },
    tabsText() {
      return this.stepData?.tabs?.text;
    },
    getIngineList() {
      let newList = [];
      const oldList = cloneDeep(
        this.stepData.fields.vehicle[
          this.formInfo.privilegesBox ? "privilegesList" : "values"
        ]
      );

      oldList.forEach((item) => {
        item.list[0].listTitle = item.name;
        newList = [...newList, ...item.list];
      });

      return newList;
    },
    ifTypeLegal() {
      if (this.formInfo.type) {
        return this.formInfo.type.id === "LEGAL";
      }
      return false;
    },
    getPrivilegesValue() {
      if (this.formInfo?.privileges?.id === "noChoice") {
        return "";
      }

      return this.formInfo?.privileges?.name || "";
    },
    classListForPrivilegesBox() {
      return [
        "form-col-auto",
        "checkbox-new-style",
        { "read-only": this.ifTypeLegal },
        { disabled: this.loading },
      ];
    },
    stepList() {
      return this.stepData.stepList;
    },
  },
  validations() {
    let val = {
      formInfo: {
        promo: {
          minLength: minLength(1),
          maxLength: maxLength(20),
        },
        privileges: {
          required: this.formInfo.privilegesBox ? required : false,
        },
        type: {
          required,
        },
      },
    };

    if (!this.isValidation) {
      val = {
        formInfo: {
          promo: {
            required: false,
          },
          privileges: {
            required: false,
          },
          type: {
            required: false,
          },
        },
      };
    }

    if (this.dataUser.wayIndex === 1) {
      val.formInfo.carNumber = this.isValidation
        ? {
            required,
            minLength: minLength(3),
            maxLength: maxLength(9),
          }
        : {
            required: false,
          };
    } else {
      val.formInfo.city = this.isValidation
        ? {
            required: this.formInfo.euroNumber ? false : required,
          }
        : {
            required: false,
          };
      val.formInfo.vehicle = this.isValidation
        ? {
            required,
          }
        : {
            required: false,
          };
    }

    return val;
  },
};
</script>

<style lang="scss" scoped>
.wio-step-1 {
  // display: flex;

  .wio-step-head {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-bottom: 7px;

    @include media(">=md") {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
    }

    @include media("<md") {
      max-width: 380px;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .heading,
    .title-wrapper {
      margin-bottom: 0;
    }

    & > * {
      @include media(">=md") {
        max-width: 50%;
      }
    }
  }
}

.wio-step {
  &__content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .wio-btn-wrapper {
      padding-top: 20px;

      @include media(">=md") {
        padding-top: 15px;
      }
    }
  }

  &__img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-top: 60px;
  }
}
</style>
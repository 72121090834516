<template>
  <div class="wio-info-line">
    <div v-if="lineData.text" class="wio-info-line__text">
      {{ lineData.text }}
    </div>
    <div
      v-tippy="{ onShow: () => this.tooltip }"
      :content="lineData.tooltip"
      class="wio-info-line__icon"
    />
  </div>
</template>

<script>
export default {
  name: "InfoLine",
  props: {
    lineData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tooltip() {
      return this.lineData?.tooltip || false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wio-info-line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: $base-font-size-s;
  color: $secondary;
  margin-bottom: 10px;

  @include media(">=md") {
    margin-bottom: 0;
  }

  &__icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    margin-top: -1px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $secondary;
    transition: $transition;

    &:hover {
      border-color: $primary;

      &::before {
        color: $primary;
      }
    }

    &::before {
      color: $secondary;
      content: $icon-question;
      font-family: $icomoon-font-family;
      font-size: 10px;
    }
  }
}
</style>
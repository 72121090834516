const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
}

export default function df (date, separator) {
    if (!isValidDate(date)) { return '' }

    const result = date.toISOString().split('T')[0]

    return separator ? result.split('-').reverse().join('.') : result
}

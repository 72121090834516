import { render, staticRenderFns } from "./FlagsList.vue?vue&type=template&id=58ab29b7&scoped=true&"
import script from "./FlagsList.vue?vue&type=script&lang=js&"
export * from "./FlagsList.vue?vue&type=script&lang=js&"
import style0 from "./FlagsList.vue?vue&type=style&index=0&id=58ab29b7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ab29b7",
  null
  
)

export default component.exports
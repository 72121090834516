import Vue from 'vue'
import App from './App.vue'

import './styles/main.scss'


//Plugins
import { createPinia, PiniaVuePlugin } from "pinia"
import VueTippy, { TippyComponent } from "vue-tippy"
import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import ClickOutside from 'vue-click-outside'
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


//Custom components
import UIImage from './components/UI/Image'
import UIInput from './components/UI/Input'
import UIButton from './components/UI/Button'
import UILoader from './components/UI/Loader'
import UIPrevValue from './components/UI/PrevValue'
import UIProposal from './components/UI/Proposal'
import UIToolTip from './components/UI/ToolTip'
import UISideBar from './components/UI/SideBar'
import UIPayCard from './components/UI/PayCard'
import UIDatePicker from './components/UI/DatePicker'
import UIAttention from './components/UI/Attention'
import UIInputTell from './components/UI/InputTell'
import UIInputCode from './components/UI/InputCode'
import UIErrorMessage from './components/UI/ErrorMessage'
import UIStepHeading from './components/UI/StepHeading'

const ID = '#inpolis-osago';
const pinia = createPinia();

Vue.config.productionTip = true

Vue.use(pinia)
Vue.use(PiniaVuePlugin)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2,{
  target: '#inpolis-osago'
})
Vue.use(VueMask, {
  placeholders: {
      Я: /[а-яА-ЯёЁa-zA-Z]/
  }
})
Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  animation: 'fade',
  theme: 'light',
  maxWidth: 200,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }
});

Vue.directive('click-outside', ClickOutside)
Vue.directive('click-outside', ClickOutside)

Vue.component('UIImage', UIImage)
Vue.component('UIInput', UIInput)
Vue.component('UIButton', UIButton)
Vue.component('UILoader', UILoader)
Vue.component('UIPrevValue', UIPrevValue)
Vue.component('UIPrevValue', UIPrevValue)
Vue.component('UIProposal', UIProposal)
Vue.component('UIProposal', UIProposal)
Vue.component('UIToolTip', UIToolTip)
Vue.component('UISideBar', UISideBar)
Vue.component('UIPayCard', UIPayCard)
Vue.component('UIDatePicker', UIDatePicker)
Vue.component('UIAttention', UIAttention)
Vue.component('UItippy', TippyComponent)
Vue.component('UIInputTell', UIInputTell)
Vue.component('UIInputCode', UIInputCode)
Vue.component('UIErrorMessage', UIErrorMessage)
Vue.component('UIStepHeading', UIStepHeading)
Vue.component('DataPick', DatePick)
Vue.component('VSelect', vSelect)

const rootElement = document?.querySelector(ID)
const token = rootElement?.dataset?.token || null
const lang = rootElement?.dataset?.lang || null
const internetSalesToken = rootElement?.dataset?.salesToken || null
const telegramSign = rootElement?.dataset?.telegramSign || null
const telegramToken = rootElement?.dataset?.telegramToken || null
const telegramGroup = rootElement?.dataset?.telegramGroup || null
const redirectUrl = rootElement?.dataset?.redirectUrl || null
const backUrl = rootElement?.dataset?.backUrl || null

new Vue({
  data: {
    test: 'test',
    token,
    lang,
    internetSalesToken,
    telegramSign,
    telegramToken,
    telegramGroup,
    redirectUrl,
    backUrl,
  },
  render: h => h(App),
}).$mount(ID)

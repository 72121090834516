<template>
  <div class="offer-drop">
    <div class="offer-drop__option-side">
      <div
        v-for="(item, index) in optionList"
        :key="`${item.title}${index}`"
        class="offer-drop__list"
      >
        <div class="offer-drop__title">
          {{ item.title }}
        </div>
        <div class="offer-drop__list-content">
          <div
            v-for="(listItem, idx) in item.list"
            :key="`${listItem}${idx}`"
            class="offer-drop__list-content__item"
          >
            <div class="offer-drop__list-content__item--icon">
              <span class="icon-success" />
            </div>
            <div class="offer-drop__list-content__item--text">
              {{ listItem }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offer-drop__content-side">
      <div class="offer-drop__title">
        {{ content.title }}
      </div>
      <p v-for="item in text" :key="item">
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferDrop",
  props: {
    dataObject: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  computed: {
    optionList() {
      return this.dataObject?.optionsList;
    },
    content() {
      return this.dataObject?.content;
    },
    text() {
      return this.content?.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-drop {
  max-width: 100%;
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;

  @include media(">=lg") {
    flex-direction: row;
    border-top: 1px solid $grey-200;
    padding: 30px 0 40px 10px;
  }

  @include media("<lg") {
    padding: 20px 30px 0;
  }

  @include media("<md") {
    padding: 20px 0 0;
  }

  &__option-side {
    margin-bottom: 30px;

    @include media(">=lg") {
      width: 36%;
      min-width: 36%;
      padding-right: 15px;
      margin-bottom: 0;
    }
  }

  &__content-side {
    font-size: $base-font-size;
    line-height: 1.5em;

    @include media(">=lg") {
      width: 53%;
      min-width: 53%;
    }

    p {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-size: $base-font-size-xl;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 20px;
  }

  &__list {
    & + & {
      margin-top: 30px;
    }
  }

  &__list-content {
    &__item {
      display: flex;
      font-size: $base-font-size;

      & + & {
        margin-top: 10px;
      }

      &--icon {
        color: $primary;
        font-size: $base-font-size-s;
        margin-top: 3px;
        margin-right: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="wio-step wio-step-7">
    <div class="btn-back-wrapper" :class="{ 'loading-disabled': loading }">
      <UIButton
        :text="stepData.btnPrevText"
        class="btn-go-back"
        @click="$emit('prevStep')"
      />
    </div>
    <UIStepHeading
      :items="stepList"
      :active-index="5"
      :way-flag="dataUser.wayIndex"
      :class="{ 'loading-disabled': loading }"
    />
    <div class="wio-step-sidebar">
      <div class="wio-step-left">
        <div class="step-content">
          <h5 v-if="title" class="step-content__title">{{ title }}</h5>

          <div class="way-wrapper">
            <div 
            class="payment-way-item" 
            v-for="input in wayValues" 
            :key="input.id"
            :class="{'info-wrap' : input.infoLine}">
              <UIInput
                :id="input.id"
                v-model="paymentWay"
                :name="'paymentWay'"
                :label="input.name"
                :type="'radio'"
                :input-value="input"
              />
              <InfoLine class="info-target" v-if="input.infoLine" :lineData="input.infoLine" />
              <UIImage class="payment-way-item__image" width="37" height="36" v-if="input.file" :image-data="input.file" loading="lazy" />
            </div>
          </div>
          <div class="line"></div>
          <UIPayCard
            v-if="stepData.payment"
            :text="paymentText"
            :value="paymentValue"
            class="pay-card-step-7"
          />
          <div class="btn-step-footer">
            <UIButton
              :text="paymentWay.id == 0 ? stepData.btnDownloadText : stepData.btnNextText"
              @click="submitHandler"
              class="mb-20"
              :class="{ 'btn-disabled': loading }"
              :loader="loading"
            />
          </div>
        </div>
      </div>
      <div class="wio-step-right">
        <UISideBar v-if="sideBarData" :data-object="sideBarData" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import CONSTANTS from "@/helper/constants";
import InfoLine from "@/components/UI/InfoLine.vue";
import { minLength, maxLength } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "wioStep7",
  components:{
    InfoLine,
  },
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      paymentWay: null,
      paymentText: "",
      paymentValue: "",
      promo: "",
      promoLoader: false,
      promoActive: false,
      pdfUrl: "",
    };
  },
  created() {
    this.GTMPush()

    if (this.stepData?.payment) {
      this.paymentText = this.stepData?.payment?.text;
      this.paymentValue = this.stepData?.payment?.price;
    }

    if(this?.dataUser?.paymentWay){
      this.paymentWay = this.dataUser.paymentWay;
    } else if(this.wayValues.length){
      this.paymentWay = this.wayValues[1];
    }

    if (this?.dataUser?.promo) {
      this.promo = this.dataUser.promo;

      setTimeout(() => {
        this.promoActive = true;
      });
    }

    if (this.stepData?.pdfLink) {
      this.pdfUrl = this.stepData.pdfLink;
    }
  },
  watch: {
    promo: {
      handler() {
        this.promoActive = false;
        this.setBackError({
          payload: false,
          key: "promo",
        });
      },
    },
  },
  computed: {
    dataUser() {
      return this.userStore.getUser;
    },
    stepData() {
      return this.stepStore.getStep7;
    },
    ifBackErrors() {
      let result = false;

      if (!Object.keys(this.errors).length) {
        return result;
      }

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key]) {
          result = true;
        }
      });

      return result;
    },
    errors() {
      return this.userStore?.getBackErrors;
    },
    title() {
      return this?.stepData?.title;
    },
    sideBarData() {
      return this.stepData?.sideBar;
    },
    wayValues() {
      return this.stepData?.fields?.paymentWay?.values || [];
    },
    promoClass() {
      return [
        "btn-secondary btn-square",
        { "btn-active": this.promoActive },
        {
          disabled:
            this.promoDisabled || this.errors.promo || this.$v.promo.$error,
        },
      ];
    },
    stepList() {
      return this.stepData.stepList;
    },
  },
  methods: {
    ...mapActions(userData, ["setBackError"]),
    submitHandler() {
      if (
        (this.paymentWay?.id === "0" ||
          this.paymentWay?.id === 0 ||
          this.paymentWay === 0) &&
        window
      ) {
        window.open(this.pdfUrl, "_blank").focus();
        return;
      }

      this.userStore.setUserOne({ key: "paymentWay", payload: this.paymentWay });

      this.$emit("changeStep");
    },
    async checkPromo() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.promoLoader = true;
      this.userStore.setUserOne({ key: "promo", payload: this.promo });

      try {
        await axios
          .post(`${CONSTANTS.BASE_URL}promo`, {
            params: {
              userData: { ...this.dataUser },
              key: userData().getUserKey, 
              lang: "ua",
              internetSalesToken: userData().getInternetSalesToken,
              token: userData()?.getTokenId,
              telegramToken: userData().getTelegramToken,
              telegramSign: userData().telegramSign,
              telegramGroup: userData().telegramGroup
            },
          })
          .then(({ data }) => {
            if (data?.success) {
              this.promoLoader = false;
              this.promoActive = true;
              this.paymentValue = data?.price;

              if (data?.payLink) {
                this.userStore.setUserOne({
                  key: "payLink",
                  payload: data?.payLink,
                });
              }

              if (data?.pdfLink) {
                this.pdfUrl = data.pdfLink;
              }

              if (data?.user && data?.user?.type === "agent") {
                this.userStore.setUserOne({
                  key: "promoData",
                  payload: data?.user,
                });
              } else {
                this.setPromo(null);
              }
            } else if (data?.error) {
              this.promoLoader = false;
              this.userStore.setUserOne({ key: "promoData", payload: null });
              this.userStore.setUserOne({ key: "promo", payload: null });
              this.setBackError({
                payload: {
                  messages: [data.error],
                },
                key: "promo",
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    GTMPush(){
      if(window && window.dataLayer){
        const self = this

        console.log('GTM add_shipping_info')

        window.dataLayer.push({
          event: 'add_shipping_info',
          ecommerce: {
            items: [{
              item_name: 'widget_osago',
              item_id: self.dataUser?.offer?.id,
              price: self.dataUser?.offer?.franchiseActive?.price?.price,
              item_brand: self.dataUser?.offer?.company?.title,
              item_category: self.dataUser?.type?.name,
              item_category2: self.dataUser?.vehicle?.name,
              item_category3: self.dataUser?.offer?.about?.list,
              item_variant: self.dataUser?.offer?.offerFranchise,
              quantity: 1
            }]
          }
        })
      }
    }
  },
  validations() {
    return {
      promo: {
        minLength: minLength(1),
        maxLength: maxLength(20),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wio-step-7 {
  .wio-step-left {
    @include media(">=lg") {
      padding-right: 80px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        width: 1px;
        background: $primary;
        opacity: 0.1;
      }
    }
  }

  .payment-way-item{
    display: flex;
    // align-items: center;
    justify-content: space-between;

    .info-target{
      margin-bottom: 15px;
    }

    &__image{
      width: 32px;
      height: 32px;

      @include media('<md') {
       transform: translateY(-6px);
       margin-right: 10px;
      }
    }
  }

  .step-content {
    width: 100%;
    max-width: 100% !important;
  }

  h5 {
    margin-bottom: 55px !important;
  }
  .promo-wrapper {
    margin: 0 0 0 -10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .wio-input-wrapper {
      margin-bottom: 0;
      width: calc(100% - 145px);

      @include media("<md") {
        width: calc(100% - 90px);
      }
    }

    .wio-btn {
      max-width: 140px;
      min-height: 44px;
      height: 44px;
      padding-right: 5px;
      padding-left: 5px;

      @include media("<md") {
        font-size: $base-font-size-s;
        max-width: 90px;
      }

      &.btn-active {
        pointer-events: none;
        font-size: 0;

        .icon-success {
          margin-right: 0;
          font-size: $base-font-size-s;
        }
      }
    }

    .icon-success {
      margin-right: 5px;
    }
  }

  .way-wrapper {
    margin: 0 -15px;
    max-width: 370px;

    @include media('<md') {
      margin: 0 -10px;
    }

    @include media('>=lg') {
      max-width: 424px;
    }
  }

  .wio-input-wrapper {
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: $primary;
    opacity: 0.1;
    margin: 15px 0 30px;
  }

  .pay-card-step-7 {
    max-width: 454px;
  }

  .btn-step-footer {
    align-items: flex-start !important;

    @include media(">=md") {
      padding-top: 40px !important;
    }
  }
}
</style>
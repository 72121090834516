var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type.toLowerCase() == 'checkbox')?_c('div',{class:[
    'wio-input-wrapper',
    'wio-checkbox-wrapper',
    { 'wio-checkbox-checked': _vm.value },
    { error: _vm.error }
  ]},[_c('label',{directives:[{name:"tippy",rawName:"v-tippy",value:({ onShow: () => !!_vm.tooltip }),expression:"{ onShow: () => !!tooltip }"}],attrs:{"content":_vm.tooltip,"for":_vm.id}},[(_vm.label)?_c('span',{staticClass:"label-text"},[_vm._t("label"),_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.showRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()],2):_c('span',{staticClass:"label-text"},[_vm._t("label")],2),_c('input',{ref:"input",staticClass:"wio-input wio-chackbox",attrs:{"id":_vm.id,"name":_vm.name ? _vm.name : _vm.placeholder.split(' ').join(''),"type":_vm.type,"placeholder":_vm.placeholder},domProps:{"checked":_vm.value,"value":_vm.value},on:{"change":_vm.changeHandler}}),_c('span',{staticClass:"checkmark"})])]):(_vm.type.toLowerCase() == 'radio')?_c('div',{class:[
    'wio-input-wrapper',
    'wio-radio-wrapper',
    { 'wio-radio-checked': _vm.checked },
    { error: _vm.error }
  ]},[_c('label',{directives:[{name:"tippy",rawName:"v-tippy",value:({ onShow: () => !!_vm.tooltip }),expression:"{ onShow: () => !!tooltip }"}],attrs:{"content":_vm.tooltip,"for":_vm.id}},[_c('input',{ref:"input",staticClass:"wio-input wio-radio",attrs:{"id":_vm.id,"name":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder},domProps:{"checked":_vm.checked,"value":_vm.value},on:{"change":_vm.changeHandler}}),_c('span',{staticClass:"checkmark"}),(_vm.label)?_c('span',{staticClass:"label-text"},[_vm._t("label"),_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.showRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()],2):_c('span',{staticClass:"label-text"},[_vm._t("label")],2)])]):(_vm.type.toLowerCase() == 'textarea')?_c('div',{class:['wio-input-wrapper', { error: _vm.error }]},[(_vm.label)?_c('label',{directives:[{name:"tippy",rawName:"v-tippy",value:({ onShow: () => !!_vm.tooltip }),expression:"{ onShow: () => !!tooltip }"}],attrs:{"content":_vm.tooltip,"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.showRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('textarea',{ref:"input",staticClass:"wio-input wio-textarea",attrs:{"id":_vm.id,"name":_vm.name ? _vm.name : _vm.placeholder.split(' ').join(''),"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.changeHandler,"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false}}}),_vm._v(" "),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorText))])]):_c('div',{class:[
    'wio-input-wrapper',
    { error: _vm.error },
    { focus: _vm.focus },
    { 'input-wrapper-tooltip': _vm.tooltip }
  ]},[(_vm.label)?_c('label',{attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.showRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e(),(_vm.tooltip)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ onShow: () => !!_vm.tooltip }),expression:"{ onShow: () => !!tooltip }"}],staticClass:"input-tooltip",attrs:{"content":_vm.tooltip}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-holder"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.checkMask),expression:"checkMask"}],ref:"input",staticClass:"wio-input",attrs:{"id":_vm.id,"name":_vm.name ? _vm.name : _vm.placeholder,"type":_vm.type,"inputmode":_vm.inputmode,"pattern":_vm.pattern,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.changeHandler,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enterPush')},"blur":_vm.blurHandler,"focus":function($event){_vm.focus = true}}}),_vm._t("inputbtn"),(_vm.loader)?_c('UILoader',{staticClass:"wio-input-loader"}):_vm._e(),(_vm.focus && _vm.options && _vm.options.length)?_c('div',{staticClass:"input-option-wrapper"},[_c('div',{staticClass:"ovh"},_vm._l((_vm.options),function(item,i){return _c('div',{key:`${item}${i}`,staticClass:"input-option",on:{"click":function($event){return _vm.listClickHandler(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e()],2),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorText))])])
}
var staticRenderFns = []

export { render, staticRenderFns }
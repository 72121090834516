<template>
  <div class="wio-step wio-step-6">
    <div class="btn-back-wrapper" :class="{ 'loading-disabled': loading }">
      <UIButton
        :text="stepData.btnPrevText"
        class="btn-go-back"
        @click="$emit('prevStep')"
      />
    </div>
    <UIStepHeading
      :items="stepList"
      :active-index="4"
      :way-flag="dataUser.wayIndex"
      :class="{ 'loading-disabled': loading }"
    />
    <div v-if="title" class="step-heading">
      <div class="heading-wrapper">
        <h3>{{ title }}</h3>
      </div>
      <div class="sub-title">
        {{ stepData.subTitle }}
      </div>
    </div>
    <div class="code-wrapper">
      <div class="otp-lable lable">
        {{ stepData.fields.otp.label }}
      </div>
      <UIInputCode
        v-model="otpCode"
        :class="{ 'code-error': errors.otp || otpCodeError }"
        @input="changeOtpCode"
      />
      <div :class="['resend-sms', { disabled: curentTimer > 0 }]">
        <span>{{ stepData.resendBtn.startText }}</span>
        <a href="#" @click.prevent="resendSms" class="resend-sms-link">{{
          stepData.resendBtn.btnText
        }}</a>
        <span v-if="curentTimer > 0">{{
          `${stepData.resendBtn.endText} ${showTime} ${stepData.resendBtn.timeText}`
        }}</span>
      </div>

      <div class="line"></div>

      <div class="policy-wrapper">
        <div
          class="policy-content"
          v-html="stepData.fields.privetPolicy.label"
        />
      </div>
      <UIAttention class="otp-attention">
        {{ stepData.atentionText }}
      </UIAttention>

      <div class="btn-step-footer">
        <UIButton
          :text="stepData.btnNextText"
          :class="{ 'btn-disabled': loading }"
          :loader="loading"
          class="mb-20"
          @click="submitHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import CONSTANTS from "@/helper/constants";
import axios from "axios";

export default {
  name: "wioStep6",
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      otpCode: "",
      otpError: false,
      timer: 60,
      curentTimer: 0,
      timerInstans: null,
      privacyPolicy: false,
      isBtnPres: false,
    };
  },
  created() {
    this.GTMPush();
    this.startTimer();
  },
  computed: {
    dataUser() {
      return this.userStore.getUser;
    },
    stepData() {
      return this.stepStore.getStep6;
    },
    ifBackErrors() {
      let result = false;

      if (!Object.keys(this.errors).length) {
        return result;
      }

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key]) {
          result = true;
        }
      });

      return result;
    },
    errors() {
      return this.userStore?.getBackErrors;
    },
    title() {
      return this?.stepData?.title;
    },
    showTime() {
      const min = Math.floor(this.curentTimer / 60);
      const sec = this.curentTimer % 60;

      return `${min}:${sec < 10 || sec === 0 ? `0${sec}` : sec}`;
    },
    otpCodeError() {
      return this.isBtnPres && this.otpCode?.length !== 6;
    },
    stepList() {
      return this.stepData.stepList;
    },
  },
  watch: {
    "errors.otp": {
      handler(v) {
        if (v) {
          this.otpCode = "";
        }
      },
    },
  },
  methods: {
    ...mapActions(userData, ["setBackError"]),
    submitHandler() {
      this.isBtnPres = true;
      if (this.otpCode.length === 6) {
        this.userStore.setUserOne({key: 'otpCode', payload: this.otpCode })
        this.$emit("changeStep");
      }
    },
    changeOtpCode() {
      this.setBackError({
        payload: false,
        key: "otp",
      });
    },
    startTimer() {
      clearInterval(this.timerInstans);
      this.curentTimer = this.timer;

      this.timerInstans = setInterval(() => {
        if (this.curentTimer > 0) {
          this.curentTimer -= 1;
        } else {
          clearInterval(this.timerInstans);
        }
      }, 1000);
    },
    async resendSms() {
      await axios.post(
        `${CONSTANTS.BASE_URL}resend/sms/${this.dataUser.contractId}`,
        {
          params: {
            userData: this.userData,
            key: userData().getUserKey, 
            type: "osago",
            internetSalesToken: userData().getInternetSalesToken,
            token: userData()?.getTokenId,
            telegramToken: userData().getTelegramToken,
            telegramSign: userData().telegramSign,
            telegramGroup: userData().telegramGroup
          },
        }
      );
      this.startTimer();
    },
    GTMPush(){
      if(window && window.dataLayer){
        const self = this

        console.log('GTM otp')

        window.dataLayer.push({
          event: 'otp',
          ecommerce: {
            items: [{
              item_name: 'widget_osago',
              item_id: self.dataUser?.offer?.id,
              price: self.dataUser?.offer?.franchiseActive?.price?.price,
              item_brand: self.dataUser?.offer?.company?.title,
              item_category: self.dataUser?.type?.name,
              item_category2: self.dataUser?.vehicle?.name,
              item_category3: self.dataUser?.offer?.about?.list,
              item_variant: self.dataUser?.offer?.offerFranchise,
              quantity: 1
            }]
          }
        })
      }
    }
  },
  validations() {},
};
</script>

<style lang="scss" scoped>
.wio-step-6 {
  text-align: center;

  .btn-back-wrapper {
    display: flex;
    justify-content: start;
  }

  ::v-deep {
    .policy-wrapper {
      .label-text {
        padding-left: 10px;
        font-size: 10px !important;
        text-align: left;

        @include media(">=md") {
          font-size: $base-font-size-s !important;
        }
      }

      a {
        color: $secondary;
      }
      .error {
        background: rgba($red, 0.1);
        border-radius: 20px;
      }
    }
  }

  .policy-content {
    color: $grey-800;
    font-size: $base-font-size-s;
    margin-bottom: 20px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .policy-wrapper {
    margin: 0 -10px;
  }

  .otp-attention {
    margin: 0 auto 10px !important;
  }

  .step-heading {
    margin-bottom: 30px;

    @include media(">=md") {
      margin-bottom: 45px;
    }
  }

  .heading-wrapper {
    margin-bottom: 13px;

    @include media(">=md") {
      margin-bottom: 23px;
    }
  }

  .sub-title {
    font-size: $base-font-size-s;

    @include media("<md") {
      max-width: 270px;
      margin: 0 auto;
    }

    @include media(">=md") {
      font-size: 18px;
    }
  }

  .code-wrapper {
    max-width: 588px;
    margin: 0 auto;

    .lable {
      font-size: $base-font-size-s;
      margin-bottom: 12px;

      @include media(">=md") {
        font-size: 14px;
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: $grey-200;
    margin: 20px 0;

    @include media(">=md") {
      margin: 25px 0 40px;
    }
  }

  .resend-sms {
    font-size: $base-font-size-s;

    @include media(">=md") {
      font-size: 14px;
    }

    &.disabled {
      a {
        pointer-events: none;
        color: $grey-800;
      }
    }

    a {
      font-weight: 600;
      color: $primary;
      padding: 0 6px;
    }
  }
}
</style>
<template>
  <div
    :class="['wio-input-wrapper', 'input-tell', { error: error }, { focus: focus }]"
  >
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="showRequired" class="required">*</span>
    </label>
    <div class="input-holder" @click="$refs.tell.focus()">
      <div class="input-prefix">
        {{ prefix }}
      </div>
      <!-- :placeholder="'--) --- -- --'" -->
      <input
        :id="id"
        ref="tell"
        v-mask="checkMask"
        :name="name ? name : placeholder"
        :type="type"
        :inputmode="inputmode"
        :pattern="pattern"
        :placeholder="placeholder"
        :value="value"
        @input="changeHandler"
        @keypress.enter="$emit('enterPush')"
        @blur="blurHandler"
        @focus="focus = true"
      />
      <Loader v-if="loader" />
    </div>
    <span class="error-text">{{ errorText }}</span>
  </div>
</template>

<script>
import inputMasks from '@/helper/inputsMasks'
export default {
  name: 'InputTell',
  props: {
    type: {
      type: String,
      default: () => 'text'
    },
    placeholder: {
      type: String,
      default: () => 'Plaseholder'
    },
    value: {
      type: [String, Number, Boolean],
      default: () => ''
    },
    error: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: () => ''
    },
    required: {
      type: Boolean,
      default: () => false
    },
    errorText: {
      type: String,
      default: () => 'error text'
    },
    showRequired: {
      type: Boolean,
      default: () => false
    },
    mask: {
      type: [String, Boolean],
      default: ''
    },
    loader: {
      type: Boolean,
      default: false
    },
    inputmode: {
      type: String,
      default: ''
    },
    pattern: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      prefix: '+38 (0',
      focus: false
    }
  },
  computed: {
    checkMask () {
      return inputMasks[this.mask] ? inputMasks[this.mask] : ''
    }
  },
  methods: {
    changeHandler ({ target }) {
      this.$emit('input', target.value)
    },
    blurHandler ({ target }) {
      this.focus = false
      const value = target.value

      if (value) {
        this.$emit('input', value.trim())
      }
    }
  }
}
</script>

<style lang="scss">
.input-tell {
  &.error {
    .input-holder {
      border-color: $red !important;
    }
  }

  // &:hover,
  // &:focus,
  // &.focus,
  // &.hover {
  //   .input-holder {
  //     border-color: $input-color-hover !important;
  //   }
  // }
  .input-holder {
    padding: 0 15px;
    font-size: 16px;
    max-width: 100%;
    width: 100%;
    border: $input-border;
    border-radius: $input-border-radius;
    color: $base-color;
    outline: none;
    height: 44px;
    max-height: 44px;
    display: flex;
    align-items: center;
    justify-content: left;
    background: $white;
    transition: border $transition;
    padding-right: 0 !important;

    @include media('>=md') {
      font-size: $input-font-size;
      padding: 10px 15px !important;
      padding-right: 0 !important;
    }

    input {
      font-family: $font;
      width: auto;
      padding: 0 !important;
      text-align: left !important;
      border: none;
      outline: none !important;
      background: transparent;
      max-width: calc(100% - 51px);
      width: 100%;
      height: calc(44px - 2px) !important;

      &:-internal-autofill-selected {
        background-color: red !important;
      }
    }

    .input-prefix{
      height: calc(44px - 2px) !important;
      display: flex;
      align-items: center;
      @include media('<=md') {
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="wio-step wio-step-3">
    <template v-if="showOffers">
      <div class="btn-back-wrapper" :class="{ 'loading-disabled': loading }">
        <UIButton
          :text="stepData.btnChangePrevInfo"
          class="btn-go-back"
          @click="$emit('prevStep')"
        />
      </div>
      <UIStepHeading
        :items="stepList"
        :active-index="2"
        :way-flag="dataUser.wayIndex"
        :class="{ 'loading-disabled': loading }"
      />
      <UIPrevValue
        :prev-values="prevValues"
        :class="{ 'loading-disabled': loading }"
      />
      <div
        v-if="!isHideFilters"
        class="filter-section"
        :class="{ 'loading-disabled': loading || filterLoad }"
      >
        <div class="filter-wrapper">
          <div
            :class="['mobile-filter-popup', { 'filter-open': isOpenFilter }]"
          >
            <div class="mobile-popup-wrapper">
              <div class="popup-header">
                <div class="title">
                  {{ stepData.filter.title || "Фильтр" }}
                </div>
                <div class="btn-close" @click="isOpenFilter = false">
                  <span class="icon-close" />
                </div>
              </div>
              <div class="wio-form-row">
                <div
                  :class="[
                    'wio-input-wrapper',
                    'form-col-6',
                    'crear',
                    { filled: filter.franchises },
                    { disabled: filter.proposal || loading },
                  ]"
                >
                  <label
                    v-if="stepData.filter.franchises.label"
                    :for="stepData.filter.franchises.id"
                  >
                    {{ stepData.filter.franchises.label }}
                  </label>

                  <v-select
                    :id="stepData.filter.franchises.id"
                    v-model="filter.franchises"
                    :placeholder="stepData.filter.franchises.placeholder"
                    label="name"
                    :filterable="false"
                    :options="stepData.filter.franchises.values"
                  />
                </div>
                <div
                  :class="[
                    'wio-input-wrapper',
                    'form-col-6',
                    'crear',
                    { filled: filter.options },
                    { disabled: filter.proposal || loading },
                  ]"
                >
                  <label
                    v-if="stepData.filter.options.label"
                    :for="stepData.filter.options.id"
                  >
                    {{ stepData.filter.options.label }}
                  </label>

                  <v-select
                    :id="stepData.filter.options.id"
                    v-model="filter.options"
                    :placeholder="stepData.filter.options.placeholder"
                    label="name"
                    :filterable="false"
                    :options="stepData.filter.options.values"
                  />
                </div>
              </div>
              <div class="wio-form-row">
                <div
                  :class="[
                    'wio-input-wrapper',
                    'form-col-6',
                    'crear',
                    { filled: filter.company },
                    { disabled: filter.proposal || loading },
                  ]"
                >
                  <label
                    v-if="stepData.filter.company.label"
                    :for="stepData.filter.company.id"
                  >
                    {{ stepData.filter.company.label }}
                  </label>

                  <v-select
                    :id="stepData.filter.company.id"
                    v-model="filter.company"
                    :placeholder="stepData.filter.company.placeholder"
                    label="name"
                    :filterable="false"
                    :options="companyData"
                  />
                </div>
                <UIInput
                  :id="stepData.filter.min.id"
                  v-model="filter.min"
                  :label="stepData.filter.min.label"
                  :placeholder="stepData.filter.min.placeholder"
                  class="form-col-3 input-price"
                  :class="[{ disabled: filter.proposal || loading }]"
                  type="number"
                  :mask="'digit6'"
                  :min="0"
                  @input="delayTouch(filter.min, 'min')"
                />
                <UIInput
                  :id="stepData.filter.max.id"
                  v-model="filter.max"
                  :label="stepData.filter.max.label"
                  :placeholder="stepData.filter.max.placeholder"
                  class="form-col-3 without-lable input-price"
                  :class="[{ disabled: filter.proposal || loading }]"
                  type="number"
                  :mask="'digit6'"
                  :min="1"
                  @input="delayTouch(filter.max, 'max')"
                />
              </div>
              <div class="mobile-button-wrapper">
                <UIButton
                  :text="stepData.filter.btn"
                  class="btn-small"
                  @click="btnFilterHandler"
                />
                <UIButton
                  :text="stepData.filter.btnReset"
                  class="btn-go-back"
                  @click="resetHandler"
                />
              </div>
            </div>
          </div>
          <div class="filter-fotter">
            <div class="result-title">
              {{ itemsTitle }}
            </div>
            <UIProposal
              v-if="proposal"
              v-model="filter.proposal"
              :data-object="proposal"
              class="mobile-only"
            />
            <div class="filter-btns-wrapper">
              <UIButton
                :text="stepData.filter.btnFilter"
                :class="['btn-small btn-prim', { disabled: loading }]"
                @click="isOpenFilter = !isOpenFilter"
              >
                <template #icon>
                  <span class="icon icon-filter" />
                </template>
              </UIButton>
              <UIButton
                ref="sort"
                :text="stepData.filter.btnSort"
                :class="[
                  'btn-small btn-prim sort-btn',
                  { disabled: filter.proposal || loading },
                  ,
                  { active: isSortOpen },
                ]"
                @click="isSortOpen = !isSortOpen"
              >
                <template #icon>
                  <span class="icon icon-sort" />
                </template>
              </UIButton>
            </div>
            <div
              v-click-outside="clickOutSide"
              :class="['sort-wrapper', { 'sort-open': isSortOpen }]"
            >
              <div class="sort-opener" @click="isSortOpen = !isSortOpen">
                <div class="sort-opener-prefix">
                  {{ stepData.filter.sort.text }}
                </div>
                <div class="sort-value">
                  {{
                    filter.sortValue.name || stepData.filter.sort.placeholder
                  }}
                </div>
                <div class="icon icon-arrow-new-v2"></div>
              </div>
              <div class="sort-drop">
                <div
                  v-for="sortItem in stepData.filter.sort.values"
                  :key="sortItem.name"
                  :class="[
                    'sort-item',
                    {
                      active:
                        filter.sortValue && filter.sortValue.id === sortItem.id,
                    },
                  ]"
                  @click="setSortItem(sortItem)"
                >
                  {{ sortItem.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="special-offer">
          <UIProposal
            v-if="proposal"
            v-model="filter.proposal"
            :data-object="proposal"
            :color="primaryColor"
          />
          <div class="proposal-card"></div>
          <div
            v-if="fillFilter || filter.proposal"
            :class="['btn-reset', { disabled: loading }]"
            @click="resetFilter"
          >
            <div class="icon-reset" />
            <span class="btn-reset__text">
              {{ stepData.btnClearFilter }}
            </span>
          </div>
        </div>
      </div>

      <Transition mode="out-in">
        <div class="loader-wrapper" v-if="filterLoad || filterLoad">
          <UILoader />
        </div>
      </Transition>
      <Transition mode="out-in">
        <div v-if="stepData.offers" class="offers-wrapper">
          <div
            v-if="items"
            class="offers"
            :style="`opacity:${filterLoad ? 0 : 1}`"
          >
            <OfferCard
              v-for="index in showItemsCount"
              :key="`offer${index}${items[index - 1].offer}`"
              :data-object="items[index - 1]"
              :loading="loadingIndex == index"
              :disable="loading"
              :flag-hide="isHideFilters"
              @buyPolicy="
                (offerData) => submitHandler(items[index - 1], offerData, index)
              "
            />
          </div>
        </div>
      </Transition>
      <Transition mode="out-in">
        <div
          v-if="items && items.length > showItemsCount"
          class="wio-btn-wrapper btn-center"
        >
          <UIButton
            :text="stepData.offers.btnNext.text"
            class="btn-green"
            @click="loadMore"
          />
        </div>
      </Transition>
      <div v-if="showNoOffersInfo" class="no-offers-wrapper">
          <p>{{stepData.noOffers.text}}</p>
          <div class="no-offers-btn-wrapper">
            <UIButton
            :text="stepData.noOffers.btnText"
            class="btn-green"
            @click="goHome"
          />
          </div>
        </div>
    </template>
    <h3 v-else style="margin: 100px 0">No options</h3>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import CONSTANTS from "@/helper/constants";
import axios from "axios";
import { cloneDeep } from "lodash";
import OfferCard from "./UI/Offer/Card.vue";

export default {
  name: "wioStep3",
  components: {
    OfferCard,
  },
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      items: [],
      itemsLenght: 0,
      itemsTitle: "",
      filterLoad: false,
      showItemsCount: 0,
      activeFilter: 0,
      filter: {
        franchises: "",
        company: "",
        sortValue: "",
        options: "",
        min: "",
        max: "",
        proposal: false,
      },
      min: "",
      max: "",
      delayObj: {},
      isOpenFilter: false,
      isSortOpen: false,
      loadingIndex: -1,
      noOffers:{
        text: "На жаль, за вашим запитом пропозиції відсутні",
        btnText: "Повернутись на головну",
      }
    };
  },
  created() {
    this.GTMPush()
    this.itemsTitle = cloneDeep(this.stepData.offers.title);
    this.items = cloneDeep(this.stepData.offers.list);

    if (this.stepData?.filter?.company?.value) {
      this.filter.company = cloneDeep(this.stepData.filter.company.value);
    }
    if (this.stepData?.filter?.franchises?.value) {
      this.filter.franchises = cloneDeep(this.stepData.filter.franchises.value);
    }
    if (this.stepData?.filter?.options?.value) {
      this.filter.options = cloneDeep(this.stepData.filter.options.value);
    }
    if (this.stepData?.filter?.sort?.value) {
      this.filter.sortValue = cloneDeep(this.stepData.filter.sort.value);
    }

    if (this.items) {
      this.itemsLenght = this.items.length;
      this.showItemsCount = this.itemsLenght >= 10 ? 10 : this.itemsLenght;
    }

    if (this.dataUser?.filter3step) {
      if (this.dataUser.filter3step?.company) {
        this.filter.company = this.dataUser.filter3step?.company;
      }
      if (this.dataUser.filter3step?.franchises) {
        this.filter.franchises = this.dataUser.filter3step?.franchises;
      }
      if (this.dataUser.filter3step?.sortValue) {
        this.filter.sortValue = this.dataUser.filter3step?.sortValue;
      }
      if (this.dataUser.filter3step?.proposal) {
        this.filter.proposal = this.dataUser.filter3step?.proposal;
      }
      if (this.dataUser.filter3step?.options) {
        this.filter.options = this.dataUser.filter3step?.options;
      }
    }

    this.$watch("filter.franchises", () => {
      if (this.filter.franchises) {
        this.checkFilter();
      }
    });

    this.$watch("filter.options", () => {
      if (this.filter.options) {
        this.checkFilter();
      }
    });

    this.$watch("filter.company", () => {
      if (this.filter.company) {
        this.checkFilter();
      }
    });

    this.$watch("filter.sortValue", () => {
      this.filterHandler();
    });

    this.$watch("filter.proposal", () => {
      this.getPropose();
    });
  },
  computed: {
    primaryColor() {
      return this.userStore.primaryColor;
    },
    dataUser() {
      return this.userStore.getUser;
    },
    lang() {
      return this.userStore.getLeng;
    },
    stepData() {
      return this.stepStore.getStep3;
    },
    showOffers() {
      return this.stepData?.offersSuccess;
    },
    prevValues() {
      return this.stepData.prevValues;
    },
    companyData() {
      const values = this.stepData?.filter?.company?.values;
      return Array.isArray(values) ? values : [];
    },
    fillFilter() {
      return (
        this.filter.franchises ||
        this.filter.company ||
        this.filter.options ||
        this.filter.min ||
        this.filter.max
      );
    },
    proposal() {
      return (
        this.stepData?.proposal || {
          label: "label",
          title: "title",
          text: "text",
        }
      );
    },
    isHideFilters() {
      return (
        "countOfOffers" in this.stepData && this.stepData.countOfOffers < 2
      );
    },
    showNoOffersInfo(){
      return (
        "countOfOffers" in this.stepData && this.stepData.countOfOffers < 1
      );
    },
    stepList() {
      return this.stepData?.stepList || [];
    },
  },
  methods: {
    ...mapActions(userData, ["resetAll"]),
    ...mapActions(stepData, ["setActiveIndex", "fetchStep1"]),
    submitHandler(item, offerData, index) {
      const newItem = cloneDeep(item);

      newItem.franchiseActive = offerData.franchise;

      this.userStore.setUserOne({ key: "offer", payload: newItem });
      this.loadingIndex = index;
      this.$emit("changeStep");
    },
    loadMore() {
      const newCount = this.showItemsCount + 10;
      this.showItemsCount =
        newCount >= this.itemsLenght ? this.itemsLenght : newCount;
    },
    btnFilterHandler() {
      this.isOpenFilter = false;
      this.filterHandler();
    },
    filterHandler() {
      this.filterLoad = true;
      this.userStore.setUserOne({
        key: "filter3step",
        payload: cloneDeep(this.filter),
      });
      this.gofilter();
    },
    async gofilter() {
      this.filterLoad = true;

      const data = await axios.post(
        `${CONSTANTS.BASE_URL}osago/${this.lang}/3`,
        {
          params: {
            userData: this.dataUser,
            internetSalesToken: userData().getInternetSalesToken,
            key: userData().getUserKey, 
            token: userData()?.getTokenId,
            telegramToken: userData().getTelegramToken,
            telegramSign: userData().telegramSign,
            telegramGroup: userData().telegramGroup
          },
        }
      );

      this.items = false;
      this.itemsTitle = cloneDeep(data.data.offers.title);
      this.items = cloneDeep(data.data.offers.list);

      if (this.items) {
        this.itemsLenght = this.items.length;
        this.showItemsCount = this.itemsLenght >= 10 ? 10 : this.itemsLenght;
      }

      this.filterLoad = false;
    },
    resetHandler() {
      this.isOpenFilter = false;

      this.filter = {
        franchises: "",
        company: "",
        sortValue: "",
        options: "",
        min: "",
        max: "",
      };

      this.filterHandler();
    },
    checkFilter() {
      if (window && window.innerWidth > 991) {
        this.filterHandler();
      }
    },
    resetFilter() {
      this.filter.franchises = "";
      this.filter.company = "";
      this.filter.options = "";
      this.filter.options = "";
      this.filter.max = "";
      this.filter.min = "";
      this.filter.proposal = false;

      this.userStore.setUserOne({
        key: "filter3step",
        payload: cloneDeep(this.filter),
      });
      this.gofilter();
    },
    getPropose() {
      this.filter.franchises = "";
      this.filter.company = "";
      this.filter.options = "";
      this.filter.options = "";
      this.filter.max = "";
      this.filter.min = "";

      this.userStore.setUserOne({
        key: "filter3step",
        payload: cloneDeep(this.filter),
      });
      this.gofilter();
    },
    delayTouch(i, key) {
      clearTimeout(this.delayObj[key]);
      this.delayObj[key] = setTimeout(() => {
        if (key === "min") {
          if (this.filter.max && this.filter.min >= this.filter.max) {
            this.filter.min = this.filter.max - 1;
          }

          if (+this.min !== +this.filter.min) {
            this.min = this.filter.min;
            this.checkFilter();
          }
        }

        if (key === "max") {
          if (this.filter.min && this.filter.min >= this.filter.max) {
            this.filter.max = +this.filter.min + 1;
          }

          if (+this.max !== +this.filter.max) {
            this.max = this.filter.max;
            this.checkFilter();
          }
        }
      }, 1000);
    },
    setSortItem(item) {
      this.isSortOpen = false;
      this.$set(this.filter, "sortValue", item);
    },
    clickOutSide(item) {
      if (
        !item.target.classList.contains("sort-btn") &&
        !item.target.classList.contains("icon-sort") &&
        this.isSortOpen
      ) {
        this.isSortOpen = false;
      }
    },
    GTMPush(){
      if(window && window.dataLayer){
        const self = this

        console.log('GTM view_item_list')

        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            items: [{
              item_name: 'widget_osago',
              item_category: self.dataUser?.type?.name,
              quantity: 1
            }]
          }
        })
      }
    },
    goHome() {
      if (this.stepStore.getStep1) {
        this.resetAll()
        this.setActiveIndex(0)
      } else {
        this.fetchStep1().then(() => {
          this.resetAll()
          this.setActiveIndex(0)
        });
      }
    },
  },
};
</script>

<style lang="scss">
.wio-step-3 {
  .wio-form-row {
    @include media('>md') {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  .filter-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-top: 12px;

    .special-offer {
      // border: 1px solid $grey-200;
      // border-radius: $base-border-radius;
      width: calc(32%);
      margin: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 10px;
      // display: none;

      @include media("<lg") {
        display: none;
      }

      .btn-reset {
        color: $primary;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        transition: $transition;
        font-size: $base-font-size;

        &:hover {
          color: $primary;
        }

        &.disabled {
          pointer-events: none;
          color: $grey-400;
        }

        .icon-reset {
          margin: -2px 9px 0 0;
          font-size: $base-font-size-l;
        }
      }
    }

    .result-title {
      font-size: 18px;
      line-height: 1.5em;

      @include media("<md") {
        text-align: center;
      }
    }
  }

  .filter-wrapper {
    width: 68%;

    @include media("<lg") {
      width: 100%;
    }

    .filter-fotter {
      display: flex;
      justify-content: space-between;

      @include media("<md") {
        width: 100%;
        flex-direction: column-reverse;
      }
    }

    .wio-form-row {
      @include media(">=md") {
        margin-bottom: 10px;
      }
    }
  }

  .title-wrapper {
    margin-bottom: 0;
  }

  .without-lable {
    label {
      opacity: 0;
    }
  }

  .form-wrapper {
    .v-select {
      .vs__selected-options {
        &:before {
          position: absolute;
          right: 100%;
          top: 50%;
          font-family: "icomoon";
          width: 25px;
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 22px;
          color: $primary;
          transform: translate(-10px, -50%);
        }
      }

      &.select-franchises {
        .vs__selected-options {
          &:before {
            content: $icon-money;
          }
        }
      }

      &.select-company {
        .vs__selected-options {
          &:before {
            content: $icon-company;
          }
        }
      }
    }
  }

  .filter-btns {
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .mobile-filter-popup {
    @include media(">=md") {
      margin-bottom: 15px;
    }
    @include media("<md") {
      position: fixed;
      bottom: 0;
      left: -100%;
      right: 100%;
      top: 0;
      background: white;
      z-index: 99999;
      padding: 30px 20px 65px;
      transition: $transition;
      box-shadow: 0px 50px $white;

      &.filter-open {
        transform: translateX(100%);
      }
    }

    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      @include media(">=md") {
        display: none;
      }
    }

    .title {
      margin-top: -5px;
    }

    .input-price {
      max-width: 50%;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    .btn-close {
      padding: 5px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        color: $primary;
      }
    }

    .mobile-popup-wrapper {
      @include media("<md") {
        max-width: 400px;
        min-height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
      }
    }

    .mobile-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      .wio-btn {
        max-width: 340px;
      }

      .wio-btn + .wio-btn {
        margin-top: 20px;
      }

      .btn-go-back {
        width: 100%;

        &:before {
          display: none;
        }
      }

      @include media(">=md") {
        display: none;
      }
    }
  }

  .filter-btns-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 17px;

    @include media(">=md") {
      display: none;
    }

    .wio-btn {
      max-width: calc(50% - 5px) !important;
      padding-left: 5px;
      padding-right: 5px;
    }

    .wio-btn + .wio-btn {
      margin-left: 10px;
    }
  }

  .loader-wrapper {
    background: rgba($white, 0.5);
    // pointer-events: none !important;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0 100px;
  }

  .offers-wrapper {
    position: relative;
  }

  .no-offers-wrapper{
    padding-top: 13px;
    font-size: 18px;

    p{
      margin: 0;
    }

    .no-offers-btn-wrapper{
      margin-top: 30px;
      display: flex;
      justify-content: center
    }
  }
}

.sort-wrapper {
  font-size: $base-font-size;
  position: relative;

  &.sort-open {
    .sort-opener {
      .icon {
        transform: rotate(-90deg);
      }
    }

    .sort-drop {
      display: flex;
    }
  }

  .sort-drop {
    position: absolute;
    top: 70px;
    right: 0;
    display: none;
    flex-direction: column;
    align-content: flex-end;
    border: 2px solid $grey-400;
    border-radius: $base-border-radius;
    padding: 10px 0;
    background: $white;
    z-index: 99999;
    width: 300px;
    max-width: 100%;

    @include media(">=md") {
      top: 100%;
      min-width: auto;
      width: auto;
      max-width: 500px;
    }

    .sort-item {
      padding: 10px 20px;
      line-height: 1;
      cursor: pointer;
      transition: $transition;
      white-space: nowrap;

      @include media("<md") {
        padding-right: 40px;
        position: relative;
      }

      &:hover {
        background: rgba($primary, 0.05);
      }

      &.active {
        color: $primary;
        pointer-events: none;

        &::after {
          opacity: 1;
        }
      }

      &::after {
        @include media("<md") {
          content: $icon-check;
          font-family: $icomoon-font-family;
        }

        position: absolute;
        right: 20px;
        top: 7px;
        color: $primary;
        opacity: 0;
        font-size: 20px;
        transition: $transition;
        font-weight: 500;
      }
    }
  }

  .sort-opener {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    @include media("<md") {
      display: none;
    }

    &-prefix {
      color: $grey5;
      margin-right: 5px;
    }

    .icon {
      transform: rotate(90deg);
      margin-left: 7px;
    }
  }
}

.mobile-only {
  margin-bottom: 20px;
  @include media(">=md") {
    display: none;
  }
}
</style>
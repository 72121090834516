import { userData } from "@/store/userData";


export default function(theme) {
  const userStore = userData();
  
  if(document){
    const r = document.querySelector(':root');
    const primaryColor = theme?.primary || '#002947'
    r.style.setProperty('--widget-osago-primary', primaryColor);
    r.style.setProperty('--widget-osago-primary-dark', '#004439');
    r.style.setProperty('--widget-osago-secondary', '#3E77AA');
    r.style.setProperty('--widget-osago-size', '14px');
    userStore.setPrimaryColor(primaryColor)
  }

// // Create a function for getting a variable value
// function myFunction_get() {
//   // Get the styles (properties and values) for the root
//   var rs = getComputedStyle(r);
//   // Alert the value of the --blue variable
//   alert("The value of --blue is: " + rs.getPropertyValue('--blue'));
// }

// // Create a function for setting a variable value
// function myFunction_set() {
//   // Set the value of variable --blue to another value (in this case "lightblue")
//   r.style.setProperty('--blue', 'lightblue');
// }
}
<template>
  <div class="wio-step wio-step-2">
    <div class="btn-back-wrapper" :class="{ 'loading-disabled': loading }">
      <UIButton
        :text="stepData.btnPrevText"
        class="btn-go-back"
        @click="$emit('prevStep')"
      />
    </div>
    <UIStepHeading
      :items="stepList"
      :active-index="1"
      :way-flag="dataUser.wayIndex"
      :class="{ 'loading-disabled': loading }"
    />
    <div class="wio-step__content">
      <div ref="formWrapper" class="wio-form-wrapper">
        <div class="wio-form-row">
          <div
            :class="[
              'wio-input-wrapper',
              'form-col-6',
              'crear',
              { 'read-only read-only-opacity': dataUser.euroNumber },
              { filled: formInfo.city },
              { error: $v.formInfo.city.$error },
              { disabled: loading },
            ]"
          >
            <label
              v-if="stepData.fields.city.label"
              :for="stepData.fields.city.id"
            >
              {{ stepData.fields.city.label }}
              <span class="required">*</span>
            </label>

            <v-select
              :id="stepData.fields.city.id"
              v-model="$v.formInfo.city.$model"
              push-tags
              :placeholder="stepData.fields.city.placeholder"
              :options="placeOptions"
              label="name"
              :filterable="false"
              @search="onSearch"
            />
            <span class="error-text">{{ stepData.fields.city.error }}</span>
          </div>
          <div
            :class="[
              'wio-input-wrapper',
              'select-list',
              'form-col-6',
              { filled: formInfo.vehicle },
              { error: $v.formInfo.vehicle.$error },
              { disabled: loading },
            ]"
          >
            <label
              v-if="stepData.fields.vehicle.label"
              :for="stepData.fields.vehicle.id"
            >
              {{ stepData.fields.vehicle.label }}
              <span class="required">*</span>
            </label>
            <v-select
              :id="stepData.fields.vehicle.id"
              v-model="$v.formInfo.vehicle.$model"
              :searchable="false"
              :placeholder="stepData.fields.vehicle.placeholder"
              :options="getIngineList()"
              label="name"
            >
              <template #option="option">
                <div v-if="option.listTitle" class="options-group-title">
                  {{ option.listTitle }}
                </div>
                <div class="option-wrapper">
                  <div
                    :class="[
                      'option-icon',
                      `icon-category_${setVehicleIcon(option.icon)}`,
                    ]"
                  />
                  <div class="option-text">
                    {{ option.name }}
                  </div>
                </div>
              </template>
              <template #selected-option="{ icon, name }">
                <div class="option-wrapper selected">
                  <div
                    :class="[
                      'option-icon',
                      `icon-category_${setVehicleIcon(icon)}`,
                    ]"
                  />
                  <div class="option-text">
                    {{ name }}
                  </div>
                </div>
              </template>
            </v-select>
            <span class="error-text">{{ stepData.fields.vehicle.error }}</span>
          </div>
        </div>
        <div class="wio-form-row"></div>
        <div class="wio-btn-wrapper btn-center btn-column">
          <UIButton
            :text="stepData.btnText"
            class="btn-green"
            @click="submitHandler"
            :loader="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import { required } from "vuelidate/lib/validators";
import { debounce, cloneDeep } from "lodash";
import axios from "axios";
import CONSTANTS from "@/helper/constants";

const touchMap = new WeakMap();

export default {
  name: "wioStep2",
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isValidation: true,
      formInfo: {},
      placeOptions: null,
    };
  },
  created() {
    this.userStore.setUserOne({ key:'filter3step', payload: null });
    this.placeOptions = this.stepData?.fields?.city?.values;
    this.formInfo = cloneDeep(this.dataUser);
    this.GTMPush()
  },
  watch: {},
  methods: {
    delayTouch($v, key) {
      if (key === "carNumber") {
        this.formInfo.carNumber = this.formInfo.carNumber.toUpperCase();
      }

      $v.$reset();

      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.search(loading, search, this);
      } else if (!search.length) {
        this.placeOptions = this.stepData.fields.city.values;
      }
    },
    search: debounce((loading, search, vm) => {
      axios
        .post(`${CONSTANTS.BASE_URL}city`, { params: { city: search } })
        .then((res) => {
          vm.placeOptions = res.data;
          loading(false);
        })
        .catch((error) => console.log(error));
    }, 350),
    submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        const element = this.$refs.formWrapper;

        if (!element) {
          return;
        }

        setTimeout(() => {
          const errorElement = element.querySelectorAll(
            ".input-wrapper.error"
          )[0];

          if (!errorElement) {
            return;
          }

          const { top } = errorElement.getBoundingClientRect();

          if (top > 30) {
            return;
          }

          window.scroll(0, window.scrollY + top - 30);
        }, 10);

        console.log("error");
      } else {
        Object.keys(this.formInfo).forEach((key) => {
          this.userStore.setUserOne({ key, payload: this.formInfo[key] });
        });

        this.$emit("changeStep");
      }
    },
    getIngineList() {
      let newList = [];

      const oldList = cloneDeep(
        this.stepData?.fields?.vehicle[
          this.dataUser.privilegesBox ? "privilegesList" : "values"
        ]
      );

      oldList.forEach((item) => {
        item.list[0].listTitle = item.name;
        newList = [...newList, ...item.list];
      });

      return newList;
    },
    setVehicleIcon(icon) {
      if (!icon) {
        return "b";
      }
      if (icon === "E") {
        return "f";
      }
      return icon.toLowerCase();
    },
    GTMPush(){
      console.log(window, window.dataLayer)
      if(window && window.dataLayer){
        const self = this

        console.log('GTM select_item')

        window.dataLayer.push({
          event: 'select_item',
          ecommerce: {
            items: [{
              item_name: 'widget_osago',
              item_category: self.dataUser.type.name,
              item_category2: self.dataUser.vehicle,
              item_variant: 'undefined',
              quantity: 1
            }]
          }
        })
      }
    }
  },
  computed: {
    dataUser() {
      return this.userStore.getUser;
    },
    stepData() {
      return this.stepStore.getStep2;
    },
    stepList() {
      return this.stepData.stepList
    },
  },
  validations() {
    return {
      formInfo: {
        city: {
          required,
        },
        vehicle: {
          required,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wio-step-2 {
  // display: flex;
}

.wio-step {
  &__content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    // .wio-form-wrapper {
    //   width: 100%;
    // }

    .wio-btn-wrapper {
      @include media(">=md") {
        padding-top: 15px;
      }
    }
  }

  &__img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-top: 60px;

    img {
      max-width: 430px;
    }
  }
}
</style>
<template>
  <div
    :class="[
      'offer',
      { 'style-2': style2 },
      { 'offer--with-flag': flagsList.length },
      { 'loading-disabled': disable && !loading },
    ]"
  >
    <FlagsList
      v-if="flagsList.length && !flagHide"
      :list="flagsList"
      :class="{ 'loading-disabled': disable && loading }"
      />
    <div class="offer__content">
      <div
        :class="[
          'offer__brand-info',
          { 'offer__brand-info--with-flag': flagsList.length },
          { 'loading-disabled': disable && loading },
        ]"
      >
        <div>
          <div v-if="brandImg.src" class="offer--logo">
            <UIImage :image-data="brandImg" loading="lazy" />
          </div>
          <SpeedOfPayment v-if="speedOfPayment" :data-object="speedOfPayment" />
        </div>
        <AdvantageList v-if="advantageList" :list="advantageList" />
        <StarsList v-if="topList" :list="topList" />
      </div>
      <div
        class="offer__main"
        :class="{ 'loading-disabled': disable && loading }"
      >
        <h4 class="offer__title">
          {{ dataObject.company.text }}
        </h4>
        <div class="offer__price mobile">
          <div v-if="proposal" class="offer__proposal">
            <span class="icon-fire" />
            <span>{{ proposal }}</span>
          </div>
          <div v-if="dataObject.advantageMobile" class="price-list-item">
            <div class="left">
              {{ dataObject.advantageMobile.name }}
            </div>
            <div class="right">
              {{ dataObject.advantageMobile.value }}
            </div>
          </div>
          <div class="price-list-item">
            <div class="left">
              {{ priceMobileText }}
            </div>
            <div class="right">
              <Price v-if="price" :price="price" />
            </div>
          </div>
        </div>
        <Franchise
          v-if="franchiseList"
          v-model="franshiseValue"
          :data-object="franchiseList"
        />
        <div v-if="aboutBtn" class="offer__about-opener desktop">
          <span class="offer__about-opener--prefix">
            {{ aboutBtn.prefixText }}
          </span>
          <span
            class="offer__about-opener--text"
            @click="isDropOpen = !isDropOpen"
          >
            <div class="offer__about-opener--text--value">
              {{ aboutBtn.text }}
            </div>
            <div
              :class="[
                'offer__about-opener--text--icon',
                { 'offer__about-opener--text--icon--active': isDropOpen },
              ]"
            >
              <span class="icon-arrow-new" />
            </div>
          </span>
        </div>
      </div>
      <div class="offer__price desktop">
        <div
          v-if="proposal"
          class="offer__proposal"
          :class="{ 'loading-disabled': disable && loading }"
        >
          <span class="icon-fire" />
          <span>{{ proposal }}</span>
        </div>
        <Price v-if="price" :price="price" />
        <div class="btn-wrapper">
          <UIButton
            :text="dataObject.btnButText"
            class="btn-small offer-btn"
            :class="[{ disabled: disable && !loading }]"
            :loader="loading"
            @click="$emit('buyPolicy', currentData)"
          />
        </div>
        <InfoIconsList v-if="infoIcons" :list="infoIcons" />
      </div>
    </div>
    <div class="btn-wrapper mobile">
      <UIButton
        :text="dataObject.btnButText"
        class="btn-small offer-btn"
        :class="[{ disabled: disable && !loading }]"
        :loader="loading"
        @click="$emit('buyPolicy', currentData)"
      />
    </div>
    <div
      v-if="aboutBtn"
      class="offer__about-opener mobile"
      :class="{ 'loading-disabled': disable && loading }"
    >
      <span class="offer__about-opener--text" @click="isDropOpen = !isDropOpen">
        <div class="offer__about-opener--text--value">
          {{ aboutBtn.text }}
        </div>
        <div
          :class="[
            'offer__about-opener--text--icon',
            { 'offer__about-opener--text--icon--active': isDropOpen },
          ]"
        >
          <span class="icon-arrow-new" />
        </div>
      </span>
    </div>
    <div
      v-if="aboutBtn"
      ref="drop"
      class="offer__drop"
      :style="`height:${dropHeight}`"
    >
      <Drop :data-object="aboutData" />
    </div>
  </div>
</template>

<script>
import FlagsList from "./FlagsList.vue";
import SpeedOfPayment from "./SpeedOfPayment.vue";
import AdvantageList from "./AdvantageList.vue";
import StarsList from "./StarsList.vue";
import InfoIconsList from "./InfoIconsList.vue";
import Drop from "./Drop.vue";
import Price from "./Price.vue";
import Franchise from "./Franchise.vue";

export default {
  name: "OfferCard",
  components: {
    FlagsList,
    SpeedOfPayment,
    AdvantageList,
    StarsList,
    InfoIconsList,
    Drop,
    Price,
    Franchise,
  },
  props: {
    dataObject: {
      type: Object,
      default: () => ({}),
    },
    style2: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      deafult: false,
    },
    flagHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ratingActive: false,
      openBottom: false,
      nameList: ["leader", "recommendation", "purpose"],
      franshiseValue: null,
      isDropOpen: false,
      dropHeight: 0,
      dropTimeOut: null,
    };
  },
  computed: {
    proposal() {
      return this.dataObject?.proposal;
    },
    currentData() {
      return {
        franchise: this.franshiseValue,
        policyData: this.dataObject,
      };
    },
    ifFranchise() {
      return (
        this.dataObject.franchise &&
        this.dataObject.franchise.list.length &&
        this.dataObject.franchise.list[0].price.price
      );
    },
    flagsList() {
      return this?.dataObject?.marks || [];
    },
    brandImg() {
      return {
        src: this.dataObject?.company?.img,
        alt: this.dataObject?.company?.alt || "brand-alt",
        title: this.dataObject?.company?.title || "brand-title",
      };
    },
    speedOfPayment() {
      return this.dataObject?.description?.speedOfpayment;
    },
    advantageList() {
      return this.dataObject?.description?.list;
    },
    price() {
      let price = null;

      if (this?.franshiseValue) {
        price = JSON.parse(JSON.stringify(this?.franshiseValue?.price));
      }

      return price;
    },
    priceMobileText() {
      return this.franshiseValue?.price?.text || "";
    },
    topList() {
      return this.dataObject?.topList;
    },
    infoIcons() {
      return this.dataObject?.infoIcons;
    },
    franchiseList() {
      const franchiseList = JSON.parse(
        JSON.stringify(this.dataObject?.franchise)
      );
      franchiseList.id = this.dataObject?.id;
      return franchiseList;
    },
    aboutBtn() {
      return this.dataObject?.about?.btn;
    },
    aboutData() {
      return {
        optionsList: this.dataObject?.about?.optionsList,
        content: this.dataObject?.about?.content,
      };
    },
  },
  watch: {
    isDropOpen() {
      if (this.isDropOpen) {
        clearTimeout(this.dropTimeOut);
        this.dropHeight = `${this.$refs.drop.scrollHeight}px`;
        this.dropTimeOut = setTimeout(() => {
          if (this.dropHeight !== "0px") {
            this.dropHeight = "auto";
          }
        }, 400);
      } else {
        clearTimeout(this.dropTimeOut);
        this.dropHeight = `${this.$refs.drop.scrollHeight}px`;
        setTimeout(() => {
          this.dropHeight = "0px";
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;
  border: 1px solid $grey3;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.07);
  background: $white;

  @include media("<md") {
    max-width: 500px;
    margin: 0 auto 20px;
    border-radius: $base-border-radius;
    padding: 0 20px;
  }

  @include media(">=md") {
    border-radius: $base-border-radius;
    margin-bottom: 15px;
  }

  // &::before {
  //   position: absolute;
  //   left: -20px;
  //   top: 0;
  //   right: -20px;
  //   height: 1px;
  //   background: $grey-200;

  //   @include media("<md") {
  //     content: "";
  //   }
  // }

  &__proposal {
    font-size: $base-font-size-s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-800;
    margin-bottom: 20px;
    padding-right: 15px;

    [class^="icon-"],
    [class*=" icon-"] {
      color: $orange;
      font-size: $base-font-size-l;
      margin-right: 5px;
    }
  }

  .offer__about-opener {
    width: 100%;

    &.mobile {
      margin-bottom: 30px;

      .offer__about-opener--text {
        height: 50px;
        border: 2px solid $grey-400;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 283px;
        margin-bottom: 30px;
        margin: 0 auto;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
    max-width: 100%;

    @include media(">=md") {
      flex-direction: row;
    }
  }

  &__brand-info {
    width: calc(100% - 558px - 307px);
    padding: 30px;
    position: relative;

    @include media(">=md", "<de") {
      width: 100%;
      display: flex;

      & > * {
        width: 33.333%;
      }
    }

    @include media("<de") {
      padding: 30px 30px 10px;
    }

    @include media("<md") {
      padding: 15px 0 0;
      width: 100%;
      border-bottom: 1px solid $grey-200;
    }

    &::before {
      position: absolute;
      top: 30px;
      bottom: 30px;
      right: 0;
      width: 1px;
      background-color: $grey-100;

      @include media(">=md", "<de") {
        left: 30px;
        right: 30px;
        bottom: 0;
        top: auto;
        height: 1px;
        width: auto;
      }

      @include media(">=md") {
        content: "";
      }
    }
  }

  &__main {
    padding: 20px 0 0;
    max-width: 100%;

    @include media(">=md") {
      padding: 20px 30px 30px;
      width: calc(100% - 285px);
    }

    @include media(">=de") {
      padding: 45px 40px 30px;
      // width: calc(100% - 640px);
      width: 558px;
    }
  }

  &__price {
    width: 100%;
    min-width: 345px;
    padding: 30px 60px 60px;
    position: relative;

    @include media(">=md") {
      width: 285px;
      min-width: 285px;
      padding: 30px 20px 20px;
    }

    @include media(">=de") {
      width: 307px;
      min-width: 307px;
      padding: 30px 40px 40px;
    }

    &.mobile {
      background: $grey-100;
      padding: 20px;
      border-radius: $base-border-radius;
      line-height: 1.5em;
      font-size: $base-font-size;
      max-width: 100%;
      min-width: auto;
      margin-bottom: 30px;
      border: 1px solid $grey3;

      @include media(">=md") {
        display: none;
      }

      &:before {
        content: none;
      }

      .price-list-item {
        display: flex;

        & + .price-list-item {
          margin-top: 20px;
        }

        .price {
          @include media("<md") {
            align-items: flex-end;
          }
        }
      }

      .left {
        width: calc(100% - 150px);
        color: $grey-800;
        display: flex;
        align-items: flex-end;
      }
      .right {
        width: 250px;
        display: flex;
        justify-content: flex-end;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 30px;
      bottom: 30px;
      left: 0;
      width: 1px;
      background-color: $grey-100;
    }
  }

  &__title {
    line-height: 1.5;
    font-weight: 500 !important;
    margin-bottom: 20px !important;
    width: 100%;

    @include media(">=lg") {
      margin-bottom: 30px !important;
    }
  }

  &--logo {
    max-width: 96px;
    margin-bottom: 20px;

    @include media(">=lg") {
      max-width: 160px;
      margin-bottom: 20px;
    }
  }

  &__about-opener {
    display: flex;
    font-size: $base-font-size;
    color: $grey-800;

    &--text {
      font-weight: 500;
      color: $primary;
      margin-left: 5px;
      cursor: pointer;
      transition: $transition;
      display: flex;
      align-items: center;

      &:hover {
        color: $primary;
      }

      &--value {
        margin-right: 5px;
      }

      &--icon {
        transition: $transition;
        display: flex;
        align-items: center;
        justify-content: center;

        &--active {
          transform: rotateX(180deg);
        }
      }
    }
  }

  &__drop {
    overflow: hidden;
    transition: $transition;
    max-width: 100%;

    @include media(">lg") {
      padding: 0 30px;
    }
  }

  &__m-header {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }

  &__m-options {
    border-radius: 20px;
    overflow: hidden;
    background: $grey-100;
    padding: 20px;
    margin-bottom: 30px;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  &__m-culemn-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .speed-of-payment {
    @include media("<md") {
      display: none;
    }
  }

  .advantage-list {
    margin-bottom: 20px;
  }

  .price {
    @include media(">=lg") {
      margin-bottom: 30px;
    }
  }

  .btn-wrapper {
    margin-bottom: 30px;

    &.mobile {
      @include media("<md") {
        padding-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .franchise {
    @include media(">=md") {
      margin-bottom: 15px;
    }
  }

  &.style-2 {
    @include media(">=md") {
      padding: 15px 0 20px;
    }

    .right-side {
      padding-top: 20px;
    }

    .offer-footer {
      .buy-btn-wrapper {
        display: flex;
        align-items: flex-end;

        @include media("<md") {
          width: 100%;
        }
      }
    }
  }

  .desktop {
    @include media("<md") {
      display: none;
    }
  }

  .mobile {
    @include media(">=md") {
      display: none;
    }
  }
}
</style>

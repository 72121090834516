<template>
  <div class="wio-step wio-step-4">
    <div class="btn-back-wrapper" :class="{ 'loading-disabled': loading }">
      <UIButton
        :text="stepData.btnPrevText"
        class="btn-go-back"
        @click="$emit('prevStep')"
      />
    </div>
    <UIStepHeading
      :items="stepList"
      :active-index="3"
      :way-flag="dataUser.wayIndex"
      :class="{ 'loading-disabled': loading }"
    />
    <div class="wio-step-sidebar">
      <div class="wio-step-left">
        <div
          ref="formWrapper"
          :class="['wio-form-wrapper', { 'form-loading': carNumberLoader }]"
        >
          <div
            class="sub-title"
            :class="{ 'loading-disabled': loading }"
            style="margin-top: 0"
          >
            <span>{{ stepData.contactTitle }}</span>
          </div>
           <!-- FIO -->
           <div class="wio-form-row">
            <UIInput
              v-if="ifLegal"
              v-model="$v.formInfo.companyName.$model"
              :label="stepData.fields.companyName.label"
              :placeholder="stepData.fields.companyName.placeholder"
              :error="!!errors.companyName || $v.formInfo.companyName.$error"
              :error-text="
                errors.companyName
                  ? errors.companyName.messages[0]
                  : stepData.fields.companyName.error
              "
              :show-required="true"
              class="form-col"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.companyName, 'companyName')"
            />
            <UIInput
              v-else
              v-model="$v.formInfo.userName.$model"
              :label="stepData.fields.userName.label"
              :placeholder="stepData.fields.userName.placeholder"
              :error="!!errors.userName || $v.formInfo.userName.$error"
              :error-text="
                errors.userName
                  ? errors.userName.messages[0]
                  : stepData.fields.userName.error
              "
              :show-required="true"
              class="form-col"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.userName, 'userName')"
            />
          </div>
          <!-- EMAIL / PHONE -->
          <div class="wio-form-row">
            <UIInputTell
              v-model="$v.formInfo.phone.$model"
              :label="stepData.fields.phone.label"
              :placeholder="stepData.fields.phone.placeholder"
              :error="!!errors.phone || $v.formInfo.phone.$error"
              :error-text="
                errors.phone
                  ? errors.phone.messages[0]
                  : stepData.fields.phone.error
              "
              :show-required="true"
              :mask="'tell'"
              :type="'tel'"
              class="form-col-6"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.phone, 'phone')"
            />
            <UIInput
              v-model="$v.formInfo.email.$model"
              v-tippy="{ onShow: () => !!stepData.fields.email.label }"
              :content="stepData.fields.email.label"
              :label="stepData.fields.email.label"
              :placeholder="stepData.fields.email.placeholder"
              :error="!!errors.email || $v.formInfo.email.$error"
              :error-text="
                errors.email
                  ? errors.email.messages[0]
                  : stepData.fields.email.error
              "
              :show-required="true"
              class="form-col-6"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.email, 'email')"
            />
          </div>
          <div
            class="sub-title"
            :class="{ 'loading-disabled': loading }"
            style="margin-top: 30px"
          >
            <span>{{ stepData.title }}</span>
          </div>
          <!-- CAR_NUMBER / BRAND / CAR_YEAR -->
          <div class="wio-form-row">
            <UIInput
              v-if="!dataUser.wayIndex"
              :id="stepData.fields.carNumber.id"
              v-model="$v.formInfo.carNumber.$model"
              :label="stepData.fields.carNumber.label"
              :placeholder="stepData.fields.carNumber.placeholder"
              :error="!!errors.carNumber || $v.formInfo.carNumber.$error"
              :error-text="
                errors.carNumber
                  ? errors.carNumber.messages[0]
                  : stepData.fields.carNumber.error
              "
              :show-required="true"
              :loader="carNumberLoader"
              class="form-col-6"
              :class="[{ disabled: loading }]"
              @input="delayTouch($v.formInfo.carNumber, 'carNumber')"
            />
            <UIInput
              v-else
              v-model="$v.formInfo.brand.$model"
              :label="stepData.fields.brand.label"
              :placeholder="stepData.fields.brand.placeholder"
              :error="$v.formInfo.brand.$error"
              :error-text="stepData.fields.brand.error"
              :show-required="true"
              class="form-col-6"
              :class="[{ disabled: loading }]"
              :options="brandOptions"
              @input="brandSearch"
            />
            <div
              :class="[
                'select-wrapper',
                'wio-input-wrapper',
                'crear',
                'form-col-6',
                { filled: formInfo.carYear },
                { error: $v.formInfo.carYear.$error },
                { disabled: loading },
              ]"
            >
              <label v-if="stepData.fields.carYear.label">
                {{ stepData.fields.carYear.label }}
                <span class="required">*</span>
              </label>
              <v-select
                v-model="$v.formInfo.carYear.$model"
                :placeholder="stepData.fields.carYear.placeholder"
                :options="stepData.fields.carYear.values"
                label="name"
              >
                <div slot="no-options">
                  {{ "Not faund" }}
                </div>
              </v-select>

              <span class="error-text">
                {{ stepData.fields.carYear.error }}
              </span>
            </div>
          </div>
          <!-- CAR_NUMBER / BRAND / VIN -->
          <div class="wio-form-row">
            <UIInput
              v-if="dataUser.wayIndex === 1"
              :id="stepData.fields.carNumber.id"
              v-model="$v.formInfo.carNumber.$model"
              :label="stepData.fields.carNumber.label"
              :placeholder="stepData.fields.carNumber.placeholder"
              :error="$v.formInfo.carNumber.$error"
              :error-text="stepData.fields.carNumber.error"
              :show-required="true"
              class="form-col-6 read-only"
              :class="[{ disabled: loading }]"
            />
            <UIInput
              v-if="!dataUser.wayIndex"
              v-model="$v.formInfo.brand.$model"
              :label="stepData.fields.brand.label"
              :placeholder="stepData.fields.brand.placeholder"
              :error="$v.formInfo.brand.$error"
              :error-text="stepData.fields.brand.error"
              :show-required="true"
              class="form-col-6"
              :class="disabledClass"
              :options="brandOptions"
              @input="brandSearch"
            />
            <UIInput
              :id="stepData.fields.vin.id"
              v-model="$v.formInfo.vin.$model"
              :label="stepData.fields.vin.label"
              :placeholder="stepData.fields.vin.placeholder"
              :show-required="true"
              :error="!!errors.vin || $v.formInfo.vin.$error"
              :error-text="
                errors.vin ? errors.vin.messages[0] : stepData.fields.vin.error
              "
              :tooltip="stepData.fields.vin.tooltip"
              class="form-col-6"
              :class="disabledClass"
              @input="delayTouch($v.formInfo.vin, 'vin')"
            />
          </div>
          <div class="sub-title" :class="{ 'loading-disabled': loading }">
            <span>{{ stepData.subTitle }}</span>
          </div>
          <!-- START_POLICY / END_POLICY -->
          <div class="wio-form-row">
            <UIDatePicker
              :id="stepData.fields.startPolicy.id"
              v-model="$v.formInfo.startPolicy.$model"
              class="form-col-6"
              :class="disabledClassStartPolicy"
              :error-text="stepData.fields.startPolicy.error"
              :placeholder="stepData.fields.startPolicy.placeholder"
              :min="new Date(minStartDate)"
              :max="new Date(maxDateStart)"
              :label="stepData.fields.startPolicy.label"
            />
            <UIDatePicker
              :id="stepData.fields.endPolicy.id"
              v-model="$v.formInfo.endPolicy.$model"
              class="form-col-6 read-only"
              :class="endPolicyClass"
              :error-text="stepData.fields.endPolicy.error"
              :placeholder="stepData.fields.endPolicy.placeholder"
              :min="minDate"
              :max="maxDate"
              :label="stepData.fields.endPolicy.label"
            />
          </div>
          <div v-if="showOtk || formInfo.otk" class="wio-form-row">
            <!-- <div class="form-row otk-row"> -->
            <UIInput
              :id="stepData.fields.otk.id"
              v-model="formInfo.otk"
              class="swipe-style form-col-6"
              :class="otkClass"
              :label="stepData.fields.otk.label"
              :name="'otk-swipe'"
              type="checkbox"
            />
            <UIDatePicker
              v-if="formInfo.otk"
              :id="stepData.fields.otkDate.id"
              v-model="$v.formInfo.otkDate.$model"
              :min="minDtaOtk"
              :class="['form-col-6', { error: $v.formInfo.otkDate.$error }]"
              :error-text="stepData.fields.otkDate.error"
              :placeholder="stepData.fields.otkDate.placeholder"
              :label="stepData.fields.otkDate.label"
            />
          </div>
          <div class="btn-step-footer">
            <UIButton
              :text="stepData.btnNextText"
              @click="submitHandler"
              :loader="loading"
              class="mb-20"
            />
          </div>
        </div>
      </div>
      <div class="wio-step-right" :class="{ 'loading-disabled': loading }">
        <UISideBar v-if="sideBarData" :data-object="sideBarData" />
        <UIPayCard
          v-if="stepData && stepData.payment"
          :text="stepData.payment.text"
          :value="stepData.payment.price"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import masks from "@/helper/inputsMasks";
import df from "@/helper/dateFormater";
import moment from "moment";
import validationHelpers from "@/helper/validation";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import CONSTANTS from "@/helper/constants";
import axios from "axios";
import { debounce, cloneDeep } from "lodash";

const touchMap = new WeakMap();

export default {
  name: "wioStep4",
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      masks,
      formInfo: "",
      day: 1000 * 60 * 60 * 24,
      startPolicyDate: "",
      maxDate: false,
      minDate: false,
      minDtaOtk: "",
      carNumberTimeOut: "",
      carNumberLoader: false,
      carNumberDisable: false,
      minStartDateValue: new Date(),
      brandOptions: [],
      lastSearchNumber: null,
    };
  },
  created() {
    this.GTMPush()
    const time = new Date();

    this.formInfo = cloneDeep(this.dataUser);
    this.formInfo.otkDate = df(new Date(time.getTime() + this.day * 2));
    this.minDtaOtk = df(new Date(time.getTime() + this.day * 2));
    this.formInfo.startPolicy = this.stepData.fields.startPolicy.value;

    if (this?.stepData?.fields?.brand?.values) {
      this.brandOptions = this.stepData.fields.brand.values.map((i) => i.name);
    } else {
      this.brandOptions = null;
    }

    if (this.dataUser.taxi) {
      this.formInfo.otk = true;
    }

    // let fullData = true;

    // ["brand", "vin", "carYear"].forEach((key) => {
    //   if (!this.formInfo[key]) {
    //     fullData = false;
    //   }
    // });

    // this.carNumberDisable = !fullData;

    if (this.formInfo.euroNumber) {
      this.carNumberDisable = false;
    }
  },
  computed: {
    dataUser() {
      return this.userStore.getUser;
    },
    stepData() {
      return this.stepStore.getStep4;
    },
    sideBarData() {
      return this.stepData?.sideBar;
    },
    otk() {
      return this.formInfo.otk;
    },
    showOtk() {
      const icons = ["C", "D", "E", "F"];
      const userVehicle = this.dataUser.vehicle;
      const dataVehicle = this.stepData.fields.vehicle;
      let result;

      if (userVehicle.icon && icons.includes(userVehicle.icon)) {
        result = icons.includes(userVehicle.icon);
      } else {
        let currentVehicle;

        dataVehicle.values.forEach(({ list }) => {
          if (!currentVehicle) {
            list.forEach((item) => {
              if (parseInt(item.id) === parseInt(userVehicle.id)) {
                currentVehicle = item;
              }
            });
          }
        });

        result = icons.includes(currentVehicle?.icon);
      }
      return result;
    },
    maxDateStart() {
      const newDate = new Date();
      let max = this.stepData.fields.startPolicy.dateFromMax;

      max = max || 30;

      return df(new Date(newDate.getTime() + this.day * max));
    },
    minStartDate() {
      let currentDate = moment(this.minStartDateValue);
      const fromBackDate = moment(this.stepData.fields.startPolicy.value);

      currentDate = currentDate.add(1, "day");
      currentDate =
        currentDate.toDate() >= fromBackDate.toDate()
          ? currentDate.toDate()
          : fromBackDate.toDate();

      return currentDate;
    },
    ifBackErrors() {
      let result = false;

      if (!Object.keys(this.errors).length) {
        return result;
      }

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key]) {
          result = true;
        }
      });

      return result;
    },
    errors() {
      return this.userStore?.getBackErrors;
    },
    ifReadOnly() {
      return this.carNumberDisable && !this.dataUser.wayIndex;
    },
    disabledClass() {
      return [
        // { 'read-only': this.ifReadOnly},
        // { 'read-only-opacity  ': this.ifReadOnly },
        { disabled: this.loading },
      ];
    },
    disabledClassStartPolicy() {
      return [
        { "read-only": this.ifReadOnly },
        { "read-only-opacity  ": this.ifReadOnly },
        { error: this.$v.formInfo.startPolicy.$error },
        { disabled: this.loading },
      ];
    },
    endPolicyClass() {
      return [
        { "read-only": this.ifReadOnly },
        { "read-only-opacity  ": this.ifReadOnly },
        { error: this.$v.formInfo.endPolicy.$error },
        { disabled: this.loading },
      ];
    },
    otkClass() {
      return [
        { "input-checked": this.formInfo.otk },
        { "read-only": this.dataUser.taxi },
        { disabled: this.loading },
      ];
    },
    stepList() {
      return this.stepData.stepList;
    },
    ifLegal() {
      return this.dataUser.type.id === "LEGAL";
    },
    userNameValidation() {
      // if (this.formInfo.document.id === "EXTERNAL_PASSPORT") {
      //   return validationHelpers.name3En;
      // }
      return validationHelpers.name3;
    },
  },
  watch: {
    "formInfo.startPolicy"() {
      const newDate = new Date(this.formInfo.startPolicy);
      const momentDate = moment(newDate);
      let endDate = "";

      endDate = momentDate.add(1, "years");

      setTimeout(() => {
        this.formInfo.endPolicy = df(new Date(endDate.calendar()));
      });
    },
  },
  methods: {
    ...mapActions(userData, ["setBackError"]),
    checkPrevValue(item) {
      return item && item.value;
    },
    delayTouch($v, key) {
      if (key === "carNumber") {
        this.formInfo.carNumber = this.formInfo.carNumber.toUpperCase();
      }
      if (this.errors[key]) {
        this.setBackError({
          payload: false,
          key,
        });
      }

      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }

      if (key === "carNumber" && !this.formInfo.euroNumber) {
        this.changeCarNumber($v);
      }

      touchMap.set($v, setTimeout($v.$touch, 500));
    },
    changeCarNumber($v) {
      if (this.lastSearchNumber === this.formInfo.carNumber) {
        return;
      }
      this.lastSearchNumber = this.formInfo.carNumber;
      clearTimeout(this.carNumberTimeOut);

      if (!$v.$invalid) {
        this.carNumberTimeOut = setTimeout(() => {
          this.carNumberLoader = true;
          this.serchData();
        }, 700);
      }
    },
    async serchData() {
      let data = await axios.post(
        `${CONSTANTS.BASE_URL}number/${this.formInfo.carNumber}`
      );
      this.carNumberLoader = false;

      data = data?.data;

      if (data.errors) {
        data.errors.forEach((item) => {
          this.setBackError({
            payload: item,
            key: item.name,
          });
        });
      } else {
        ["vin", "brand", "carYear"].forEach((key) => {
          if (data[key] && data[key].value) {
            this.formInfo[key] = data[key].value;
          }
        });

        this.carNumberDisable = false;
      }
    },
    changeHandler(key) {
      if (this.errors[key]) {
        this.setBackError({
          payload: false,
          key,
        });
      }
    },
    submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        const element = this.$refs.formWrapper;

        if (!element) {
          return;
        }

        setTimeout(() => {
          const errorElement = element.querySelectorAll(
            ".input-wrapper.error"
          )[0];

          if (!errorElement) {
            return;
          }

          const { top } = errorElement.getBoundingClientRect();

          if (top > 30) {
            return;
          }

          window.scroll(0, window.scrollY + top - 30);
        }, 10);
        console.log("error", this.$v);
      } else {
        Object.keys(this.formInfo).forEach((key) => {
          this.userStore.setUserOne({ key, payload: this.formInfo[key] });
        });

        this.$emit("changeStep");
      }
    },
    setSelected(e) {
      const value = e.target.value;

      if (value) {
        this.brandOptions.push(e.target.value);
        this.formInfo.brand = e.target.value;
      }
    },
    brandSearch(v) {
      this.search(v, this);
    },
    search: debounce((search, vm) => {
      axios
        .post(`${CONSTANTS.BASE_URL}model/and/brand/${search}`)
        .then(({ data }) => {
          if (data?.length) {
            vm.brandOptions = data.map((i) => i.name);
          } else {
            vm.brandOptions = [];
          }
        })
        .catch((error) => {
          vm.brandOptions = [];
          console.log(error);
        });
    }, 350),
    GTMPush(){
      if(window && window.dataLayer){
        const self = this

        console.log('GTM add_to_cart')

        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            items: [{
              item_name: 'widget_osago',
              item_id: self.dataUser?.offer?.id,
              price: self.dataUser?.offer?.franchiseActive?.price?.price,
              item_brand: self.dataUser?.offer?.company?.title,
              item_category: self.dataUser?.type?.name,
              item_category2: self.dataUser?.vehicle?.name,
              item_category3: self.dataUser?.offer?.about?.list,
              item_variant: self.dataUser?.offer?.offerFranchise,
              quantity: 1
            }]
          }
        })
      }
    }
  },
  validations() {
    const validationObject = {
      formInfo: {
       
        email: {
          required,
          checkEmail: validationHelpers.email,
        },
        phone: {
          required,
          minLength: minLength(13),
        },
        carYear: {
          required,
        },
        brand: {
          minLength: minLength(4),
          required,
        },
        carNumber: {
          required,
          minLength: minLength(4),
        },
        vin: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(17),
        },
        startPolicy: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
          checkData: validationHelpers.dataValidation,
        },
        endPolicy: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
          checkData: validationHelpers.dataValidation,
        },
        otkDate: {
          required: this.formInfo.otk ? required : false,
          minLength: minLength(this.formInfo.otk ? 10 : 0),
        },
      },
    };

    if(this.ifLegal){
      validationObject.formInfo['companyName'] = {
        required,
          maxLength: maxLength(100),
      }
    } else {
      validationObject.formInfo['userName'] = {
        required,
        checkName: this.userNameValidation,
      }
    }
    return validationObject
  },
};
</script>

<style lang="scss" scoped>
.wio-step-4 {
  .wio-step-left {
    @include media(">=lg") {
      padding-right: 80px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        width: 1px;
        background: $primary;
        opacity: 0.1;
      }
    }
  }

  .btn-step-footer {
    @include media(">=lg") {
      align-items: end !important;
    }
  }

  .sub-title {
    margin: 35px 10px 20px;
    font-size: 18px;
  }

  .wio-form-wrapper {
    position: relative;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wio-step wio-step-5"},[_c('div',{staticClass:"btn-back-wrapper",class:{ 'loading-disabled': _vm.loading }},[_c('UIButton',{staticClass:"btn-go-back",attrs:{"text":_vm.stepData.btnPrevText},on:{"click":function($event){return _vm.$emit('prevStep')}}})],1),_c('UIStepHeading',{class:{ 'loading-disabled': _vm.loading },attrs:{"items":_vm.stepList,"active-index":4,"way-flag":_vm.dataUser.wayIndex}}),_c('div',{staticClass:"wio-step-sidebar"},[_c('div',{staticClass:"wio-step-left"},[_c('UIAttention',{staticClass:"osago-attention mb-20",class:{ 'loading-disabled': _vm.loading },staticStyle:{"margin-top":"0","max-width":"590px"}},[_vm._v(" "+_vm._s(_vm.stepData.attentionText)+" ")]),_c('div',{ref:"formWrapper",staticClass:"wio-form-wrapper"},[_c('div',{staticClass:"wio-form-row"},[(_vm.ifLegal)?_c('UIInput',{staticClass:"form-col",class:{ disabled: _vm.loading },attrs:{"label":_vm.stepData.fields.companyName.label,"placeholder":_vm.stepData.fields.companyName.placeholder,"error":!!_vm.errors.companyName || _vm.$v.formInfo.companyName.$error,"error-text":_vm.errors.companyName
                ? _vm.errors.companyName.messages[0]
                : _vm.stepData.fields.companyName.error,"show-required":true},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.companyName, 'companyName')}},model:{value:(_vm.$v.formInfo.companyName.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.companyName, "$model", $$v)},expression:"$v.formInfo.companyName.$model"}}):_c('UIInput',{staticClass:"form-col",class:{ disabled: _vm.loading },attrs:{"label":_vm.stepData.fields.userName.label,"placeholder":_vm.stepData.fields.userName.placeholder,"error":!!_vm.errors.userName || _vm.$v.formInfo.userName.$error,"error-text":_vm.errors.userName
                ? _vm.errors.userName.messages[0]
                : _vm.stepData.fields.userName.error,"show-required":true},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.userName, 'userName')}},model:{value:(_vm.$v.formInfo.userName.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.userName, "$model", $$v)},expression:"$v.formInfo.userName.$model"}})],1),_c('div',{staticClass:"wio-form-row"},[_c('UIInput',{staticClass:"form-col-12",class:{ disabled: _vm.loading },attrs:{"label":_vm.stepData.fields.address.label,"placeholder":_vm.stepData.fields.address.placeholder,"error":!!_vm.errors.address || _vm.$v.formInfo.address.$error,"error-text":_vm.errors.address
                ? _vm.errors.address.messages[0]
                : _vm.stepData.fields.address.error,"show-required":true},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.address, 'address')}},model:{value:(_vm.$v.formInfo.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.address, "$model", $$v)},expression:"$v.formInfo.address.$model"}})],1),(!_vm.ifLegal)?_c('div',{staticClass:"wio-form-row"},[_c('UIInput',{staticClass:"form-col-6",class:{ disabled: _vm.loading },attrs:{"label":_vm.stepData.fields.ipn.label,"placeholder":_vm.stepData.fields.ipn.placeholder,"error":!!_vm.errors.ipn || _vm.$v.formInfo.ipn.$error,"error-text":_vm.errors.ipn ? _vm.errors.ipn.messages[0] : _vm.stepData.fields.ipn.error,"inputmode":'numeric',"pattern":'[0-9]*',"mask":'ipn',"show-required":true,"tooltip":_vm.stepData.fields.ipn.tooltip || 'test tooltip'},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.ipn, 'ipn')}},model:{value:(_vm.$v.formInfo.ipn.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.ipn, "$model", $$v)},expression:"$v.formInfo.ipn.$model"}}),_c('UIDatePicker',{staticClass:"form-col-6",class:_vm.bornClass,attrs:{"id":_vm.stepData.fields.born.id,"error-text":_vm.stepData.fields.born.error,"placeholder":_vm.stepData.fields.born.placeholder,"max":_vm.setMinBornDate(),"label":_vm.stepData.fields.born.label},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.born, 'born')}},model:{value:(_vm.$v.formInfo.born.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.born, "$model", $$v)},expression:"$v.formInfo.born.$model"}})],1):_vm._e(),(!_vm.ifLegal)?_c('div',{staticClass:"wio-form-row"},[_c('div',{class:[
              'wio-input-wrapper form-col-6',
              'crear',
              { filled: _vm.formInfo.document },
              { error: _vm.$v.formInfo.document.$error },
              { disabled: _vm.loading },
            ]},[(_vm.stepData.fields.document.label)?_c('label',[_vm._v(" "+_vm._s(_vm.stepData.fields.document.label)+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]):_vm._e(),_c('v-select',{attrs:{"searchable":false,"placeholder":_vm.stepData.fields.document.placeholder,"options":_vm.stepData.fields.document.values,"label":"name"},on:{"input":_vm.changeDocument},model:{value:(_vm.$v.formInfo.document.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.document, "$model", $$v)},expression:"$v.formInfo.document.$model"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.stepData.fields.document.error))])],1),(!_vm.ifDocumentFields1)?_c('UIInput',{staticClass:"form-col-3",class:{ disabled: _vm.loading },attrs:{"id":'test',"label":_vm.stepData.fields.documentFields1.label[_vm.documentTypeKey],"placeholder":_vm.stepData.fields.documentFields1.placeholder[_vm.documentTypeKey],"error":!!_vm.errors.documentFields1 || _vm.$v.formInfo.documentFields1.$error,"error-text":_vm.errors.documentFields1
                ? _vm.errors.documentFields1.messages[0]
                : _vm.stepData.fields.documentFields1.error[_vm.documentTypeKey],"show-required":true,"mask":_vm.documentFields1Mask},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.documentFields1, 'documentFields1')}},model:{value:(_vm.$v.formInfo.documentFields1.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.documentFields1, "$model", $$v)},expression:"$v.formInfo.documentFields1.$model"}}):_vm._e(),_c('UIInput',{class:[
              `form-col-${_vm.ifDocumentFields1 ? '6' : '3'}`,
              { disabled: _vm.loading },
            ],attrs:{"label":_vm.stepData.fields.documentFields2.label[_vm.documentTypeKey],"placeholder":_vm.stepData.fields.documentFields2.placeholder[_vm.documentTypeKey],"error":!!_vm.errors.documentFields2 || _vm.$v.formInfo.documentFields2.$error,"inputmode":'numeric',"pattern":'[0-9]*',"error-text":_vm.errors.documentFields2
                ? _vm.errors.documentFields2.messages[0]
                : _vm.stepData.fields.documentFields2.error[_vm.documentTypeKey],"show-required":true,"mask":_vm.documentFields2Mask},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.documentFields2, 'documentFields2')}},model:{value:(_vm.$v.formInfo.documentFields2.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.documentFields2, "$model", $$v)},expression:"$v.formInfo.documentFields2.$model"}})],1):_vm._e(),(_vm.ifLegal)?_c('div',{staticClass:"wio-form-row"},[_c('UIInput',{class:{ disabled: _vm.loading },attrs:{"label":_vm.stepData.fields.edrpou.label,"placeholder":_vm.stepData.fields.edrpou.placeholder,"error":!!_vm.errors.edrpou || _vm.$v.formInfo.edrpou.$error,"error-text":_vm.errors.edrpou
                ? _vm.errors.edrpou.messages[0]
                : _vm.stepData.fields.edrpou.error,"mask":'edrpou',"show-required":true},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.edrpou, 'edrpou')}},model:{value:(_vm.$v.formInfo.edrpou.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.edrpou, "$model", $$v)},expression:"$v.formInfo.edrpou.$model"}})],1):_vm._e(),(!_vm.ifLegal)?_c('div',{staticClass:"wio-form-row"},[_c('UIInput',{class:['form-col-9', { disabled: _vm.ifEPension || _vm.loading }],attrs:{"label":_vm.stepData.fields.documentPlace.label[_vm.documentTypeKey],"placeholder":_vm.stepData.fields.documentPlace.placeholder[_vm.documentTypeKey],"error":!!_vm.errors.documentPlace || _vm.$v.formInfo.documentPlace.$error,"error-text":_vm.errors.documentPlace
                ? _vm.errors.documentPlace.messages[0]
                : _vm.stepData.fields.documentPlace.error[_vm.documentTypeKey],"show-required":!_vm.ifEPension,"mask":_vm.documentPlaceMask},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.documentPlace, 'documentPlace')}},model:{value:(_vm.$v.formInfo.documentPlace.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.documentPlace, "$model", $$v)},expression:"$v.formInfo.documentPlace.$model"}}),_c('UIDatePicker',{class:[
              'form-col-3',
              {
                error:
                  !!_vm.errors.documentDate || _vm.$v.formInfo.documentDate.$error,
              },
              { disabled: _vm.loading },
            ],attrs:{"id":_vm.stepData.fields.documentDate.id,"error-text":_vm.errors.documentDate
                ? _vm.errors.documentDate.messages[0]
                : _vm.stepData.fields.documentDate.error[_vm.documentTypeKey],"placeholder":_vm.stepData.fields.documentDate.placeholder,"label":_vm.stepData.fields.documentDate.label[_vm.documentTypeKey],"max":_vm.documentTypeKey === 'E_PENSION_CERTIFICATE'
                ? false
                : _vm.df(new Date()),"min":_vm.documentTypeKey === 'E_PENSION_CERTIFICATE'
                ? _vm.df(_vm.getTomorrow())
                : false,"document-date":true},model:{value:(_vm.$v.formInfo.documentDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.documentDate, "$model", $$v)},expression:"$v.formInfo.documentDate.$model"}})],1):_vm._e(),_c('div',{staticClass:"wio-form-row"},[_c('UIInputTell',{staticClass:"form-col-6",class:{ disabled: _vm.loading },attrs:{"label":_vm.stepData.fields.phone.label,"placeholder":_vm.stepData.fields.phone.placeholder,"error":!!_vm.errors.phone || _vm.$v.formInfo.phone.$error,"error-text":_vm.errors.phone
                ? _vm.errors.phone.messages[0]
                : _vm.stepData.fields.phone.error,"show-required":true,"mask":'tell',"type":'tel'},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.phone, 'phone')}},model:{value:(_vm.$v.formInfo.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.phone, "$model", $$v)},expression:"$v.formInfo.phone.$model"}}),_c('UIInput',{directives:[{name:"tippy",rawName:"v-tippy",value:({ onShow: () => !!_vm.stepData.fields.email.label }),expression:"{ onShow: () => !!stepData.fields.email.label }"}],staticClass:"form-col-6",class:{ disabled: _vm.loading },attrs:{"content":_vm.stepData.fields.email.label,"label":_vm.stepData.fields.email.label,"placeholder":_vm.stepData.fields.email.placeholder,"error":!!_vm.errors.email || _vm.$v.formInfo.email.$error,"error-text":_vm.errors.email
                ? _vm.errors.email.messages[0]
                : _vm.stepData.fields.email.error,"show-required":true},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.email, 'email')}},model:{value:(_vm.$v.formInfo.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.email, "$model", $$v)},expression:"$v.formInfo.email.$model"}})],1),_c('div',{staticClass:"btn-step-footer"},[_c('UIButton',{staticClass:"mb-20",attrs:{"text":_vm.stepData.btnNextText,"loader":_vm.loading},on:{"click":_vm.submitHandler}})],1)])],1),_c('div',{staticClass:"wio-step-right",class:{ 'loading-disabled': _vm.loading }},[(_vm.sideBarData)?_c('UISideBar',{attrs:{"data-object":_vm.sideBarData}}):_vm._e(),(_vm.stepData && _vm.stepData.payment)?_c('UIPayCard',{attrs:{"text":_vm.stepData.payment.text,"value":_vm.stepData.payment.price}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
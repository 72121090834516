<template>
  <div class="wio-stepper">
    <div class="wio-stepper__container">
      <div
        v-for="(item, index) in items"
        :key="`item${index}`"
        :class="[
          'wio-stepper__item',
          { 'wio-stepper__item--done': index < activeIndex },
          { 'wio-stepper__item--active': index === activeIndex },
        ]"
      >
        <div class="wio-stepper__icon">
          <span :class="`icon-${icons[index]}`"></span>
        </div>
        <div class="wio-stepper__text">
          <div class="wio-stepper__text--value">
            {{ getItem(item) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepperComponent",
  props: {
    activeIndex: {
      type: Number,
      default: () => 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    wayFlag: {
      type: Number,
      default: () => 0,
    },
    icons: {
      type: Array,
      default: () => [
        "car-number",
        "settings",
        "choice",
        "car",
        "user-v2",
        "package",
      ],
    },
  },
  computed: {
    itemWidth() {
      return `mwidth: ${100 / this.items.length}%`;
    },
  },
  methods: {
    getItem(item) {
      const isArray = Array.isArray(item);

      if (!isArray) {
        return item;
      }
      return item[this.wayFlag];
    },
  },
};
</script>

<style lang="scss" scoepd>
.wio-stepper {
  background: $primary-dark;
  color: $white;
  padding: 20px 0;
  font-weight: 600;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;

  @include media(">=md") {
    padding: 50px 0;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: 100px;
    border: 1px solid rgba($white, 0.2);
    transition: $transition;
    position: relative;

    &:last-child {
      margin-right: 0 !important;

      &:before {
        display: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      right: -15px;
      top: 50%;
      height: 1px;
      width: 0;
      background: rgba($white, 0.2);
      transition: $transition;

      @include media(">=md") {
        right: -45px;
      }
    }

    & + .wio-stepper__item {
      margin-left: 5px;

      @include media(">=md") {
        margin-left: 15px;
      }
    }

    &--active {
      border-color: white;
      background: $white;
      color: $primary;
      border-color: $white;
      margin-right: 15px;

      @include media(">=md") {
        margin-right: 45px;
      }

      &:before {
        width: 10px;

        @include media(">=md") {
          width: 30px;
        }
      }

      .wio-stepper__text {
        max-width: 300px;
      }
    }

    &--done {
      background: $primary;
    }
  }

  &__icon {
    height: 22px;
    width: 22px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $base-font-size-s;

    @include media(">=md") {
      font-size: 20px;
    }

    @include media(">=md") {
      height: 42px;
      width: 42px;
    }
  }

  &__text {
    transition: $transition;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    font-size: $base-font-size-s;

    &--value {
      padding-right: 10px;

      @include media(">=md") {
        padding-right: 15px;
        font-size: $base-font-size;
      }
    }
  }
}
</style>
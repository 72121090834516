<template>
  <div class="advantage-list">
    <div
      v-for="item in list"
      :key="item.text"
      :class="['advantage-list__item', `color-${item.value}`]"
    >
      <div class="advantage-list__icon icon-success"></div>
      <div class="advantage-list__text">
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvantegeList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.advantage-list {
  &__item {
    display: flex;

    & + & {
      margin-top: 10px;
    }
  }

  &__icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #59ac8d;
    margin-right: 10px;
    color: $white;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;

    .color-2 > & {
      background: red;
    }
    .color-1 > & {
      background: orange;
    }

    &:before {
    }
  }

  &__text {
    font-size: 14px;
  }
}
</style>

import CONSTANTS from '@/helper/constants'

function isEmpty(value) {
  return value === undefined || value === null;
}

function setCookie(cname, cvalue) {
  localStorage.setItem(cname, cvalue)
  // const d = new Date();
  // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  // let expires = "expires="+d.toUTCString();
  // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname){
  // let name = cname + "=";
  // let ca = document.cookie.split(';');
  // for(let i = 0; i < ca.length; i++) {
  //   let c = ca[i];
  //   while (c.charAt(0) == ' ') {
  //     c = c.substring(1);
  //   }
  //   if (c.indexOf(name) == 0) {
  //     return JSON.parse(c.substring(name.length, c.length));
  //   }
  // }
  // return "";
  const value = localStorage.getItem(cname)
  if(value !== 'undefined' &&  value !== 'null'){
    return JSON.parse(value)
  } else {
    return null
  }
}

function setUserDataToCookie(data){
  const oldCookie = getCookie(CONSTANTS.COOKIE_NAME)
  const newCookie = {}

  Object.keys(data)?.forEach(key => {
    if(!isEmpty(data[key])){
      newCookie[key] = data[key]
    }
  })

  setCookie(CONSTANTS.COOKIE_NAME, JSON.stringify({...oldCookie, ...newCookie}))
}

export {setCookie as setCookie}
export {getCookie as getCookie}
export {setUserDataToCookie as setUserDataToCookie}
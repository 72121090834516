var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'offer',
    { 'style-2': _vm.style2 },
    { 'offer--with-flag': _vm.flagsList.length },
    { 'loading-disabled': _vm.disable && !_vm.loading },
  ]},[(_vm.flagsList.length && !_vm.flagHide)?_c('FlagsList',{class:{ 'loading-disabled': _vm.disable && _vm.loading },attrs:{"list":_vm.flagsList}}):_vm._e(),_c('div',{staticClass:"offer__content"},[_c('div',{class:[
        'offer__brand-info',
        { 'offer__brand-info--with-flag': _vm.flagsList.length },
        { 'loading-disabled': _vm.disable && _vm.loading },
      ]},[_c('div',[(_vm.brandImg.src)?_c('div',{staticClass:"offer--logo"},[_c('UIImage',{attrs:{"image-data":_vm.brandImg,"loading":"lazy"}})],1):_vm._e(),(_vm.speedOfPayment)?_c('SpeedOfPayment',{attrs:{"data-object":_vm.speedOfPayment}}):_vm._e()],1),(_vm.advantageList)?_c('AdvantageList',{attrs:{"list":_vm.advantageList}}):_vm._e(),(_vm.topList)?_c('StarsList',{attrs:{"list":_vm.topList}}):_vm._e()],1),_c('div',{staticClass:"offer__main",class:{ 'loading-disabled': _vm.disable && _vm.loading }},[_c('h4',{staticClass:"offer__title"},[_vm._v(" "+_vm._s(_vm.dataObject.company.text)+" ")]),_c('div',{staticClass:"offer__price mobile"},[(_vm.proposal)?_c('div',{staticClass:"offer__proposal"},[_c('span',{staticClass:"icon-fire"}),_c('span',[_vm._v(_vm._s(_vm.proposal))])]):_vm._e(),(_vm.dataObject.advantageMobile)?_c('div',{staticClass:"price-list-item"},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(_vm.dataObject.advantageMobile.name)+" ")]),_c('div',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.dataObject.advantageMobile.value)+" ")])]):_vm._e(),_c('div',{staticClass:"price-list-item"},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(_vm.priceMobileText)+" ")]),_c('div',{staticClass:"right"},[(_vm.price)?_c('Price',{attrs:{"price":_vm.price}}):_vm._e()],1)])]),(_vm.franchiseList)?_c('Franchise',{attrs:{"data-object":_vm.franchiseList},model:{value:(_vm.franshiseValue),callback:function ($$v) {_vm.franshiseValue=$$v},expression:"franshiseValue"}}):_vm._e(),(_vm.aboutBtn)?_c('div',{staticClass:"offer__about-opener desktop"},[_c('span',{staticClass:"offer__about-opener--prefix"},[_vm._v(" "+_vm._s(_vm.aboutBtn.prefixText)+" ")]),_c('span',{staticClass:"offer__about-opener--text",on:{"click":function($event){_vm.isDropOpen = !_vm.isDropOpen}}},[_c('div',{staticClass:"offer__about-opener--text--value"},[_vm._v(" "+_vm._s(_vm.aboutBtn.text)+" ")]),_c('div',{class:[
              'offer__about-opener--text--icon',
              { 'offer__about-opener--text--icon--active': _vm.isDropOpen },
            ]},[_c('span',{staticClass:"icon-arrow-new"})])])]):_vm._e()],1),_c('div',{staticClass:"offer__price desktop"},[(_vm.proposal)?_c('div',{staticClass:"offer__proposal",class:{ 'loading-disabled': _vm.disable && _vm.loading }},[_c('span',{staticClass:"icon-fire"}),_c('span',[_vm._v(_vm._s(_vm.proposal))])]):_vm._e(),(_vm.price)?_c('Price',{attrs:{"price":_vm.price}}):_vm._e(),_c('div',{staticClass:"btn-wrapper"},[_c('UIButton',{staticClass:"btn-small offer-btn",class:[{ disabled: _vm.disable && !_vm.loading }],attrs:{"text":_vm.dataObject.btnButText,"loader":_vm.loading},on:{"click":function($event){return _vm.$emit('buyPolicy', _vm.currentData)}}})],1),(_vm.infoIcons)?_c('InfoIconsList',{attrs:{"list":_vm.infoIcons}}):_vm._e()],1)]),_c('div',{staticClass:"btn-wrapper mobile"},[_c('UIButton',{staticClass:"btn-small offer-btn",class:[{ disabled: _vm.disable && !_vm.loading }],attrs:{"text":_vm.dataObject.btnButText,"loader":_vm.loading},on:{"click":function($event){return _vm.$emit('buyPolicy', _vm.currentData)}}})],1),(_vm.aboutBtn)?_c('div',{staticClass:"offer__about-opener mobile",class:{ 'loading-disabled': _vm.disable && _vm.loading }},[_c('span',{staticClass:"offer__about-opener--text",on:{"click":function($event){_vm.isDropOpen = !_vm.isDropOpen}}},[_c('div',{staticClass:"offer__about-opener--text--value"},[_vm._v(" "+_vm._s(_vm.aboutBtn.text)+" ")]),_c('div',{class:[
          'offer__about-opener--text--icon',
          { 'offer__about-opener--text--icon--active': _vm.isDropOpen },
        ]},[_c('span',{staticClass:"icon-arrow-new"})])])]):_vm._e(),(_vm.aboutBtn)?_c('div',{ref:"drop",staticClass:"offer__drop",style:(`height:${_vm.dropHeight}`)},[_c('Drop',{attrs:{"data-object":_vm.aboutData}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="stars-list">
    <div v-for="item in list" :key="item" class="stars-list__item">
      <div class="stars-list__icon icon-star"></div>
      <div class="stars-list__text">
        <!-- <span class="stars-list__value">{{ item.top }}</span> -->
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StarsList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.stars-list {
  @include media("<md") {
    display: none;
  }

  &__item {
    display: flex;

    & + & {
      margin-top: 7px;
    }
  }

  &__icon {
    height: 15px;
    width: 15px;
    margin-right: 8px;
    color: $orange;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;

    @include media(">=md") {
      font-size: 16px;
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
  }

  &__text {
    font-size: $base-font-size-s;

    @include media(">=md") {
      font-size: 14px;
    }
  }

  &__value {
    font-size: 14px;
    font-weight: 500;

    @include media(">=md") {
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div class="info-icons-list">
    <div
      v-for="(item, index) in list"
      :key="`${item.src}${index}`"
      class="info-icons-list__item"
    >
      <UIImage :image-data="item" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoIconsList',
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss" scoped>
.info-icons-list {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    height: 30px;
    width: 30px;
    border: 1px solid $grey-400;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }
}
</style>

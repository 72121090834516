<template>
  <div class="pay-card">
    <div class="pay-card__text">
      {{ text }}
    </div>
    <div v-if="value" class="pay-card__value">
      <div
        :class="[
          isExistNewValue ? 'pay-card__old-value' : 'pay-card__current-value',
        ]"
      >
        {{ value.value }}
      </div>
      <div v-if="isExistNewValue" class="pay-card__new-value">
        {{ value.newValue }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayCard",
  props: {
    text: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isExistNewValue() {
      return !!this.value && this.value?.newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba($green-dark, 0.1);
  border: 1px solid $green-dark;
  border-radius: $base-border-radius-m;
  width: 100%;
  padding: 20px;

  &__value {
    display: flex;
    font-size: 18px;
    line-height: 1.5em;

    @include media(">=md") {
      font-size: 24px;
    }
  }

  &__new-value {
    color: $red-800;
  }

  &__old-value {
    position: relative;
    color: $grey-800;
    font-size: $base-font-size;
    text-decoration: line-through;
    margin-right: 10px;
  }

  &__text {
    font-size: $base-font-size-s;
    line-height: 1.5em;

    @include media(">=md") {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div class="wio-step-heading">
    <div class="wio-step-heading__container">
      <div class="wio-step-heading__list">
        <div
          v-for="(item, index) in items"
          :key="`item${index}`"
          :class="[
            'wio-step-heading__item',
            { 'wio-step-heading__item--done': index < activeIndex },
            { 'wio-step-heading__item--active': index === activeIndex },
            { 'wio-step-heading__item--hide-mobile': index < activeIndex - 1 },
            { 'wio-step-heading__item--hide-mobile': index > activeIndex + 1 },
          ]"
        >
          <div class="wio-step-heading__icon">
            <span :class="`icon-${icons[index]}`"></span>
          </div>
          <div class="wio-step-heading__text">
            <div class="wio-step-heading__text--value">
              {{ getItem(item) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepHeading",
  props: {
    activeIndex: {
      type: Number,
      default: () => 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    wayFlag: {
      type: Number,
      default: () => 0,
    },
    icons: {
      type: Array,
      default: () => [
        "car-number",
        "settings",
        "choice",
        "car",
        "user-v2",
        "package",
      ],
    },
  },
  computed: {
    itemWidth() {
      return `mwidth: ${100 / this.items.length}%`;
    },
  },
  methods: {
    getItem(item) {
      const isArray = Array.isArray(item);

      if (!isArray) {
        return item;
      }
      return item[this.wayFlag];
    },
  },
};
</script>

<style lang="scss" scoped>
.wio-step-heading {
  background: transparent;
  color: $primary;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin: 15px 0 20px;

  @include media(">=md") {
    font-size: 14px;
    margin: 15px 0 30px;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: 100px;
    border: 1px solid $primary;
    transition: $transition;
    position: relative;

    &:last-child {
      margin-right: 0 !important;

      &:before {
        display: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      right: -15px;
      top: 50%;
      height: 1px;
      width: 0;
      background: $primary;
      transition: $transition;

      @include media(">=md") {
        right: -45px;
      }
    }

    & + .wio-step-heading__item {
      margin-left: 5px;

      @include media(">=md") {
        margin-left: 15px;
      }
    }
    &--hide-mobile {
      @include media("<md") {
        display: none;
      }
    }

    &--active {
      background: $white;
      color: $primary;
      margin-right: 15px;
      position: relative;

      @include media(">=md") {
        margin-right: 45px;
      }

      &:before {
        width: 10px;

        @include media(">=md") {
          width: 30px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: $primary;
        z-index: 1;
        opacity: 0.05;
        border-radius: 50px;
      }

      .wio-step-heading__text {
        max-width: 300px;
        position: relative;
        z-index: 2;
      }

      .wio-step-heading__icon {
        z-index: 2;
      }
    }

    &--done {
      background: $primary;
      color: $white;
    }
  }

  &__icon {
    height: 22px;
    width: 22px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $base-font-size-s;

    @include media(">=md") {
      font-size: 24px;
    }

    @include media(">=md") {
      height: 42px;
      width: 42px;
    }
  }

  &__text {
    transition: $transition;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;

    &--value {
      padding-right: 10px;

      @include media(">=md") {
        padding-right: 15px;
      }
    }
  }
}
</style>

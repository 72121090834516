<template>
  <a v-if="href" :href="href" class="wio-btn" @click="(e) => $emit('click', e)">
    <slot name="icon" />

    {{ text }}
  </a>
  <button
    v-else
    :class="['wio-btn', { loading: loader }]"
    @click="(e) => $emit('click', e)"
  >
    <slot name="icon" class="icon" />
    <UILoader v-if="loader" />

    {{ text }}
    <slot name="icon-after" class="icon icon-after" />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    text: {
      type: String,
      default: "btn lorem",
    },
    href: {
      type: String,
      default: "",
    },
    router: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wio-btn {
  font-size: $btn-size-s;
  color: $btn-color;
  background: $btn-background;
  border: $btn-border;
  border-radius: $btn-radius;
  min-height: 50px;
  max-width: $btn-width-s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  padding: $btn-padding;
  cursor: pointer;
  outline: none;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-weight: 600;

  @include media(">=md") {
    padding: $btn-padding;
    font-size: $btn-size;
    min-height: $btn-height;
    max-width: $btn-width;
  }

  &:hover,
  &.hover,
  &.btn-hover {
    border-color: $btn-border-color-hover;
    background: $btn-background-hover;

    &.secondary,
    &.btn-secondary,
    &[secondary],
    &[secondary="true"] {
      background: $secondary;
      color: $white;
      border-color: transparent;

      &.btn-square,
      &.square,
      &[square],
      &[square="true"] {
        border-color: transparent;
      }
    }

    &.btn-prim,
    &.prim {
      background: $primary;
      border-color: $primary;
      color: $white;

      .icon {
        color: $white;
      }
    }
  }

  &:active,
  &.active,
  &.btn-active {
    background: $btn-background;
    border-color: $btn-border-color;
    outline: none;
    box-shadow: none !important;

    &.secondary,
    &.btn-secondary,
    &[secondary],
    &[secondary="true"] {
      background: $secondary;
      border-color: $secondary;
      color: $white;
    }

    &.btn-prim,
    &.prim {
      background: $primary;
      border-color: $primary;
      color: $white;

      .icon {
        color: $white;
      }
    }
  }

  &:focus,
  &.focus,
  &.btn-focus {
    border-color: $btn-border-color-focus;
    outline: none;
    box-shadow: none;

    &.secondary,
    &.btn-secondary,
    &[secondary],
    &[secondary="true"] {
      border-color: $btn-border-color-focus;

      &.btn-square,
      &.square,
      &[square],
      &[square="true"] {
        border-color: $btn-border-color-focus;
      }
    }
  }

  &::v-deep .loader {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    .wio-svg-loader {
      color: $white;
    }
  }

  &:disabled,
  &.disabled,
  &.btn-disabled,
  &[disabled],
  &[disabled="true"] {
    pointer-events: none !important;
    border-color: $btn-border-color-disabled;
    background: $btn-background-disabled;

    &::v-deep .loader {
      border-top-color: $grey-300;
    }
  }

  &.btn-small {
    font-size: $btn-size-s-mobile;
    min-height: $btn-height-s;
    max-width: $btn-width-s;

    @include media(">=md") {
      font-size: $btn-size-s;
    }

    &::v-deep .loader {
      width: 38px;
      height: 38px;
      border-width: 7px;
      border-top-width: 7px;
    }
  }

  &.btn-full-transparent {
    background: transparent !important;
    border-color: transparent !important;
    color: $grey-800;

    &:hover {
      color: $primary;
      transition: $transition;
    }
  }

  &.go-back,
  &.btn-go-back {
    display: inline-flex;
    padding: 0 5px 0;
    height: auto;
    width: auto;
    min-height: 0;
    min-width: 0;
    font-size: $btn-size-s;
    border: none;
    background: transparent;
    color: $primary;

    &:hover,
    &.hover,
    &.btn-hover {
      color: darken($grey-800, 10%);
    }

    &::before {
      content: $icon-arrow-back;
      font-family: $icomoon-font-family;
      margin-right: 8px;
      font-size: 6px;
    }
  }

  &.loading,
  &.btn-loading {
    pointer-events: none !important;
    color: transparent;

    .loader-wrapper {
      background: inherit;
      border-radius: $btn-radius;
    }

    &.secondary,
    &.btn-secondary,
    &[secondary],
    &[secondary="true"] {
      color: transparent;
      background: rgba($secondary, 0.3);

      &::v-deep .loader-wrapper {
        background: transparent;
      }
    }
  }

  &.secondary,
  &.btn-secondary,
  &[secondary],
  &[secondary="true"] {
    background: $white;
    color: $secondary;
    border-color: rgba($color: $secondary, $alpha: 0.3);
    text-decoration: none !important;

    &.btn-square,
    &.square,
    &[square],
    &[square="true"] {
      border-color: $secondary;
    }

    &::v-deep .loader {
      width: 35px;
      height: 35px;
      border: 6px solid rgba($white, 0.4);
      border-top: 6px solid rgba($secondary, 0.3);
    }
  }

  &.btn-square,
  &.square,
  &[square],
  &[square="true"] {
    border-width: 1px;
    border-radius: $base-border-radius-s;
  }

  &.btn-prim,
  &.prim {
    border-color: $grey-400;
    background: $white;
    color: $base-color;
    font-weight: 400;
    font-size: 14px;

    .icon {
      color: $primary;
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

// .btn {
//   font-size: 14px;
//   border-radius: $base-border-radius;
//   min-width: 186px;
//   padding: 17px 15px;
//   text-align: center;
//   border: 2px solid $base-color-error;
//   color: $base-color-error;
//   transition: $transition;

//   @include media('>=lg') {
//     font-size: 16px;
//   }

//   &:hover {
//     color: $white;
//     background: $base-color-error;
//     box-shadow: 0px 0px 100px rgba($base-color-error, 0.6);
//   }

//   &:focus {
//     box-shadow: none;
//   }

//   &.btn-large {
//     padding: 20px;
//     max-width: 350px;
//     font-size: 18px;
//     width: 100%;
//     display: flex;
//     align-content: center;
//     justify-content: center;
//     box-shadow: 0px 1px 5px rgba(113, 12, 19, 0.15),
//       0px 4px 4px rgba(113, 12, 19, 0.25),
//       0px 2px 2px -2px rgba(113, 12, 19, 0.15);

//     @include media('>=sm') {
//       padding: 25px;
//       max-width: 459px;
//       font-size: 20px;
//     }

//     [class^='icon-'],
//     [class*=' icon-'] {
//       font-size: 30px;
//       margin-right: 20px;
//     }
//   }

//   &.loader {
//     position: relative;
//     overflow: hidden;
//     color: transparent !important;

//     .loader-wrapper {
//       border-radius: 100px;
//       overflow: hidden;
//     }
//   }

//   &[disabled] {
//     pointer-events: none;
//     color: $base-color !important;
//     border-color: $base-color !important;
//     opacity: 0.2;
//   }

//   &.btn-add {
//     border: none;
//     font-weight: 600;
//     font-size: 16px;
//     border-radius: 0;
//     padding: 0;
//     color: $primary;
//     background: transparent;
//     text-align: left;
//     display: flex;
//     align-items: center;

//     &:hover,
//     &.active {
//       background: transparent;
//       color: $primary;
//       box-shadow: none;

//       .add-icon {
//         background: $primary;

//         &:before,
//         &:after {
//           background: $white;
//         }
//       }
//     }

//     .add-icon {
//       width: 30px;
//       height: 30px;
//       border: 1px solid $primary;
//       margin-right: 13px;
//       position: relative;
//       transition: $transition;

//       &:before,
//       &:after {
//         content: '';
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         border-radius: 2px;
//         background: $primary;
//         height: 2px;
//         width: 12px;
//         transition: $transition;
//       }

//       &:after {
//         height: 12px;
//         width: 2px;
//       }
//     }
//   }

//   &.btn-slim {
//     padding: 12px 15px;
//     min-width: 160px;

//     @include media('>=lg') {
//       min-width: 178px;
//       padding: 13px 15px;
//     }
//   }

//   &.btn-lg {
//     min-width: 200px;
//     padding: 15px 10px;
//     font-size: 18px;
//     font-weight: 600;

//     @include media('>=md') {
//       min-width: 240px;
//     }

//     @include media('>=lg') {
//       min-width: 270px;
//       font-size: 19px;
//       padding: 22px 10px;
//     }

//     @include media('>=xl') {
//       min-width: 300px;
//       padding: 27px 15px;
//       font-size: 20px;
//     }
//   }

//   &.filled {
//     color: $white;
//     background: $base-color-error;

//     &.btn-green {
//       background: $primary;
//       color: $white;

//       &:hover {
//         background: $white;
//         color: $primary;
//       }
//     }
//   }

//   &.btn-clear-grey {
//     border-color: transparent;
//     color: $base-color;
//     opacity: 0.5;
//     min-width: 100px;

//     @include media('>=md') {
//       min-width: 120px;
//     }

//     &:hover {
//       border-color: transparent;
//       color: $base-color;
//       opacity: 1;
//       box-shadow: none;
//       background: transparent;
//     }
//   }

//   &.btn-green {
//     color: $green;
//     border-color: $green;

//     &:hover {
//       background: $green;
//       box-shadow: 0px 15px 60px rgba(18, 136, 92, 0.15);
//       color: $white;
//     }
//   }

//   &.btn-square {
//     border: none;
//     border-radius: 0;
//     background: $green-100;
//     color: $primary;
//     padding: 15px 45px;
//     min-width: 1px;
//     font-weight: 600;
//     font-size: 14px;

//     @include media('>=lg') {
//       font-size: 16px;
//       padding: 12px 40px;
//     }

//     &:hover {
//       box-shadow: none;
//       background: $primary;
//       color: $green-100;
//     }
//   }
//   &.btn-edit {
//     &:before {
//       content: $icon-icon_pen;
//       font-family: 'icomoon';
//       font-size: 16px;
//       margin-right: 10px;

//       @include media('>=lg') {
//         font-size: 20px;
//       }
//     }

//     &.btn-edit-small {
//       @include media('>=md') {
//         padding: 12px 15px;
//       }
//     }
//   }

//   &.btn-icon {
//     display: flex;
//     align-items: center;
//   }

//   &.btn-go-back {
//     border: none;
//     border-radius: 0;
//     background: transparent;
//     color: $primary;
//     padding: 12px 15px;
//     min-width: 1px;
//     font-size: 16px;

//     &:hover {
//       box-shadow: none;
//       background: transparent;
//       color: $primary;

//       &:before {
//         transform: translateX(-7px) rotate(-90deg);
//       }
//     }

//     &:before {
//       padding: 0 1px 0 0;
//       content: '\ea3b';
//       font-family: 'icomoon';
//       font-size: 16px;
//       margin-right: 10px;
//       transform: translateX(-3px) rotate(-90deg);
//       transition: $transition;
//     }

//     &.white {
//       color: $white;

//       &:hover {
//         color: $primary;
//       }
//     }
//   }
// }
</style>

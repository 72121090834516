

export default {
  OSAGO: {
      STORAGE_NAME: 'osagoStorage',
      WIDGET_STORAGE_NAME: 'widgetOS1SP',
      BASE_URL: 'osago',
      STEPS: [
          {
              URL: '',
              COMPONENT: 'OsagoStep1'
          },
          {
              URL: 'car',
              COMPONENT: 'OsagoStep2'
          },
          {
              URL: 'offer',
              COMPONENT: 'OsagoStep3'
          },
          {
              URL: 'car-data',
              COMPONENT: 'OsagoStep4'
          },
          {
              URL: 'personal-data',
              COMPONENT: 'OsagoStep5'
          },
          {
              URL: 'otp',
              COMPONENT: 'OsagoStep6'
          },
          {
              URL: 'payment-options',
              COMPONENT: 'OsagoStep7'
          },
          {
              URL: 'payment',
              COMPONENT: 'OsagoStep8'
          },
          {
              URL: 'success-payment',
              COMPONENT: 'OsagoStep9'
          }
      ],
      STEP_FIELDS_1: ['carNumber', 'type', 'privilegesBox', 'euroNumber', 'taxi', 'privileges', 'city', 'vehicle', 'promo'],
      STEP_FIELDS_2: ['city', 'vehicle', 'carYear', 'vin', 'startPolicy', 'endPolicy'],
      STEP_FIELDS_3: [],
      STEP_FIELDS_4: ['brand', 'carYear', 'vin', 'startPolicy', 'endPolicy', 'otk', 'otkDate', 'email', 'userName', 'phone', 'companyName'],
      STEP_FIELDS_5: ['userName', 'address', 'ipn', 'born', 'document', 'documentPlace', 'documentDate', 'email', 'phone', 'documentFields1', 'documentFields2', 'companyName', 'edrpou']
  },
  FONDY_URL: 'https://pay.fondy.eu/static_common/v1/checkout/ipsp.js',
  BASE_URL: process.env.VUE_APP_BASE_URL,
  PARTNER_TOKEN: 'partner_token',
  COOKIE_STATUS: false,
  COOKIE_NAME: 'WIDGET_OSAGO_USER',
  COOKIE_ACTIVE_INDEX: 'WIDGET_OSAGO_ACTIVE_INDEX',
  USER_TOKEN: process.env.VUE_APP_USER_TOKEN,
  USER_LOGS_KEY: 'USER_LOGS_KEY',
}

export default {
  tell: '##) ### ## ##',
  passport: 'AA######',
  otpCode: 'NN####',
  idPassport: '########-#####',
  idPassport2: '#########',
  documentPlace: '####',
  date: '##.##.####',
  ipn: '##########',
  edrpou: '########',
  digit6: '######',
  text2: 'ЯЯ',
  text3: 'ЯЯЯ',
  text4: 'ЯЯЯЯ',
  text5: 'ЯЯЯЯЯ',
  text6: 'ЯЯЯЯЯЯ',
  text7: 'ЯЯЯЯЯЯЯ',
  text8: 'ЯЯЯЯЯЯЯЯ',
  text9: 'ЯЯЯЯЯЯЯЯЯ',
  text10: 'ЯЯЯЯЯЯЯЯЯЯ',
  text11: 'ЯЯЯЯЯЯЯЯЯЯЯ',
  text12: 'ЯЯЯЯЯЯЯЯЯЯЯЯ',
  text13: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯ',
  text14: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯ',
  text15: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ',
  text16: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ',
  text17: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ',
  text18: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ',
  text19: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ',
  text20: 'ЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯ'
}

<template>
  <div class="step-prev-info">
    <div class="prev-info">
      <template v-for="(item, key, index) in prevValues.text">
        <div
          v-if="item && item.value"
          :key="`some${index}`"
          class="prev-info--item"
        >
          <span :class="['prev-info--icon', getIcon(key)]" />
          <!-- <span v-if="item.name" class="prev-info--name">
            {{ item.name }}
          </span> -->
          <span class="prev-info--value">
            {{ item.value.name ? item.value.name : item.value }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrevValue",
  props: {
    prevValues: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      icons: {
        carNumber: "icon-car-number",
        city: "icon-pin",
        vehicle: "icon-engine",
        taxi: "icon-taxi",
      },
    };
  },
  methods: {
    getIcon(type) {
      return this.icons[type] || "icon-choice";
    },
  },
};
</script>

<style lang="scss" scoped>
.prev-info {
  display: flex;
  flex-wrap: wrap;
  font-size: $base-font-size;
  margin-bottom: 20px;
  border-bottom: 1px solid $grey3;
  padding-bottom: 14px;

  @include media("<md") {
    flex-direction: column;
    margin-bottom: 10px;
  }

  &--item {
    display: flex;
    margin-bottom: 7px;
    align-items: center;

    & + & {
      @include media(">=md") {
        margin-left: 30px;
      }
    }
  }

  &--icon {
    margin-right: 10px;
    color: $grey3;
    font-size: 20px;

    @include media("<md") {
      font-size: 14px;
      min-width: 18px;
      display: flex;
      justify-content: center;
    }
  }

  &--name {
    font-weight: 600;
    margin-right: 10px;

    @include media(">=md") {
      margin-right: 15px;
    }
  }

  &--value {
  }
}
</style>

<template>
  <ul class="wio-tabs-menu">
    <li
      v-for="(item, index) in dataObject"
      :key="`windgetMenu${index}`"
      :class="['wio-tabs-menu__btn', { active: wayIndex === index }]"
      @click="tabClickHandler(index)"
    >
      <span
        :class="[
          index ? 'icon-car-number' : 'icon-category_b',
          'wio-tabs-menu__icon',
        ]"
      ></span>
      <span>{{ item }}</span>
    </li>
  </ul>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
export default {
  name: "WidgenMenu",
  setup() {
    const userStore = userData();
    return {
      userStore,
    };
  },
  props: {
    dataObject: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    wayIndex() {
      return this.userStore.getUser.wayIndex;
    },
  },
  methods: {
    ...mapActions(userData, ["setUserOne"]),
    tabClickHandler(index) {
      if (index != this.wayIndex) {
        this.setUserOne({
          key: "wayIndex",
          payload: index,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wio-tabs-menu {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-size: $base-font-size-s;
  font-weight: 500;
  line-height: 1.5em;
  position: relative;
  margin-bottom: 20px !important;

  @include media("<md") {
    max-width: 380px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $grey3;
  }

  @include media(">=md") {
    font-size: 14px;
  }

  li + li {
    margin-right: 20px;
  }

  .wio-tabs-menu__icon {
    margin-right: 10px;

    @include media("<md") {
      display: none;
    }
  }

  &__btn {
    padding: 3px 0 12px;
    cursor: pointer;
    transition: $transition;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      border-radius: $base-border-radius;
      transition: $transition;
    }

    &.active {
      color: $primary;

      &::before {
        background: $primary;
        transition: $transition;
      }
    }
  }
}
</style>

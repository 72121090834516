
<template>
  <div ref="datapicker" :class="['wio-input-wrapper datepicker', { focus: focus }]">
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>
    <div class="wio-input-group">
      <input
        :id="id"
        v-model="valueInput"
        v-mask="masks.date"
        class="datepicker__input"
        :placeholder="placeholder"
        type="text"
        @focus="focus = true"
        @blur="focus = false"
      />
      <DataPick
        v-model="valuePicker"
        class="datepicker__main"
        :is-date-disabled="validDate"
        :weekdays="weekDays"
        :months="getMonths"
        :mobileBreakpointWidth="768"
      >
        <template #default="{ toggle }">
          <button class="datepicker__btn" @click="clickHandler(toggle)">
            <span class="icon-calendar" />
          </button>
        </template>
      </DataPick>
    </div>

    <span class="error-text">{{ errorText }}</span>
  </div>
</template>

<script>
import masks from '@/helper/inputsMasks'
import df from '@/helper/dateFormater'
import moment from 'moment'

export default {
  name: 'DatePicker',
  props: {
    id: {
      type: String,
      default: () => 'data-picker-id'
    },
    errorText: {
      type: String,
      default: () => 'error messege'
    },
    error: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: () => 'label text'
    },
    required: {
      type: Boolean,
      default: () => true
    },
    placeholder: {
      type: String,
      default: 'Placeholder text'
    },
    max: {
      type: [String, Number, Date, Boolean],
      default: () => false
    },
    min: {
      type: [String, Number, Date, Boolean],
      default: () => false
    },
    value: {
      type: String,
      default: () => {
        return df(new Date())
      }
    },
    col: {
      type: String,
      default: 'form-col-all'
    },
    documentDate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      masks,
      valuePicker: this.value,
      valueInput: this.value ? this.value.split('-').reverse().join('.') : '',
      valueMax: this.max ? new Date(this.max) : false,
      valueMin: this.min ? new Date(this.min) : false,
      focus: false,
      language: 'ua',
    }
  },
  computed: {
    weekDays () {
      let result = []
      switch (this.language) {
        case 'ru':
          result = ['Пнд', 'Втр', 'Срд', 'Чтв', 'Птн', 'Сбт', 'Вск']
          break
        case 'ua':
          result = ['Пнд', 'Втр', 'Срд', 'Чтв', 'Птн', 'Сбт', 'Ндл']
          break
        default:
          result = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          break
      }
      return result
    },
    getMonths () {
      let result = []
      switch (this.language) {
        case 'ru':
          result = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
          break
        case 'ua':
          result = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень']
          break
        default:
          result = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
          break
      }
      return result
    }
  },
  watch: {
    valuePicker: {
      immediate: true,
      handler () {
        if (!this.valuePicker) {
          if (this.valueInput) {
            this.valueInput = null
            this.$emit('input', this.valuePicker)
          }
          return
        }

        if (this.valueMax && this.valuePicker.length === 10 && this.valueMax < new Date(this.valuePicker)) {
          this.valuePicker = df(this.max)
        } else if (this.valueMin && this.valuePicker.length === 10 && this.valueMin > new Date(this.valuePicker)) {
          this.valuePicker = df(this.min)
        }

        const newDateArray = this.valuePicker.split('-')
        const newDate = newDateArray.reverse().join('.')

        if (newDate !== this.valueInput && newDateArray.length === 3) {
          this.valueInput = newDate
          this.$emit('input', this.valuePicker)
        }
      }
    },

    valueInput () {
      if (!this.valueInput) { 
          this.valuePicker = null
          this.$emit('input', null)
        return 
      }

      const newDate = this.valueInput.split('.').reverse().join('-')
      this.$emit('input', newDate)

      if (this.valuePicker !== newDate && this.valueInput.length === 10) {
        this.valuePicker = newDate
        this.$emit('input', this.valuePicker)
      }
      if (this.valuePicker !== newDate && this.valueInput.length === 0) {
        this.valuePicker = null
      }
    },
    

    value (newVal) {
      if (newVal?.length === 10 || !newVal) {
        this.valuePicker = newVal
      }
    },
    max (value) {
      this.valueMax = value ? new Date(this.max) : false
    },
    min (value) {
      this.valueMin = value ? new Date(this.min) : false
    }
  },
  methods: {
    validDate (data) {
      const minStart = moment(this.min)
      const maxStart = moment(this.max)
      const max = maxStart.toDate()
      const curent = moment(data).toDate()
      const min = this.documentDate ? minStart.toDate() : minStart.subtract(1, 'days').toDate()

      if (minStart.isValid() && min > curent) { return true }
      if (maxStart.isValid() && max < curent) { return true }

      return false
    },
    clickHandler (toggle) {
      toggle()
      const offset = 350
      const holder = this.$refs.datapicker
      const elem = this.$refs.datapicker.querySelector('.vdpOuterWrap')

      if (elem) { return }

      const windowTop = window.scrollY
      const windowBot = window.innerHeight
      const elemPosition = holder.getBoundingClientRect().top + holder.offsetHeight
      const result = windowBot - elemPosition

      if (result < offset) {
        window.scroll(0, windowTop + (offset - result))
      }
    }
  }
}
</script>

<style lang="scss">
.datepicker {
  display: inline-block;

  label{
    display: block;
  }

  &__main {
    width: 60px;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    font-family: $font;

    &.read-only {
      pointer-events: none;
    }

    .vdpOuterWrap {
      &.vdpFloating {
        @include media('>=sm') {
          position: absolute;
          top: calc(100% - 6px);
          right: 0;
          left: auto;
          transform-origin: initial;
        }
      }
    }

    .vdpInnerWrap {
      border: $input-border;
      border-radius: $base-border-radius-s;
      padding: 0 25px 22px;

      @include media('>=sm') {
        min-width: 365px;
      }
    }

    .vdpHeader {
      background: $primary;
      padding: 0 0 37px;
      margin: 0 -25px -39px;
    }

    .vdpPeriodControls {
      color: $white;

      .vdpPeriodControl{
        color: $white;
      }

      button {
        font-size: 18px;
        padding: 20px 3px 10px;
        font-weight: bold;
        color: $white;
      }

      .vdpPrevDirection {
        font-weight: bold;
      }
    }

    .vdpArrow {
      text-indent: 0;
      font-size: 0;
      height: 60px;
      width: 80px;
      color: $white;

      &:hover {
        color: $primary;
        
        &::after {
          background: $white;
        }
      }

      &::after {
        font-size: 13px;
        border: none;
        top: calc(50% + 3px);
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
        transition: $transition;
      }

      &.vdpArrowPrev {
        &::after {
          font-family: $icomoon-font-family;
          content: $icon-arrow-new-v2;
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }

      &.vdpArrowNext {
        &::after {
          font-family: $icomoon-font-family;
          content: $icon-arrow-new-v2;
        }
      }
    }

    .vdpCell {
      padding: 6px 0 3px;

      &.outOfRange {
        color: rgba(11, 15, 30, 0.3);
      }

      &.selectable {
        &:hover {
          .vdpCellContent {
            background: $primary !important;
          }
        }
      }

      &.selected {
        .vdpCellContent {
          background: $primary;
        }
      }

      &.today {
        color: $primary;
      }
    }

    .vdpHeadCellContent {
      font-size: 16px;
      color: $white;
    }

    .vdpCellContent {
      font-size: 18px;
    }
  }

  &__btn {
    height: 100%;
    width: 100%;
    border: $input-border;
    border-radius: 0 $base-border-radius-s $base-border-radius-s 0;
    border-left: none;
    background: transparent;
    color: $primary;
    font-size: 22px;
    transition: background $transition, color $transition,
      border $transition;

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  &__input {
    padding: 10px 15px;
    font-size: 16px;
    max-width: 100%;
    width: 100%;
    max-width: calc(100% - 60px);
    border: $input-border;
    border-radius: $base-border-radius-s 0 0 $base-border-radius-s;
    border-right: none;
    color: $base-color;
    outline: none;
    height: 44px;
    max-height: 44px;
    transition: border $transition;

    @include media('>=md') {
      font-size: $input-font-size;
      padding: $input-padding;
    }
  }

  &.error {
    &.style-transparent .datepicker__btn,
    .datepicker__btn {
      border-color: $error !important;
    }
  }
}
</style>


/* eslint-disable */
import moment from "moment"

export default {
  nameAndLastName (value) {
    if (!value) return false

    const valueArray = value.trim().split(' ')
    let filterArray = ''

    filterArray = valueArray.filter(item => !!item)

    return (filterArray.length > 1 && filterArray[1] && filterArray[1].length)
  },
  name3 (value) {
    if (!value) return false

    const valueArray = value.trim().split(' ')
    let filterArray = ''

    filterArray = valueArray.filter(item => !!item)

    return (filterArray.length > 2 && filterArray[1] && filterArray[1].length && filterArray[2] && filterArray[2].length)
  },
  name3En (value) {
    if (!value) return false

    const valueArray = value.trim().split(' ')
    let filterArray = ''

    filterArray = valueArray.filter(item => !!item)

    return (filterArray.length > 2 && filterArray[1] && filterArray[1].length && filterArray[2] && filterArray[2].length)
  },
  nameAndLastNameEn (value) {
    if (!value) return false

    const valueArray = value.trim().split(' ')
    let filterArray = ''

    filterArray = valueArray.filter(item => !!item)

    return (filterArray.length > 1 && filterArray[1].length && !(/[а-я]/i.test(value)))
  },
  inEnglish (value) {
    return (!(/[а-я]/i.test(value)))
  },
  email (velue) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(String(velue))
  },
  phone (value) {
    if (!value) { return false }
    const valueArray = value.split('')
    const item = valueArray[5]
    return typeof item !== 'undefined' && item === '0'
  },
  inn (value) {
    if (value.match(/\D/)) return false;

    const inn = value.match(/(\d)/g);

    const hash = [-1, 5, 7, 9, 4, 6, 10, 5, 7];
    let sum = 0;

    for (var i = 0; i < (inn.length - 1); ++i) {
      sum += inn[i] * hash[i];
    }

    const k = sum - (11 * (Math.floor(sum / 11)));

    if (k != inn[9]) {
      return false;
    }

    return true;
  },
  dataValidation(value){
    const checkData = moment(value)
    return checkData.isValid()
  }
}

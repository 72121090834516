var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wio-step-heading"},[_c('div',{staticClass:"wio-step-heading__container"},[_c('div',{staticClass:"wio-step-heading__list"},_vm._l((_vm.items),function(item,index){return _c('div',{key:`item${index}`,class:[
          'wio-step-heading__item',
          { 'wio-step-heading__item--done': index < _vm.activeIndex },
          { 'wio-step-heading__item--active': index === _vm.activeIndex },
          { 'wio-step-heading__item--hide-mobile': index < _vm.activeIndex - 1 },
          { 'wio-step-heading__item--hide-mobile': index > _vm.activeIndex + 1 },
        ]},[_c('div',{staticClass:"wio-step-heading__icon"},[_c('span',{class:`icon-${_vm.icons[index]}`})]),_c('div',{staticClass:"wio-step-heading__text"},[_c('div',{staticClass:"wio-step-heading__text--value"},[_vm._v(" "+_vm._s(_vm.getItem(item))+" ")])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    v-if="type.toLowerCase() == 'checkbox'"
    :class="[
      'wio-input-wrapper',
      'wio-checkbox-wrapper',
      { 'wio-checkbox-checked': value },
      { error: error }
    ]"
  >
    <label v-tippy="{ onShow: () => !!tooltip }" :content="tooltip" :for="id">
      <span v-if="label" class="label-text">
        <slot name="label"></slot>
        {{ label }} <span v-if="showRequired" class="required">*</span>
      </span>
      <span v-else class="label-text">
        <slot name="label"></slot>
      </span>
      <input
        :id="id"
        ref="input"
        :checked="value"
        :name="name ? name : placeholder.split(' ').join('')"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        @change="changeHandler"
        class="wio-input wio-chackbox"
      />
      <span class="checkmark" />
    </label>
  </div>
  <div
    v-else-if="type.toLowerCase() == 'radio'"
    :class="[
      'wio-input-wrapper',
      'wio-radio-wrapper',
      { 'wio-radio-checked': checked },
      { error: error }
    ]"
  >
    <label v-tippy="{ onShow: () => !!tooltip }" :content="tooltip" :for="id">
      <input
        :id="id"
        ref="input"
        :checked="checked"
        :name="name"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        @change="changeHandler"
        class="wio-input wio-radio"
      />
      <span class="checkmark" />
      <span v-if="label" class="label-text">
        <slot name="label"></slot>
        {{ label }} <span v-if="showRequired" class="required">*</span>
      </span>
      <span v-else class="label-text">
        <slot name="label"></slot>
      </span>
    </label>
  </div>
  <div
    v-else-if="type.toLowerCase() == 'textarea'"
    :class="['wio-input-wrapper', { error: error }]"
  >
    <label
      v-if="label"
      v-tippy="{ onShow: () => !!tooltip }"
      :content="tooltip"
      :for="id"
    >
      {{ label }}
      <span v-if="showRequired" class="required">*</span>
    </label>
    <textarea
      :id="id"
      ref="input"
      :name="name ? name : placeholder.split(' ').join('')"
      :value="value"
      :placeholder="placeholder"
      @input="changeHandler"
      @focus="focus = true"
      @blur="focus = false"
      class="wio-input wio-textarea"
    />
    <span class="error-text">{{ errorText }}</span>
  </div>
  <div
    v-else
    :class="[
      'wio-input-wrapper',
      { error: error },
      { focus: focus },
      { 'input-wrapper-tooltip': tooltip }
    ]"
  >
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="showRequired" class="required">*</span>
      <div
        v-if="tooltip"
        v-tippy="{ onShow: () => !!tooltip }"
        :content="tooltip"
        class="input-tooltip"
      ></div>
    </label>
    <div class="input-holder">
      <input
        :id="id"
        ref="input"
        v-mask="checkMask"
        :name="name ? name : placeholder"
        :type="type"
        :inputmode="inputmode"
        :pattern="pattern"
        :value="value"
        :placeholder="placeholder"
        @input="changeHandler"
        @keypress.enter="$emit('enterPush')"
        @blur="blurHandler"
        @focus="focus = true"
        class="wio-input"
      />
      <slot name="inputbtn"></slot>
      <UILoader v-if="loader" class="wio-input-loader" />

      <div
        v-if="focus && options && options.length"
        class="input-option-wrapper"
      >
        <div class="ovh">
          <div
            v-for="(item, i) in options"
            :key="`${item}${i}`"
            class="input-option"
            @click="listClickHandler(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- :name="name ? name : placeholder.split(' ').join('')" -->
    <span class="error-text">{{ errorText }}</span>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import inputMasks from '@/helper/inputsMasks'
export default {
  name: 'CustomInput',
  props: {
    type: {
      type: String,
      default: () => 'text'
    },
    placeholder: {
      type: String,
      default: () => 'Plaseholder'
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: () => ''
    },
    error: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: [String, Number],
      default: () => ''
    },
    required: {
      type: Boolean,
      default: () => false
    },
    errorText: {
      type: String,
      default: () => 'error text'
    },
    showRequired: {
      type: Boolean,
      default: () => false
    },
    mask: {
      type: [String, Boolean],
      default: ''
    },
    loader: {
      type: Boolean,
      default: false
    },
    inputmode: {
      type: String,
      default: ''
    },
    pattern: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    inputValue: {
      type: [String, Object, Array, Number],
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      focus: false,
      hover: false
    }
  },
  computed: {
    checkMask () {
      return inputMasks[this.mask] ? inputMasks[this.mask] : ''
    },
    ifCheckbox () {
      return this.type.toLowerCase() === 'checkbox'
    },
    checked () {
      if (this.type.toLowerCase() === 'radio' && this.inputValue) {
        return isEqual(this.value, this.inputValue)
      }
      return null
    }
  },
  mounted () {
    if (this.type.toLowerCase() === 'radio' && this.inputValue && this.value && this.inputValue === this.value) {
      this.$refs.input.checked = true
    }
  },
  methods: {
    changeHandler ({ target }) {
      const value = this.ifCheckbox ? target.checked : target.value

      if (this.type.toLowerCase() === 'radio') {
        this.$emit('input', this.inputValue)
      } else {
        this.$emit('input', value)
      }
    },
    blurHandler ({ target }) {
      const value = target.value

      setTimeout(() => {
        this.focus = false
      }, 150)

      if (value) {
        this.$emit('input', value.trim())
        this.$emit('blur', value.trim())
      }


    },
    listClickHandler (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.input-wrapper-tooltip {
  .input-tooltip {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $secondary;
    transition: $transition;
    position: absolute;
    top: 0;
    right: 15px;

    &:hover {
      border-color: $primary;

      &::before {
        color: $primary;
      }
    }

    &::before {
      color: $secondary;
      content: $icon-question;
      font-family: $icomoon-font-family;
      font-size: 10px;
    }
  }

  .label {
    position: relative;
    padding-right: 20px;
  }
}

.wio-input-wrapper{
  .wio-input{
    &:not(.wio-chackbox),
    &:not(.wio-radio),
    &:not(.wio-text-aria){
      min-height: 44px;
      border: 1px solid $input-border-color;
      border-radius: $base-border-radius-s;
      width: 100%;
      padding: 0 15px;
      outline: none !important;
      box-shadow: none !important;
      transition: $transition;
    }
  }

  .input-option-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 111111;
    overflow: hidden;
    border: $input-border;
    border-radius: $input-border-radius;
    background: $white;

    .ovh {
      max-height: 220px;
      overflow-y: auto;
    }

    .input-option {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
}
</style>

<template>
  <div class="wio-step wio-step-5">
    <div class="btn-back-wrapper" :class="{ 'loading-disabled': loading }">
      <UIButton
        :text="stepData.btnPrevText"
        class="btn-go-back"
        @click="$emit('prevStep')"
      />
    </div>
    <UIStepHeading
      :items="stepList"
      :active-index="4"
      :way-flag="dataUser.wayIndex"
      :class="{ 'loading-disabled': loading }"
    />
    <div class="wio-step-sidebar">
      <div class="wio-step-left">
        <UIAttention
          class="osago-attention mb-20"
          :class="{ 'loading-disabled': loading }"
          style="margin-top: 0; max-width: 590px"
        >
          {{ stepData.attentionText }}
        </UIAttention>
        <div ref="formWrapper" class="wio-form-wrapper">
          <!-- FIO -->
          <div class="wio-form-row">
            <UIInput
              v-if="ifLegal"
              v-model="$v.formInfo.companyName.$model"
              :label="stepData.fields.companyName.label"
              :placeholder="stepData.fields.companyName.placeholder"
              :error="!!errors.companyName || $v.formInfo.companyName.$error"
              :error-text="
                errors.companyName
                  ? errors.companyName.messages[0]
                  : stepData.fields.companyName.error
              "
              :show-required="true"
              class="form-col"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.companyName, 'companyName')"
            />
            <UIInput
              v-else
              v-model="$v.formInfo.userName.$model"
              :label="stepData.fields.userName.label"
              :placeholder="stepData.fields.userName.placeholder"
              :error="!!errors.userName || $v.formInfo.userName.$error"
              :error-text="
                errors.userName
                  ? errors.userName.messages[0]
                  : stepData.fields.userName.error
              "
              :show-required="true"
              class="form-col"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.userName, 'userName')"
            />
          </div>
          <!-- ADDRESS -->
          <div class="wio-form-row">
            <UIInput
              v-model="$v.formInfo.address.$model"
              :label="stepData.fields.address.label"
              :placeholder="stepData.fields.address.placeholder"
              :error="!!errors.address || $v.formInfo.address.$error"
              :error-text="
                errors.address
                  ? errors.address.messages[0]
                  : stepData.fields.address.error
              "
              :show-required="true"
              class="form-col-12"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.address, 'address')"
            />
          </div>
          <!-- IPN / BORN -->
          <div v-if="!ifLegal" class="wio-form-row">
            <UIInput
              v-model="$v.formInfo.ipn.$model"
              :label="stepData.fields.ipn.label"
              :placeholder="stepData.fields.ipn.placeholder"
              :error="!!errors.ipn || $v.formInfo.ipn.$error"
              :error-text="
                errors.ipn ? errors.ipn.messages[0] : stepData.fields.ipn.error
              "
              :inputmode="'numeric'"
              :pattern="'[0-9]*'"
              :mask="'ipn'"
              :show-required="true"
              class="form-col-6"
              :class="{ disabled: loading }"
              :tooltip="stepData.fields.ipn.tooltip || 'test tooltip'"
              @input="delayTouch($v.formInfo.ipn, 'ipn')"
            />
            <UIDatePicker
              :id="stepData.fields.born.id"
              v-model="$v.formInfo.born.$model"
              class="form-col-6"
              :class="bornClass"
              :error-text="stepData.fields.born.error"
              :placeholder="stepData.fields.born.placeholder"
              :max="setMinBornDate()"
              :label="stepData.fields.born.label"
              @input="delayTouch($v.formInfo.born, 'born')"
            />
          </div>
          <!-- DOCUMENT / DOCUMENT1 / DOCUMENT2 -->
          <div v-if="!ifLegal" class="wio-form-row">
            <div
              :class="[
                'wio-input-wrapper form-col-6',
                'crear',
                { filled: formInfo.document },
                { error: $v.formInfo.document.$error },
                { disabled: loading },
              ]"
            >
              <label v-if="stepData.fields.document.label">
                {{ stepData.fields.document.label }}
                <span class="required">*</span>
              </label>
              <v-select
                v-model="$v.formInfo.document.$model"
                :searchable="false"
                :placeholder="stepData.fields.document.placeholder"
                :options="stepData.fields.document.values"
                label="name"
                @input="changeDocument"
              />

              <span class="error-text">{{
                stepData.fields.document.error
              }}</span>
            </div>
            <UIInput
              v-if="!ifDocumentFields1"
              :id="'test'"
              v-model="$v.formInfo.documentFields1.$model"
              :label="stepData.fields.documentFields1.label[documentTypeKey]"
              :placeholder="
                stepData.fields.documentFields1.placeholder[documentTypeKey]
              "
              :error="
                !!errors.documentFields1 || $v.formInfo.documentFields1.$error
              "
              :error-text="
                errors.documentFields1
                  ? errors.documentFields1.messages[0]
                  : stepData.fields.documentFields1.error[documentTypeKey]
              "
              :show-required="true"
              class="form-col-3"
              :class="{ disabled: loading }"
              :mask="documentFields1Mask"
              @input="
                delayTouch($v.formInfo.documentFields1, 'documentFields1')
              "
            />
            <UIInput
              v-model="$v.formInfo.documentFields2.$model"
              :label="stepData.fields.documentFields2.label[documentTypeKey]"
              :placeholder="
                stepData.fields.documentFields2.placeholder[documentTypeKey]
              "
              :error="
                !!errors.documentFields2 || $v.formInfo.documentFields2.$error
              "
              :inputmode="'numeric'"
              :pattern="'[0-9]*'"
              :error-text="
                errors.documentFields2
                  ? errors.documentFields2.messages[0]
                  : stepData.fields.documentFields2.error[documentTypeKey]
              "
              :show-required="true"
              :class="[
                `form-col-${ifDocumentFields1 ? '6' : '3'}`,
                { disabled: loading },
              ]"
              :mask="documentFields2Mask"
              @input="
                delayTouch($v.formInfo.documentFields2, 'documentFields2')
              "
            />
          </div>
          <!-- ERDPOU -->
          <div v-if="ifLegal" class="wio-form-row">
            <UIInput
              v-model="$v.formInfo.edrpou.$model"
              :label="stepData.fields.edrpou.label"
              :placeholder="stepData.fields.edrpou.placeholder"
              :error="!!errors.edrpou || $v.formInfo.edrpou.$error"
              :error-text="
                errors.edrpou
                  ? errors.edrpou.messages[0]
                  : stepData.fields.edrpou.error
              "
              :mask="'edrpou'"
              :show-required="true"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.edrpou, 'edrpou')"
            />
          </div>
          <!-- DOCUMENT PLACE / DOCUMENT DATE -->
          <div v-if="!ifLegal" class="wio-form-row">
            <UIInput
              v-model="$v.formInfo.documentPlace.$model"
              :label="stepData.fields.documentPlace.label[documentTypeKey]"
              :placeholder="
                stepData.fields.documentPlace.placeholder[documentTypeKey]
              "
              :error="
                !!errors.documentPlace || $v.formInfo.documentPlace.$error
              "
              :error-text="
                errors.documentPlace
                  ? errors.documentPlace.messages[0]
                  : stepData.fields.documentPlace.error[documentTypeKey]
              "
              :show-required="!ifEPension"
              :mask="documentPlaceMask"
              :class="['form-col-9', { disabled: ifEPension || loading }]"
              @input="delayTouch($v.formInfo.documentPlace, 'documentPlace')"
            />
            <UIDatePicker
              :id="stepData.fields.documentDate.id"
              v-model="$v.formInfo.documentDate.$model"
              :class="[
                'form-col-3',
                {
                  error:
                    !!errors.documentDate || $v.formInfo.documentDate.$error,
                },
                { disabled: loading },
              ]"
              :error-text="
                errors.documentDate
                  ? errors.documentDate.messages[0]
                  : stepData.fields.documentDate.error[documentTypeKey]
              "
              :placeholder="stepData.fields.documentDate.placeholder"
              :label="stepData.fields.documentDate.label[documentTypeKey]"
              :max="
                documentTypeKey === 'E_PENSION_CERTIFICATE'
                  ? false
                  : df(new Date())
              "
              :min="
                documentTypeKey === 'E_PENSION_CERTIFICATE'
                  ? df(getTomorrow())
                  : false
              "
              :document-date="true"
            />
          </div>
          <!-- EMAIL / PHONE -->
          <div class="wio-form-row">
            <UIInputTell
              v-model="$v.formInfo.phone.$model"
              :label="stepData.fields.phone.label"
              :placeholder="stepData.fields.phone.placeholder"
              :error="!!errors.phone || $v.formInfo.phone.$error"
              :error-text="
                errors.phone
                  ? errors.phone.messages[0]
                  : stepData.fields.phone.error
              "
              :show-required="true"
              :mask="'tell'"
              :type="'tel'"
              class="form-col-6"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.phone, 'phone')"
            />
            <UIInput
              v-model="$v.formInfo.email.$model"
              v-tippy="{ onShow: () => !!stepData.fields.email.label }"
              :content="stepData.fields.email.label"
              :label="stepData.fields.email.label"
              :placeholder="stepData.fields.email.placeholder"
              :error="!!errors.email || $v.formInfo.email.$error"
              :error-text="
                errors.email
                  ? errors.email.messages[0]
                  : stepData.fields.email.error
              "
              :show-required="true"
              class="form-col-6"
              :class="{ disabled: loading }"
              @input="delayTouch($v.formInfo.email, 'email')"
            />
          </div>
          <div class="btn-step-footer">
            <UIButton
              :text="stepData.btnNextText"
              @click="submitHandler"
              :loader="loading"
              class="mb-20"
            />
          </div>
        </div>
      </div>
      <div class="wio-step-right" :class="{ 'loading-disabled': loading }">
        <UISideBar v-if="sideBarData" :data-object="sideBarData" />
        <UIPayCard
          v-if="stepData && stepData.payment"
          :text="stepData.payment.text"
          :value="stepData.payment.price"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import masks from "@/helper/inputsMasks";
import df from "@/helper/dateFormater";
import moment from "moment";
import validationHelpers from "@/helper/validation";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import {
  cloneDeep,
} from "lodash";

const touchMap = new WeakMap();

export default {
  name: "wioStep5",
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      masks,
      df,
      contractId: false,
      formInfo: "",
      resendDisable: false,
      loader: false,
      changeDocumentTimeOut: false,
      ifChangeDocument: false,
      day: 1000 * 60 * 60 * 24,
    };
  },
  created() {
    this.GTMPush()
    this.formInfo = cloneDeep(this.dataUser);
    this.setMinBornDate();

    if (!this.formInfo.born) {
      this.formInfo.born = null;
    }

    if (!this.formInfo.documentDate) {
      this.formInfo.documentDate = null;
    }
  },
  mounted() {
    const preVlidate = ["userName", "address"];

    preVlidate.forEach((key) => {
      if (
        this.$v.formInfo[key] &&
        this.$v.formInfo[key].$model &&
        this.$v.formInfo[key].$model.length
      ) {
        this.$v.formInfo[key].$touch();
      }
    });
  },
  computed: {
    dataUser() {
      return this.userStore.getUser;
    },
    stepData() {
      return this.stepStore.getStep5;
    },
    ifBackErrors() {
      let result = false;

      if (!Object.keys(this.errors).length) {
        return result;
      }

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key]) {
          result = true;
        }
      });

      return result;
    },
    errors() {
      return this.userStore?.getBackErrors;
    },
    ifLegal() {
      return this.dataUser.type.id === "LEGAL";
    },
    documentFields1MinLenght() {
      if (!this.formInfo.document) {
        return 2;
      }

      const documentValue = this.formInfo.document.id;

      if (documentValue === "DISABILITY_CERTIFICATE") {
        return 2;
      }
      if (documentValue === "VETERAN_CERTIFICATE") {
        return 2;
      }
      if (documentValue === "CHERNOBYL_CERTIFICATE") {
        return 2;
      }
      if (documentValue === "PENSION_CERTIFICATE") {
        return 2;
      }
      if (documentValue === "E_PENSION_CERTIFICATE") {
        return 0;
      }
      if (documentValue === "ID_PASSPORT") {
        return 14;
      }
      if (documentValue === "DRIVING_LICENSE") {
        return 3;
      }

      return 2;
    },
    documentFields1MaxLenght() {
      if (!this.formInfo.document) {
        return 2;
      }

      const documentValue = this.formInfo.document.id;

      if (documentValue === "DISABILITY_CERTIFICATE") {
        return 4;
      }
      if (documentValue === "VETERAN_CERTIFICATE") {
        return 4;
      }
      if (documentValue === "CHERNOBYL_CERTIFICATE") {
        return 4;
      }
      if (documentValue === "PENSION_CERTIFICATE") {
        return 4;
      }
      if (documentValue === "E_PENSION_CERTIFICATE") {
        return 0;
      }
      if (documentValue === "ID_PASSPORT") {
        return 14;
      }
      if (documentValue === "DRIVING_LICENSE") {
        return 3;
      }

      return 2;
    },
    documentFields2MinLenght() {
      if (!this.formInfo.document) {
        return 6;
      }

      const documentValue = this.formInfo.document.id;

      if (documentValue === "DISABILITY_CERTIFICATE") {
        return 6;
      }
      if (documentValue === "VETERAN_CERTIFICATE") {
        return 6;
      }
      if (documentValue === "CHERNOBYL_CERTIFICATE") {
        return 6;
      }
      if (documentValue === "PENSION_CERTIFICATE") {
        return 6;
      }
      if (documentValue === "E_PENSION_CERTIFICATE") {
        return 6;
      }
      if (this.formInfo.document.id === "ID_PASSPORT") {
        return 9;
      }
      return 6;
    },
    documentFields2MaxLenght() {
      if (!this.formInfo.document) {
        return 6;
      }

      const documentValue = this.formInfo.document.id;

      if (documentValue === "DISABILITY_CERTIFICATE") {
        return 20;
      }
      if (documentValue === "VETERAN_CERTIFICATE") {
        return 20;
      }
      if (documentValue === "CHERNOBYL_CERTIFICATE") {
        return 20;
      }
      if (documentValue === "PENSION_CERTIFICATE") {
        return 20;
      }
      if (documentValue === "E_PENSION_CERTIFICATE") {
        return 20;
      }
      if (this.formInfo.document.id === "ID_PASSPORT") {
        return 9;
      }
      return 6;
    },
    documentPlaceLenght() {
      if (this.formInfo.document.id === "ID_PASSPORT") {
        return 4;
      }
      return 1;
    },
    userNameValidation() {
      if (this.formInfo.document.id === "EXTERNAL_PASSPORT") {
        return validationHelpers.name3En;
      }
      return validationHelpers.name3;
    },
    bornClass() {
      return [
        {
          error:
            (!!this.errors.ipn && !this.errors.ipn.value) ||
            this.$v.formInfo.born.$error,
        },
        { "no-message": !!this.errors.ipn },
        { disabled: this.loading },
      ];
    },
    documentTypeKey() {
      return this.$v.formInfo.document.$model
        ? this.$v.formInfo.document.$model.id
        : "PASSPORT";
    },
    documentFields1Mask() {
      if (!this.formInfo.document) {
        return "text2";
      }

      const documentValue = this.formInfo.document.id;

      if (documentValue === "DISABILITY_CERTIFICATE") {
        return "text4";
      }
      if (documentValue === "VETERAN_CERTIFICATE") {
        return "text4";
      }
      if (documentValue === "CHERNOBYL_CERTIFICATE") {
        return "text4";
      }
      if (documentValue === "PENSION_CERTIFICATE") {
        return "text4";
      }
      if (documentValue === "E_PENSION_CERTIFICATE") {
        return "text4";
      }
      if (documentValue === "ID_PASSPORT") {
        return "idPassport";
      }
      if (documentValue === "DRIVING_LICENSE") {
        return "text3";
      }

      return "text2";
    },
    documentFields2Mask() {
      if (this.formInfo.document.id === "ID_PASSPORT") {
        return "idPassport2";
      }

      return "digit6";
    },
    documentPlaceMask() {
      if (!this.formInfo.document) {
        return false;
      }

      if (this.formInfo.document.id === "ID_PASSPORT") {
        return "documentPlace";
      }

      return false;
    },
    sideBarData() {
      return this.stepData?.sideBar;
    },
    ifDocumentFields1() {
      if (!this.formInfo.document) {
        return false;
      }

      return (
        this.formInfo.document.id === "E_PENSION_CERTIFICATE" ||
        this.formInfo.document.id === "ID_PASSPORT"
      );
    },
    ifEPension() {
      if (!this.formInfo.document) {
        return false;
      }

      return this.formInfo.document.id === "E_PENSION_CERTIFICATE";
    },
    stepList() {
      return this.stepData.stepList;
    },
  },
  watch: {
    "formInfo.document.id": {
      handler(value, prevValue) {
        if (value === "E_PENSION_CERTIFICATE") {
          this.formInfo.documentPlace = "";
          this.formInfo.documentDate = null;
          // this.formInfo.documentDate = df(new Date())
        } else if (prevValue === "E_PENSION_CERTIFICATE") {
          this.formInfo.documentDate = null;
          // this.formInfo.documentDate = df(new Date())
        }
      },
    },
    "formInfo.ipn": {
      handler(value) {
        if (!value || value.length !== 10) {
          return;
        }

        try {
          const addDay = value.slice(0, 5);
          const startDay = moment("1900-01-01");

          const currentDay = this.df(
            new Date(new Date(startDay.add(addDay, "days").calendar()))
          );

          if (currentDay) {
            this.formInfo.born = currentDay;
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  },
  methods: {
    ...mapActions(userData, ["setBackError"]),
    delayTouch($v, key) {
      if (key === "carNumber") {
        this.formInfo.carNumber = this.formInfo.carNumber.toUpperCase();
      }
      if (this.errors[key]) {
        this.setBackError({
          payload: false,
          key,
        });
      }

      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }

      if (this.ifChangeDocument && !$v.$model.length) {
        return;
      }

      touchMap.set($v, setTimeout($v.$touch, 500));
    },
    getTomorrow () {
      return new Date(moment().add(0, 'days'))
    },
    changeHandler(key) {
      if (this.errors[key]) {
        this.setBackError({
          payload: false,
          key,
        });
      }
    },
    submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        const element = this.$refs.formWrapper;

        if (!element) {
          return;
        }

        setTimeout(() => {
          const errorElement = element.querySelectorAll(
            ".input-wrapper.error"
          )[0];

          if (!errorElement) {
            return;
          }

          const { top } = errorElement.getBoundingClientRect();

          if (top > 30) {
            return;
          }

          window.scroll(0, window.scrollY + top - 30);
        }, 10);
        console.log("error");
      } else {
        Object.keys(this.formInfo).forEach((key) => {
          this.userStore.setUserOne({ key, payload: this.formInfo[key] });
        });

        this.$emit("changeStep");
      }
    },
    setMinBornDate() {
      const now = new Date();
      const min = df(
        new Date(`${now.getFullYear() - 16}-${now.getMonth()}-${now.getDate()}`)
      );

      return min;
    },
    changeDocument() {
      this.ifChangeDocument = true;

      clearTimeout(this.changeDocumentTimeOut);
      this.changeDocumentTimeOut = setTimeout(() => {
        this.ifChangeDocument = false;
      });
    },
    GTMPush(){
      if(window && window.dataLayer){
        const self = this

        console.log('GTM begin_checkout')

        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            items: [{
              item_name: 'widget_osago',
              item_id: self.dataUser?.offer?.id,
              price: self.dataUser?.offer?.franchiseActive?.price?.price,
              item_brand: self.dataUser?.offer?.company?.title,
              item_category: self.dataUser?.type?.name,
              item_category2: self.dataUser?.vehicle?.name,
              item_category3: self.dataUser?.offer?.about?.list,
              item_variant: self.dataUser?.offer?.offerFranchise,
              quantity: 1
            }]
          }
        })
      }
    }
  },
  validations() {
    if (!this.ifLegal) {
      return {
        formInfo: {
          userName: {
            required,
            checkName: this.userNameValidation,
          },
          address: { required },
          ipn: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
            // ,
            // checkINN: validationHelpers.inn
          },
          born: {
            required,
            minLength: minLength(10),
          },
          document: { required },
          documentFields1: {
            required: this.ifDocumentFields1 ? false : required,
            minLength: this.ifDocumentFields1
              ? false
              : minLength(this.documentFields1MinLenght),
            maxLength: this.ifDocumentFields1
              ? false
              : maxLength(this.documentFields1MaxLenght),
          },
          documentFields2: {
            required,
            minLength: minLength(this.documentFields2MinLenght),
            maxLength: maxLength(this.documentFields2MaxLenght),
          },
          documentPlace: {
            required: this.ifEPension ? false : required,
            minLength: minLength(this.documentPlaceLenght),
          },
          documentDate: {
            required,
            minLength: minLength(10),
            checkDocumentDate: (value) => {
              if (this.formInfo.born?.length === 10 && value?.length === 10) {
                return new Date(this.formInfo.born) < new Date(value);
              }
              return true;
            },
          },
          email: {
            required,
            checkEmail: validationHelpers.email,
          },
          phone: {
            required,
            minLength: minLength(13),
            // checkPhone: validationHelpers.phone
          },
        },
      };
    }
    return {
      formInfo: {
        companyName: {
          required,
          maxLength: maxLength(100),
        },
        address: { required },
        edrpou: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(8),
          numeric,
        },
        email: {
          required,
          checkEmail: validationHelpers.email,
        },
        phone: {
          required,
          minLength: minLength(13),
          // checkPhone: validationHelpers.phone
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wio-step-5 {
  .wio-step-left {
    @include media(">=lg") {
      padding-right: 80px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        width: 1px;
        background: $primary;
        opacity: 0.1;
      }
    }
  }

  .btn-step-footer {
    @include media(">=lg") {
      align-items: end !important;
    }
  }

  .sub-title {
    margin: 35px 10px 20px;
    font-size: 18px;
  }
}
</style>
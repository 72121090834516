var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wio-step wio-step-2"},[_c('div',{staticClass:"btn-back-wrapper",class:{ 'loading-disabled': _vm.loading }},[_c('UIButton',{staticClass:"btn-go-back",attrs:{"text":_vm.stepData.btnPrevText},on:{"click":function($event){return _vm.$emit('prevStep')}}})],1),_c('UIStepHeading',{class:{ 'loading-disabled': _vm.loading },attrs:{"items":_vm.stepList,"active-index":1,"way-flag":_vm.dataUser.wayIndex}}),_c('div',{staticClass:"wio-step__content"},[_c('div',{ref:"formWrapper",staticClass:"wio-form-wrapper"},[_c('div',{staticClass:"wio-form-row"},[_c('div',{class:[
            'wio-input-wrapper',
            'form-col-6',
            'crear',
            { 'read-only read-only-opacity': _vm.dataUser.euroNumber },
            { filled: _vm.formInfo.city },
            { error: _vm.$v.formInfo.city.$error },
            { disabled: _vm.loading },
          ]},[(_vm.stepData.fields.city.label)?_c('label',{attrs:{"for":_vm.stepData.fields.city.id}},[_vm._v(" "+_vm._s(_vm.stepData.fields.city.label)+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]):_vm._e(),_c('v-select',{attrs:{"id":_vm.stepData.fields.city.id,"push-tags":"","placeholder":_vm.stepData.fields.city.placeholder,"options":_vm.placeOptions,"label":"name","filterable":false},on:{"search":_vm.onSearch},model:{value:(_vm.$v.formInfo.city.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.city, "$model", $$v)},expression:"$v.formInfo.city.$model"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.stepData.fields.city.error))])],1),_c('div',{class:[
            'wio-input-wrapper',
            'select-list',
            'form-col-6',
            { filled: _vm.formInfo.vehicle },
            { error: _vm.$v.formInfo.vehicle.$error },
            { disabled: _vm.loading },
          ]},[(_vm.stepData.fields.vehicle.label)?_c('label',{attrs:{"for":_vm.stepData.fields.vehicle.id}},[_vm._v(" "+_vm._s(_vm.stepData.fields.vehicle.label)+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]):_vm._e(),_c('v-select',{attrs:{"id":_vm.stepData.fields.vehicle.id,"searchable":false,"placeholder":_vm.stepData.fields.vehicle.placeholder,"options":_vm.getIngineList(),"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(option.listTitle)?_c('div',{staticClass:"options-group-title"},[_vm._v(" "+_vm._s(option.listTitle)+" ")]):_vm._e(),_c('div',{staticClass:"option-wrapper"},[_c('div',{class:[
                    'option-icon',
                    `icon-category_${_vm.setVehicleIcon(option.icon)}`,
                  ]}),_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(option.name)+" ")])])]}},{key:"selected-option",fn:function({ icon, name }){return [_c('div',{staticClass:"option-wrapper selected"},[_c('div',{class:[
                    'option-icon',
                    `icon-category_${_vm.setVehicleIcon(icon)}`,
                  ]}),_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(name)+" ")])])]}}]),model:{value:(_vm.$v.formInfo.vehicle.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.vehicle, "$model", $$v)},expression:"$v.formInfo.vehicle.$model"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.stepData.fields.vehicle.error))])],1)]),_c('div',{staticClass:"wio-form-row"}),_c('div',{staticClass:"wio-btn-wrapper btn-center btn-column"},[_c('UIButton',{staticClass:"btn-green",attrs:{"text":_vm.stepData.btnText,"loader":_vm.loading},on:{"click":_vm.submitHandler}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
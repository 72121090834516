<template>
  <div class="side-bar">
    <div v-if="dataObject.title" class="side-bar__title">
      {{ dataObject.title }}
    </div>
    <template v-for="key in menuListKeys">
      <div v-if="dataObject[key]" :key="key" class="side-bar__menu">
        <div v-if="dataObject[key].title" class="side-bar__menu-title">
          <span>{{ dataObject[key].title }}</span>
          <div :class="['icon', icons[key]]"></div>
        </div>
        <div v-if="dataObject[key].list" class="side-bar__menu-list">
          <div
            v-for="item in dataObject[key].list"
            :key="item.id"
            class="side-bar__item"
          >
            <div v-if="item.name" class="side-bar__item--name">
              {{ item.name }}
            </div>
            <div class="side-bar__item--value">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    dataObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      menuListKeys: ["userData", "carData", "offerData"],
      icons: {
        userData: "icon-user-v2",
        carData: "icon-car",
        offerData: "icon-umbrella",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  border-radius: $base-border-radius-m;
  background: $grey-100;
  border: 1px solid $grey-400;
  padding: 25px 20px 20px;
  margin-bottom: 20px;
  color: $black;

  &__title {
    font-size: 24px;
    line-height: 1.5em;
    font-weight: 500;
    margin-bottom: 40px;
  }

  &__menu + &__menu {
    margin-top: 20px;
  }

  &__menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid $grey-400;
    padding-bottom: 20px;

    span {
      max-width: calc(100% - 35px);
    }

    .icon {
      font-size: 25px;
    }
  }

  &__menu-list {
    padding: 15px 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $base-font-size-s;
    line-height: 1.5em;
    padding: 5px 0;

    &--name {
      color: $grey-800;
    }

    &--value {
      text-align: right;
    }
  }
}
</style>

<template>
  <div class="error-block">
    <span class="error-block__icon icon-explanation" />
    <div class="error-bloc__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
};
</script>

<style lang="scss" scoped>
.error-block {
  background-color: rgba($error, 0.1);
  border: 1px solid rgba($error, 0.2);
  border-radius: $input-border-radius;
  padding: 10px;
  font-size: 10px;
  display: flex;
  margin: 30px 0;
  text-align: left;
  align-items: center;
  justify-content: center;

  @include media(">=md") {
    font-size: $base-font-size-s;
    padding: 20px;
  }

  &__icon {
    width: 40px;
    display: flex;
    align-items: center;
    font-size: 27px;
    color: $red;

    @include media(">=md") {
      width: 50px;
      font-size: 33px;
    }
  }

  &__content {
    max-width: calc(100% - 40px);
    line-height: 1.5em;
    display: flex;
    align-items: center;

    @include media(">=md") {
      max-width: calc(100% - 50px);
    }

    p {
      margin: 0;
    }
  }
}
</style>

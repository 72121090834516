var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wio-step wio-step-1"},[_c('div',{staticClass:"wio-step__content"},[_c('div',{staticClass:"wio-step-head",class:{ 'loading-disabled': _vm.loading }},[(_vm.stepData.title)?_c('h4',{staticClass:"wio-h4",domProps:{"innerHTML":_vm._s(_vm.stepData.title)}}):_vm._e(),(_vm.stepData.infoLine)?_c('InfoLine',{attrs:{"lineData":_vm.stepData.infoLine}}):_vm._e()],1),_c('UIStepHeading',{class:{ 'loading-disabled': _vm.loading },attrs:{"items":_vm.stepList,"active-index":0,"way-flag":_vm.dataUser.wayIndex}}),(_vm.tabsText)?_c('TabsMenu',{class:{ 'loading-disabled': _vm.loading },attrs:{"way-index":_vm.dataUser.wayIndex,"data-object":_vm.tabsText}}):_vm._e(),_c('div',{ref:"formWrapper",staticClass:"wio-form-wrapper"},[(_vm.dataUser.wayIndex === 1)?_c('div',{staticClass:"wio-form-row"},[_c('UIInput',{staticClass:"form-col-4",class:{ disabled: _vm.loading },attrs:{"id":_vm.stepData.fields.carNumber.id,"label":_vm.stepData.fields.carNumber.label || 'test',"placeholder":_vm.stepData.fields.carNumber.placeholder,"required":true,"show-required":true,"data-key":"carNumber","error":!!_vm.errors.carNumber || _vm.$v.formInfo.carNumber.$error,"errorText":_vm.errors.carNumber
              ? _vm.errors.carNumber.messages[0]
              : _vm.stepData.fields.carNumber.error},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.carNumber, 'carNumber')}},model:{value:(_vm.$v.formInfo.carNumber.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.carNumber, "$model", $$v)},expression:"$v.formInfo.carNumber.$model"}}),_c('div',{class:[
            'wio-input-wrapper form-col-4',
            { filled: _vm.formInfo.type },
            { error: _vm.$v.formInfo.type.$error },
            { disabled: _vm.loading },
          ],attrs:{"data-key":"type"}},[(_vm.stepData.fields.type.label)?_c('label',[_vm._v(" "+_vm._s(_vm.stepData.fields.type.label)+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]):_vm._e(),_c('v-select',{attrs:{"searchable":false,"placeholder":_vm.stepData.fields.type.placeholder,"options":_vm.stepData.fields.type.values,"label":"name"},model:{value:(_vm.$v.formInfo.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.type, "$model", $$v)},expression:"$v.formInfo.type.$model"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s("notFaund")+" ")])]),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.stepData.fields.type.error))])],1),_c('UIInput',{staticClass:"form-col-4",class:{ disabled: _vm.loading },attrs:{"id":_vm.stepData.fields.promo.id,"label":_vm.stepData.fields.promo.label || 'test',"placeholder":_vm.stepData.fields.promo.placeholder,"error":!!_vm.errors.promo || _vm.$v.formInfo.promo.$error,"error-text":_vm.errors.promo
              ? _vm.errors.promo.messages[0]
              : _vm.stepData.fields.promo.error,"loader":_vm.promoLoading},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.promo, 'promo')},"blur":_vm.checkPromo},model:{value:(_vm.$v.formInfo.promo.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.promo, "$model", $$v)},expression:"$v.formInfo.promo.$model"}})],1):_vm._e(),(!_vm.dataUser.wayIndex)?_c('div',{staticClass:"wio-form-row"},[_c('div',{class:[
            'wio-input-wrapper',
            'form-col-3',
            { 'read-only read-only-opacity': _vm.formInfo.euroNumber },
            { filled: _vm.formInfo.city },
            { error: _vm.$v.formInfo.city.$error },
            { disabled: _vm.loading },
          ],attrs:{"data-key":"city"}},[(_vm.stepData.fields.city.label)?_c('label',{attrs:{"for":_vm.stepData.fields.city.id}},[_vm._v(" "+_vm._s(_vm.stepData.fields.city.label)+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]):_vm._e(),_c('v-select',{attrs:{"id":_vm.stepData.fields.city.id,"push-tags":"","placeholder":_vm.stepData.fields.city.placeholder,"options":_vm.placeOptions,"label":"name","filterable":false},on:{"search":_vm.onSearch},model:{value:(_vm.$v.formInfo.city.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.city, "$model", $$v)},expression:"$v.formInfo.city.$model"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s("notFaund")+" ")])]),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.stepData.fields.city.error))])],1),_c('div',{class:[
            'wio-input-wrapper',
            'select-list',
            'form-col-3',
            { filled: _vm.formInfo.vehicle },
            { error: _vm.$v.formInfo.vehicle.$error },
            { disabled: _vm.loading },
          ],attrs:{"data-key":"vehicle"}},[(_vm.stepData.fields.vehicle.label)?_c('label',{attrs:{"for":_vm.stepData.fields.vehicle.id}},[_vm._v(" "+_vm._s(_vm.stepData.fields.vehicle.label)+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]):_vm._e(),_c('v-select',{attrs:{"id":_vm.stepData.fields.vehicle.id,"searchable":false,"placeholder":_vm.stepData.fields.vehicle.placeholder,"options":_vm.getIngineList,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(option.listTitle)?_c('div',{staticClass:"options-group-title"},[_vm._v(" "+_vm._s(option.listTitle)+" ")]):_vm._e(),_c('div',{staticClass:"option-wrapper"},[_c('div',{class:[
                    'option-icon',
                    `icon-category_${_vm.setVehicleIcon(option.icon)}`,
                  ]}),_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(option.name)+" ")])])]}},{key:"selected-option",fn:function({ icon, name }){return [_c('div',{staticClass:"option-wrapper selected"},[_c('div',{class:[
                    'option-icon',
                    `icon-category_${_vm.setVehicleIcon(icon)}`,
                  ]}),_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(name)+" ")])])]}}],null,false,3299533618),model:{value:(_vm.$v.formInfo.vehicle.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.vehicle, "$model", $$v)},expression:"$v.formInfo.vehicle.$model"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.stepData.fields.vehicle.error))])],1),_c('div',{class:[
            'wio-input-wrapper form-col-3',
            { filled: _vm.formInfo.type },
            { error: _vm.$v.formInfo.type.$error },
            { disabled: _vm.loading },
          ],attrs:{"data-key":"type"}},[(_vm.stepData.fields.type.label)?_c('label',[_vm._v(" "+_vm._s(_vm.stepData.fields.type.label)+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]):_vm._e(),_c('v-select',{attrs:{"searchable":false,"placeholder":_vm.stepData.fields.type.placeholder,"options":_vm.stepData.fields.type.values,"label":"name"},model:{value:(_vm.$v.formInfo.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.type, "$model", $$v)},expression:"$v.formInfo.type.$model"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.stepData.fields.type.error))])],1),_c('UIInput',{staticClass:"form-col-3",class:{ disabled: _vm.loading },attrs:{"id":_vm.stepData.fields.promo.id,"label":_vm.stepData.fields.promo.label || 'test',"placeholder":_vm.stepData.fields.promo.placeholder,"error":!!_vm.errors.promo || _vm.$v.formInfo.promo.$error,"error-text":_vm.errors.promo
              ? _vm.errors.promo.messages[0]
              : _vm.stepData.fields.promo.error,"data-key":"promo","loader":_vm.promoLoading},on:{"input":function($event){return _vm.delayTouch(_vm.$v.formInfo.promo, 'promo')},"blur":_vm.checkPromo},model:{value:(_vm.$v.formInfo.promo.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.promo, "$model", $$v)},expression:"$v.formInfo.promo.$model"}})],1):_vm._e(),_c('div',{staticClass:"wio-form-row"},[_c('div',{staticClass:"wio-custom-input-wrapper"},[_c('UIInput',{class:_vm.classListForPrivilegesBox,attrs:{"id":_vm.stepData.fields.privilegesBox.id,"label":`${_vm.stepData.fields.privilegesBox.label} ${_vm.getPrivilegesValue}`,"type":"checkbox","tooltip":_vm.stepData.fields.privilegesBox.tooltip},model:{value:(_vm.formInfo.privilegesBox),callback:function ($$v) {_vm.$set(_vm.formInfo, "privilegesBox", $$v)},expression:"formInfo.privilegesBox"}}),_c('div',{class:[
              'input-wrapper select-wrapper',
              { 'read-only': _vm.ifTypeLegal },
              { filled: _vm.formInfo.privileges },
              { error: _vm.$v.formInfo.privileges.$error },
              { disabled: _vm.loading },
            ]},[_c('v-select',{attrs:{"searchable":false,"placeholder":_vm.stepData.fields.privileges.placeholder,"options":_vm.stepData.fields.privileges.values,"label":"name"},model:{value:(_vm.$v.formInfo.privileges.$model),callback:function ($$v) {_vm.$set(_vm.$v.formInfo.privileges, "$model", $$v)},expression:"$v.formInfo.privileges.$model"}})],1)],1),(_vm.stepData.fields.euroNumber)?_c('UIInput',{staticClass:"form-col-auto checkbox-new-style",class:{ disabled: _vm.loading },attrs:{"id":_vm.stepData.fields.euroNumber.id,"label":_vm.stepData.fields.euroNumber.label,"type":"checkbox","tooltip":_vm.stepData.fields.euroNumber.tooltip},model:{value:(_vm.formInfo.euroNumber),callback:function ($$v) {_vm.$set(_vm.formInfo, "euroNumber", $$v)},expression:"formInfo.euroNumber"}}):_vm._e(),(_vm.stepData.fields.taxi)?_c('UIInput',{staticClass:"form-col-auto checkbox-new-style",class:{ disabled: _vm.loading },attrs:{"id":_vm.stepData.fields.taxi.id,"label":_vm.stepData.fields.taxi.label,"type":"checkbox","tooltip":_vm.stepData.fields.taxi.tooltip},model:{value:(_vm.formInfo.taxi),callback:function ($$v) {_vm.$set(_vm.formInfo, "taxi", $$v)},expression:"formInfo.taxi"}}):_vm._e()],1),_c('div',{staticClass:"wio-btn-wrapper btn-center"},[_c('UIButton',{staticClass:"btn-green",attrs:{"text":_vm.stepData.btnText,"loader":_vm.loading},on:{"click":_vm.submitHandler}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="attention">
    <span class="attention__icon icon-explanation" />
    <div class="attention__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AttentionComponent",
};
</script>

<style lang="scss" scoped>
.attention {
  background-color: rgba($orange, 0.1);
  border: 1px solid rgba($orange, 0.2);
  border-radius: $input-border-radius;
  padding: 10px;
  font-size: 10px;
  display: flex;
  margin: 0;
  text-align: left;

  @include media(">=md") {
    font-size: $base-font-size-s;
    padding: 20px;
  }

  &__icon {
    width: 40px;
    display: flex;
    align-items: center;
    font-size: 27px;
    color: $orange;

    @include media(">=md") {
      width: 50px;
      font-size: 33px;
    }
  }

  &__content {
    width: 100%;
    max-width: calc(100% - 40px);
    line-height: 1.5em;
    display: flex;
    align-items: center;

    @include media(">=md") {
      max-width: calc(100% - 50px);
    }

    p {
      margin: 0;
    }
  }
}
</style>

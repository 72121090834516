<template>
  <div :class="['wio-input-wrapper otp-code', { 'otp-code-focus': focusFlag }]">
    <label for="code-otp">
      <!-- inputmode="numeric" -->
        <!-- type="number" -->
        <!-- pattern="/^[A-Z1-5]{8}$/" -->
        <input
        id="code-otp"
        ref="input"
        @input="changeHandler"
        @focus="focusFlag = true"
        @blur="focusFlag = false"
        @keypress.enter="$emit('codeEnter')"
        v-mask="inputMasks.otpCode"
      />
      <div class="number-wrapper">
        <span
          v-for="index in length"
          :key="index"
          :ref="`number${index}`"
          :class="['number', { active: index === value.length + 1 }]"
        >
        {{ inputValueArray[index - 1] }}
          <!-- {{
            Number.isInteger(+inputValueArray[index - 1])
              ? inputValueArray[index - 1]
              : ''
          }} -->
        </span>
      </div>
    </label>
  </div>
</template>

<script>

import inputMasks from '@/helper/inputsMasks'

export default {
  name: 'InputCode',
  props: {
    length: {
      type: Number,
      default: 6
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      inputValue: '',
      focusFlag: false,
      inputMasks: inputMasks
    }
  },
  computed: {
    inputValueArray () {
      return `${this.inputValue}`.split('')
    }
  },
  watch: {
    value (newVal) {
      if (this.inputValue !== newVal) {
        this.inputValue = newVal
        this.$refs.input.focus()
        this.$refs.input.value = newVal
      }
    }
  },
  mounted () {
    this.$refs.input.focus()
  },
  methods: {
    changeHandler (e) {
      e.target.value = e.target.value.toUpperCase()
      const value = e.target.value

      // if (Number.isInteger(+value) && value.length <= this.length) {
        this.inputValue = value
      // }
      this.$emit('input', this.inputValue)
      e.target.value = this.inputValue
    }
  }
}
</script>

<style lang="scss" scoped>
.otp-code {
  position: relative;
  max-width: 420px;
  margin: 0 auto 15px;
  padding: 0 !important;
  overflow: hidden;

  &.code-error {
    .number {
      border-color: $red;
    }
  }

  &.otp-code-focus {
    .number {
      &.active {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 4px;
          top: 4px;
          bottom: 4px;
          width: 2px;
          background: $primary;
          animation: focus 0.8s;
          animation-iteration-count: infinite;

          @keyframes focus {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 1;
            }
            51% {
              opacity: 0;
            }
            100% {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

input {
  position: absolute;
  top: 0;
  right: -25px;
  left: 0;
  bottom: 7px;
  opacity: 0;
}

.number-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 25px;
  color: $primary;
  border: 1px solid $grey-400;
  background: $white;
  border-radius: $input-border-radius;

  @include media('>=md') {
    height: 44px;
    width: 57px;
  }

  & + & {
    margin-left: 11px;

    @include media('>=md') {
      margin-left: 15px;
    }
  }
}
</style>

<template>
  <div class="flags-list">
    <div
      v-for="flag in newList"
      :key="flag.label"
      :class="['flags-list__flag', `flags-list__${flag.color}`]"
    >
      {{ flag.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferFlagsList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    newList() {
      const list = JSON.parse(JSON.stringify(this.list));
      // list.push({
      //   name: 'purpose',
      //   value: 'Инполис рекомендует'
      // })
      // list.push({
      //   name: 'leader',
      //   value: 'Лучшее предложение'
      // })
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.flags-list {
  display: flex;
  margin: -14px -5px 0;
  padding: 0;
  flex-wrap: wrap;
  max-width: 100%;

  @include media(">=md") {
    white-space: nowrap;
    padding: 0 40px 0;
  }

  &__flag {
    display: inline-flex;
    border-radius: 30px;
    background: $orange;
    padding: 6px 10px;
    color: $white;
    font-weight: 600;
    font-size: $base-font-size;
    line-height: 1.2;
    justify-content: center;
    margin: 5px 5px 0;
    white-space: nowrap;
    min-width: 156px;

    @include media(">=md") {
      font-size: $base-font-size;
    }
  }

  &__yellow {
    background: #d1ff85;
    color: #577d1b;
  }

  &__blue {
    background: #c9e8ff;
    color: #007ad1;
  }

  &__green {
    background: #cfffd4;
    color: #007a0d;
  }

  &__pink {
    color: #b200ad;
    background: #ffdbfe;
  }

  &__red {
    color: #002c98;
    background: #cfddff;
  }

  &__purple {
    color: #523ec6;
    background: #e0dbff;
  }

  &__orange {
    color: #a46300;
    background: #ffebcd;
  }
}
</style>

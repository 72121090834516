<template>
  <div
    :class="['proposal-card', { 'proposal-action': currentValue }]"
    @click="currentValue = !currentValue"
  >
    <!-- :style="`background-image: url(${imgUrl})`" -->
    <div class="proposal-card__label">
      <span class="icon-fire" />
      <span>{{ label }}</span>
    </div>
    <div class="proposal-card__title">
      {{ title }}
    </div>
    <div class="proposal-card__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProposalComponent",
  props: {
    dataObject: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: false,
    },
    color: {
      tyep: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    label() {
      return this.dataObject?.label;
    },
    title() {
      return this.dataObject?.title;
    },
    text() {
      return this.dataObject?.text;
    },
    imgUrl() {
      return this?.dataObject?.file?.src;
    },
  },
  watch: {
    currentValue() {
      this.$emit("input", this.currentValue);
    },
    value() {
      this.currentValue = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.proposal-card {
  position: relative;
  overflow: hidden;
  border: 1px solid $grey-200;
  border-radius: $base-border-radius;
  width: 100%;
  min-height: 141px;
  padding: 20px 15px 20px;
  transition: $transition;
  cursor: pointer;
  background: linear-gradient(259deg, #2970a3 -30.14%, $primary 78.4%);

  @include media(">md") {
    border-radius: $base-border-radius-s;
    padding: 15px 15px 5px;
  }

  &:hover {
    box-shadow: 0 0 8px 0 rgba($primary, 0.25);
  }

  &.proposal-action {
    border-color: $primary;
  }

  &__label {
    font-size: $base-font-size-s;
    display: flex;
    align-items: center;
    color: $white;
    margin-bottom: 15px;
    opacity: 0.7;

    @include media("<md") {
      justify-content: center;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      color: $orange;
      font-size: $base-font-size;
      margin-right: 5px;
      opacity: 1;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 1.5;
    color: $white;
    margin-bottom: 5px;

    @include media("<md") {
      text-align: center;
    }

    @include media(">=md") {
      max-width: calc(100% - 100px);
    }
  }

  &__text {
    font-size: $base-font-size;
    color: $white;
    opacity: 0.7;

    @include media("<md") {
      text-align: center;
    }
  }
}
</style>

<template>
  <div class="franchise">
    <div
      v-if="title"
      :class="[
        'franchise__title',
        { 'franchise__title--with-tooltip': tooltip }
      ]"
    >
      <div class="franchise__title--text">
        {{ title }}
      </div>
      <UIToolTip v-if="tooltip" :tool-tip-data="tooltip" />
    </div>
    <div class="franchise__list">
      <div v-for="item in franchiseList" :key="item.id" class="franchise__item">
        <UIInput
          :id="item.id"
          v-model="franchiseValue"
          :name="id + ''"
          :label="item.text"
          :type="'radio'"
          :input-value="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferFranchise',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    dataObject: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      franchiseValue: null
    }
  },
  computed: {
    franchiseList () {
      const franchise = JSON.parse(JSON.stringify(this.dataObject?.list))
      // franchise.push({
      //   id: franchise[0].id + 'test',
      //   price: {
      //     currency: 'грн',
      //     oldPrice: 0,
      //     price: '2500 грн'
      //   },
      //   text: '2500 грн'
      // })
      return franchise
    },
    title () {
      return this.dataObject?.title || 'Выберите сумму франшизы'
    },
    tooltip () {
      return this.dataObject?.tooltip || 'test tooltip'
    },
    id () {
      return this.dataObject?.id
    }
  },
  watch: {
    franchiseValue (value) {
      this.$emit('input', value)
    },
    franchiseList () {
      this.setDefaultValue()
    }
  },
  created () {
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue () {
      if (this.dataObject?.value) {
        this.franchiseValue = this.dataObject?.value
      } else if (this.franchiseList.length) {
        this.franchiseValue = this.franchiseList[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.franchise {
  &__title {
    display: flex;
    font-size: $base-font-size-s;
    color: $grey-800;
    margin-bottom: 2px;

    .tool-tip {
      margin-left: 6px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .wio-input-wrapper {
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <div class="wio" ref="widget-step-wrapper" id="inpolis-osago" style="min-height: 340px">
    <Transition>
      <div v-if="firstLoading" class="first-loading">
        <UILoader :size="15" />
      </div>
    </Transition>
    <div class="wio-step-content">
      <Transition mode="out-in">
        <component
          v-if="stepData"
          :is="activeStepComponent"
          @changeStep="goNextStep"
          @prevStep="goBack"
          :loading="stepLoading"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import Loader from "@/components/UI/Loader";
import Stepper from "@/components/UI/Stepper";
import Step1 from "@/components/Step1";
import Step2 from "@/components/Step2";
import Step3 from "@/components/Step3";
import Step4 from "@/components/Step4";
import Step5 from "@/components/Step5";
import Step6 from "@/components/Step6";
import Step7 from "@/components/Step7";
import Step8 from "@/components/Step8";
import Step9 from "@/components/Step9";
import setGlobalCollors from "@/helper/cssVaribles";
import CONSTANTS from "@/helper/constants";
import axios from "axios";
import { getCookie } from "@/helper/cookie"

export default {
  name: "OsagoWidget",
  components: {
    Loader,
    Stepper,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
  },
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  data() {
    return {
      loading: true,
      stepLoading: false,
      firstLoading: true,
      validLands: {
        ua: true,
        ru: true,
        en: true,
      },
    };
  },
  async created() {
    const lang = this?.$root?.lang;
    const tokenId = this?.$root?.token;
    const internetSalesToken = this?.$root?.internetSalesToken
    const telegramSign = this?.$root?.telegramSign
    const telegramToken = this?.$root?.telegramToken
    const telegramGroup = this?.$root?.telegramGroup
    const backUrl = this?.$root?.backUrl
    const redirectUrl = this?.$root?.redirectUrl

    this.checkLocalStorage()
    this.checkUserKey()

    if(backUrl){
      this.setBackUrl(backUrl)
    }

    if(redirectUrl){
      this.setRedirectUrl(redirectUrl)
    }

    if(telegramGroup){
      this.setTelegramGroup(telegramGroup)
    }

    if(telegramSign){
      this.setTelegramSign(telegramSign)
    }

    if(telegramToken){
      this.setTelegramToken(telegramToken)
    }

    if(internetSalesToken){
      this.setInternetSalesToken(internetSalesToken)
    }

    if (lang && this.validLands[lang]) {
      this.setLang(lang);
    }

    if (tokenId) {
      this.setTokenId(tokenId);
      await axios
        .get(`${CONSTANTS.USER_TOKEN}${tokenId}`)
        .then(({ data }) => {
          const primaryColor = data?.whiteLabeling?.color;
          if (primaryColor) {
            setGlobalCollors({
              primary: primaryColor,
            });
          } else {
            setGlobalCollors();
          }

          this.startWidget();
        })
        .catch((error) => {
          setGlobalCollors();
          this.startWidget();
          console.log(error);
        });
    } else {
      setGlobalCollors();
      this.startWidget();
    }
  },
  computed: {
    name() {
      return this.userStore.getName;
    },
    user() {
      return this.userStore.getUser;
    },
    globalStepInfo() {
      return this.stepStore.getStep;
    },
    step1() {
      return this.stepStore.getStep1;
    },
    step2() {
      return this.stepStore.getStep2;
    },
    step3() {
      return this.stepStore.getStep3;
    },
    step4() {
      return this.stepStore.getStep4;
    },
    step5() {
      return this.stepStore.getStep5;
    },
    step6() {
      return this.stepStore.getStep6;
    },
    step7() {
      return this.stepStore.getStep7;
    },
    step8() {
      return this.stepStore.getStep8;
    },
    step9() {
      return this.stepStore.getStep9;
    },
    // stepList() {
    //   return this?.globalStepInfo?.stepListTabs;
    // },
    activeIndex() {
      return this?.stepStore?.getActiveIndex || 0;
    },
    errors() {
      return this?.userStore?.getBackErrors;
    },
    activeStepComponent() {
      if (this.activeIndex === 0) return Step1;
      else if (this.activeIndex === 1) return Step2;
      else if (this.activeIndex === 2) return Step3;
      else if (this.activeIndex === 3) return Step4;
      else if (this.activeIndex === 4) return Step5;
      else if (this.activeIndex === 5) return Step6;
      else if (this.activeIndex === 6) return Step7;
      else if (this.activeIndex === 7) return Step8;
      else if (this.activeIndex === 8) return Step9;
      return Step1;
    },
    stepData() {
      if (this.activeIndex === 0) return this.step1;
      else if (this.activeIndex === 1) return this.step2;
      else if (this.activeIndex === 2) return this.step3;
      else if (this.activeIndex === 3) return this.step4;
      else if (this.activeIndex === 4) return this.step5;
      else if (this.activeIndex === 5) return this.step6;
      else if (this.activeIndex === 6) return this.step7;
      else if (this.activeIndex === 7) return this.step8;
      else if (this.activeIndex === 8) return this.step9;
      return this.step1;
    },
  },
  mounted(){
    //fondy
    let c = document.createElement("script");
    c.async = false;
    c.src = "https://pay.fondy.eu/static_common/v1/checkout/ipsp.js";
    document.head.append(c);
    //portmone
    // let b = document.createElement("script");
    // b.async = false;
    // b.src = "https://www.portmone.com.ua/r3/resources/pg/js/asset/pg.min.js";
    // document.head.append(b);
  },
  methods: {
    ...mapActions(stepData, [
      "fetchStep1",
      "fetchStep2",
      "fetchStep3",
      "fetchStep4",
      "fetchStep5",
      "fetchStep6",
      "fetchStep7",
      "fetchStep8",
      "fetchStep9",
      "setActiveIndex",
    ]),
    ...mapActions(userData, [
      "setLang", 
      "setUserOne", 
      "setTokenId", 
      'setInternetSalesToken', 
      'setUserKey',
      'setTelegramSign',
      'setTelegramToken',
      'setTelegramGroup',
      'setBackUrl',
      'setRedirectUrl',
    ]),
    goNextStep() {
      if (this.activeIndex === 8) {
        this.setActiveIndex(0);
        return;
      }

      if (this.activeIndex < 5) {
        this.removeContractData();
      }

      if (this.activeIndex === 2 && this.user?.wayIndex === 0) {
        this.setUserOne({ key: "carNumber", payload: null });
      }

      this.stepLoading = true;
      this[`fetchStep${this.activeIndex + 2}`]().then((isError) => {
        if (isError && isError.anyErrors && this.activeIndex > 4) {
          if (this.step1) {
            this.setActiveIndex(0);
            this.autoScroll();
          } else {
            this.fetchStep1().then(() => {
              this.setActiveIndex(0);
              this.autoScroll();
            });
          }
          return;
        }

        if (!this.user.wayIndex && !this.activeIndex) {
          this.fetchStep3().then((isError2) => {
            this.stepLoading = false;

            if (!isError2) {
              this.setActiveIndex(2);
              this.autoScroll();
            }
          });
        } else if (!isError) {
          this.stepLoading = false;
          this.setActiveIndex(this.activeIndex + 1);
          this.autoScroll();
        } else {
          this.stepLoading = false;
        }
      });
    },
    autoScroll() {
      const widgetStepWrapper = this.$refs["widget-step-wrapper"];
      if (widgetStepWrapper && widgetStepWrapper.scrollIntoView) {
        widgetStepWrapper.scrollIntoView({ behavior: "smooth" });
      }
    },
    goBack() {
      if (this.activeIndex < 6) {
        this.removeContractData();
      }
      if (this.activeIndex) {
        let index = 0;

        if (this.activeIndex === 2 && !this.user.wayIndex) {
          index = 0;
        } else if (this.activeIndex > 0) {
          index = this.activeIndex - 1;
        }

        if (!this[`step${index + 1}`]) {
          this.stepLoading = true;
          this[`fetchStep${index + 1}`]().then((isError) => {
            this.stepLoading = false;

            if (!isError) {
              this.setActiveIndex(index);
            }
          });
        } else {
          this.setActiveIndex(index);
        }
      }
    },
    removeContractData() {
      this.setUserOne({
        key: "contractId",
        payload: null,
      });
      this.setUserOne({
        key: "otpCode",
        payload: null,
      });
      this.setUserOne({
        key: "contractNumber",
        payload: null,
      });
    },
    checkUserKey(){
        function createID(){
          let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
            }
          //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }

        const userKey = localStorage.getItem(CONSTANTS.USER_LOGS_KEY)
        if(userKey){
          this.setUserKey(userKey)
        } else {
          const ID = createID()
          this.setUserKey(ID)
          localStorage.setItem(CONSTANTS.USER_LOGS_KEY, ID);
        }
    },
    checkLocalStorage(){
      const ferstStepData = CONSTANTS.OSAGO.STEP_FIELDS_1
      ferstStepData.push('wayIndex')
      // const widgel_local_data = {
      //   userData:
      //   {
      //     wayIndex:1,
      //     carNumber:"AA1111CK",
      //     type:{
      //       id:"NATURAL",
      //       name:"Фізична особа"
      //     },
      //     city:{
      //       id:1,
      //       name:"Київ",
      //       nameFullRus:"Київ",
      //       placeCode:"1",
      //       zone:"ZONE_1"
      //     },
      //     filter3step:{},
      //     contractId:null,
      //     otpCode:null
      //   },
      //   activeIndex:1
      // }
      let localData = localStorage.getItem(CONSTANTS.OSAGO.WIDGET_STORAGE_NAME)
      if(!localData) return

      localData = JSON.parse(localData)

      ferstStepData.forEach( key =>{
        if(localData?.userData?.[key]){
          this.setUserOne({ key: key, payload: localData.userData[key] });
        }
      })
    },
    startWidget() {
      if(CONSTANTS.COOKIE_STATUS){
        const cookieActiveIndex = getCookie(CONSTANTS.COOKIE_ACTIVE_INDEX);

        if (cookieActiveIndex < 5) {
          this.removeContractData();
        }

        this.setActiveIndex(cookieActiveIndex);
        this[`fetchStep${cookieActiveIndex + 1}`]("firstLoading")
          .then((isError) => {
            this.firstLoading = false;
            if (isError && (isError?.anyErrors || isError?.requestError)) {
              if (this[`step${cookieActiveIndex + 2}`]) {
                this.setActiveIndex(0);
              } else {
                this.fetchStep1().then(() => {
                  this.setActiveIndex(0);
                });
              }
            }
          })
          .catch(() => {
            this.setActiveIndex(0);
            this[`fetchStep1`].then(() => {
              this.firstLoading = false;
            });
          });
      }
      
      let localData = localStorage.getItem(CONSTANTS.OSAGO.WIDGET_STORAGE_NAME)

      if(localData) {
        localData = JSON.parse(localData)
      }
      
      this.fetchStep1(localData?.userData).then(() => {
        this.setActiveIndex(0);
        this.firstLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.wio-step-content {
  padding: 30px 20px 30px;

  @include media(">=md") {
    padding: 40px 40px 30px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

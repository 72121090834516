<template>
  <div
    v-if="toolTipData"
    v-tippy="{ onShow: () => !!toolTipData }"
    :content="toolTipData"
    class="tool-tip"
  >
    <span class="icon-question" />
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    toolTipData: {
      require: true,
      default: '',
      type: String || Object
    }
  }
}
</script>

<style lang="scss" scoped>
.tool-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  border: 1px solid $secondary;
  border-radius: 50%;
  color: $secondary;
  cursor: pointer;
  transition: $transition;
  font-size: 10px;

  &:hover {
    border-color: $primary;
    color: $primary;
  }
}
</style>

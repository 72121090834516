<template>
  <div class="wio-step wio-step-9">
    <UIStepHeading
      :items="stepList"
      :active-index="5"
      :way-flag="dataUser.wayIndex"
      :class="{ 'loading-disabled': loading }"
    />
    <div class="heading-wrapper">
      <h3>{{ stepData.title }}</h3>
    </div>
    <div class="sub-title" v-html="stepData.subTitle" />
    <div class="result-content">
      <div class="polic-info">
        <div class="icon icon-document" />
        <div class="text-content">
          {{ stepData.polic.text }}
        </div>
      </div>
      <div class="home-btn-wrapper">
        <UIButton
          :text="stepData.btnHome.text"
          @click="goHome"
          class="btn-home"
        >
          <template #icon>
            <span class="icon icon-home" />
          </template>
        </UIButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
// import CONSTANTS from '@/helper/constants'
// import axios from 'axios'

export default {
  name: "wioStep9",
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    dataUser() {
      return this.userStore.getUser;
    },
    stepData() {
      return this.stepStore.getStep9;
    },
    ifBackErrors() {
      let result = false;

      if (!Object.keys(this.errors).length) {
        return result;
      }

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key]) {
          result = true;
        }
      });

      return result;
    },
    errors() {
      return this.userStore?.getBackErrors;
    },
    stepList() {
      return this.stepData.stepList;
    },
  },
  methods: {
    ...mapActions(userData, ["setBackError", "resetAll"]),
    ...mapActions(stepData, ["setActiveIndex", "fetchStep1"]),
    goHome() {
      if(userData().backUrl){
        window.location.href = userData().backUrl;
      } else {
        if (this.stepStore.getStep1) {
        this.resetAll()
        this.setActiveIndex(0)
      } else {
        this.fetchStep1().then(() => {
          this.resetAll()
          this.setActiveIndex(0)
        });
      }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wio-step-9 {
  max-width: 744px;
  margin: 0 auto;

  .result-content {
    @include media("<md") {
      max-width: 450px;
      margin: 0 auto;
    }
    @include media(">=md") {
      padding: 0 55px;
    }
  }

  .title {
    margin-bottom: 10px;

    @include media(">=md") {
      margin-bottom: 25px;
    }
  }

  .sub-title {
    font-size: $base-font-size-s;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;

    @include media(">=sm") {
      font-size: $base-font-size;
    }

    @include media(">=md") {
      font-size: 18px;
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 0;
    }

    ::v-deep a {
      color: $secondary;
    }
  }

  .polic-info {
    background: $grey-100;
    border-radius: $base-border-radius-s;
    padding: 10px;
    display: flex;
    align-items: center;

    @include media(">=md") {
      padding: 20px;
      margin-bottom: 10px;
    }

    @include media("<md") {
      flex-wrap: wrap;
    }

    .icon {
      height: 36px;
      width: 36px;
      min-width: 36px;
      border-radius: $base-border-radius-s;
      background: $grey-200;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;

      @include media(">=md") {
        height: 50px;
        width: 50px;
        min-width: 50px;
        font-size: 26px;
        margin-right: 15px;
      }
    }

    .text-content {
      font-size: $base-font-size;

      @include media("<md") {
        width: calc(100% - 51px);
        margin-right: 0;
        font-size: $base-font-size-s;
      }

      @include media(">=md") {
        max-width: 280px;
        margin-right: 15px;
      }
    }

    .polic-btn {
      max-width: 200px;
      margin-left: auto;
      text-decoration: none !important;

      @include media("<md") {
        min-width: 170px;
        margin: 20px auto 10px;
        max-width: 280px;
      }
    }
  }

  .add-text {
    // font-size: $base-border-radius-s;
    font-size: $base-font-size;
    margin-bottom: 20px;

    @include media("<md") {
      display: none;
    }
  }

  .add-card {
    background: $secondary;
    border-radius: $base-border-radius-m;
    padding: 25px 20px 20px;
    background-size: cover;

    @include media(">=md") {
      padding: 30px 30px 60px;
      border-radius: $base-border-radius-s;
      margin-bottom: 40px;
    }

    .add-title {
      font-size: 24px;
      color: $white;
      line-height: 1.6em;

      @include media(">=md") {
        font-size: 36px;
      }

      @include media(">=lg") {
        font-size: 44px;
      }
    }

    .add-sub-title {
      color: $white;
      margin-bottom: 30px;
      font-size: $base-font-size-s;

      @include media("<md") {
        font-weight: 500;
      }

      @include media(">=md") {
        font-size: 14px;
      }

      @include media(">=lg") {
        font-size: 16px;
      }
    }

    .add-btn {
      max-width: 247px;
      padding-left: 5px;
      padding-right: 10px;

      @include media("<md") {
        margin: 0 auto;
      }

      &:hover {
        border-color: $white;
      }

      .icon {
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }

  .home-btn-wrapper {
    display: flex;
    justify-content: center;
  }
  .btn-home {
    margin: 0 auto;
    font-size: $base-font-size;
    color: $grey-800;
    transition: $transition;
    text-decoration: none;
    background: transparent;
    border: none;

    &:hover {
      color: $primary;
      background: transparent;
      border: none;
    }

    .icon-home {
      margin-right: 8px;
      margin-top: -1px;
      font-size: 14px;
    }
  }

  .heading-wrapper {
    text-align: center;
  }
}
</style>
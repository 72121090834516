import { defineStore } from 'pinia'
import axios from 'axios'
import CONSTANTS from '@/helper/constants'
import { userData } from '@/store/userData'
import { cloneDeep } from 'lodash'
import {
  getCookie,
  setCookie,
  // setUserDataToCookie
} from '@/helper/cookie'
import Vue from 'vue'

function showError (error) {
  Vue.swal({
    icon: 'error',
    title: 'Oops...',
    text: error || 'Some error',
    target: '#inpolis-osago'
  })
}

function isEmpty(value) {
  return value === undefined || value === null;
}

export const stepData = defineStore({
  id: 'stepData',
  state: ()=>({
    step: null,
    step1: null,
    step2: null,
    step3: null,
    step4: null,
    step5: null,
    step6: null,
    step7: null,
    step8: null,
    step9: null,
    activeIndex: 0,
  }),
  getters: {
    getStep: (state) => state.step,
    getStep1: (state) => state.step1,
    getStep2: (state) => state.step2,
    getStep3: (state) => state.step3,
    getStep4: (state) => state.step4,
    getStep5: (state) => state.step5,
    getStep6: (state) => state.step6,
    getStep7: (state) => state.step7,
    getStep8: (state) => state.step8,
    getStep9: (state) => state.step9,
    getActiveIndex: (state) => state.activeIndex
  },
  actions: {
    setActiveIndex(number){
      if(CONSTANTS.COOKIE_STATUS){
        setCookie(CONSTANTS.COOKIE_ACTIVE_INDEX, number)
      }
      this.activeIndex = number
    },
    async fetchStep1(payload){ 
      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/1`,{
        params: {
          key: userData()?.getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        if(data){
          this.step1 = data
          this.step = data
        }

        if(CONSTANTS.COOKIE_STATUS){
          const cookieData = getCookie(CONSTANTS.COOKIE_NAME) || {}

          if(!isEmpty(cookieData?.wayIndex)){
            userData().setUserOne({
              payload: cookieData.wayIndex,
              key: 'wayIndex',
            })
          }
        }

        CONSTANTS?.OSAGO.STEP_FIELDS_1?.forEach((key) => {
          const value = data?.fields[key]?.value
          const localData = payload?.[key]

          if(key === 'type'){
            userData().setUserOne({
              payload: localData || value || null,
              key
            })
          } else if(key === 'wayIndex') {
            userData().setUserOne({
              payload: isEmpty(localData) ? isEmpty(value) ? 1 : value : localData,
              key
            })
          } else {
            userData().setUserOne({
              payload: isEmpty(localData) ? isEmpty(value) ? null : value : localData,
              key
            })
          }
        })

        if(
          payload?.wayIndex === 0 || 
          payload?.wayIndex === "0" || 
          payload?.wayIndex === 1 || 
          payload?.wayIndex === "1"
          ){ 
            userData().setUserOne({
              payload:payload.wayIndex,
              key: 'wayIndex'
            })
          }
      }).catch(error => {
        alert('server error 1')
        console.log('fetch step 1 error => ',error)
      })
    },
    async fetchStep2(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/2`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        this.step2 = data

        if (data?.errors) {
          if (data?.unknownError) {
            showError(data.errors[0]?.messages[0])
            error = { anyErrors: true }
          } else {
            error = data.errors

            error.forEach((err) => {
              userData().setBackError({
                payload: {
                  messages: err?.messages
                },
                key: err?.name
              })
            })
          }
        } else {
          CONSTANTS?.OSAGO.STEP_FIELDS_2?.forEach((key) => {
            userData().setUserOne({
              payload: data?.fields[key]?.value || userData()?.getUser?.[key] || null,
              key
            })
          })
        }
        return error
      }).catch(error => {
        alert('server error 2')
        console.log('fetch step 2 error => ',error)
        return false
      })

      return error
    },
    async fetchStep3(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/3`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{

        this.step3 = data

        if (data?.errors) {
          if (data?.unknownError) {
            showError(data.errors[0]?.messages[0])
            error = { anyErrors: true }
          } else {
            error = data.errors

            error.forEach((err) => {
              userData().setBackError({
                payload: {
                  messages: err?.messages
                },
                key: err?.name
              })
            })
          }
        } 
        return error
      }).catch(error => {
        alert('server error 3')
        console.log('fetch step 3 error => ',error)
        return false
      })

      return error
    },
    async fetchStep4(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/4`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        this.step4 = data

        if (data?.errors) {
          if (data?.unknownError) {
            showError(data.errors[0]?.messages[0])
            error = { anyErrors: true }
          } else {
            error = data.errors

            error.forEach((err) => {
              userData().setBackError({
                payload: {
                  messages: err?.messages
                },
                key: err?.name
              })
            })
          }
        } else {
          CONSTANTS?.OSAGO.STEP_FIELDS_4?.forEach((key) => {
            key,userData().setUserOne({
              payload: data?.fields[key]?.value || null,
              key
            })
          })
        }
        return error
      }).catch(error => {
        alert('server error 4')
        console.log('fetch step 4 error => ',error)
        return false
      })

      return error
    },
    async fetchStep5(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/5`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        this.step5 = data

        if (data?.errors) {
          if (data?.unknownError) {
            showError(data.errors[0]?.messages[0])
            error = { anyErrors: true }
          } else {
            error = data.errors

            error.forEach((err) => {
              userData().setBackError({
                payload: {
                  messages: err?.messages
                },
                key: err?.name
              })
            })
          }
        } else {
          CONSTANTS?.OSAGO.STEP_FIELDS_5?.forEach((key) => {
            console.log(key, data?.fields[key]?.value)
            key,userData().setUserOne({
              payload: data?.fields[key]?.value || null,
              key
            })
          })
        }
        return error
      }).catch(error => {
        alert('server error 5')
        console.log('fetch step 5 error => ',error)
        return false
      })

      return error
    },
    async fetchStep6(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/6`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        this.step6 = data

        if (data?.errors) {
          if (data?.unknownError) {
            error = { anyErrors: true }
            showError(data.errors[0]?.messages[0])
          } else {
            error = data.errors

            error.forEach((err) => {
              userData().setBackError({
                payload: {
                  messages: err?.messages
                },
                key: err?.name
              })
            })
          }
        } else {
          if (data?.contractId) {
            userData().setUserOne({
              payload: data.contractId,
              key: 'contractId'
            })
          }
          if (data?.contract?.contractId) {
            userData().setUserOne({
              payload: data.contract.contractId,
              key: 'contractId'
            })
          }
          if (data?.fondyId) {
            userData().setUserOne({
              payload: data.fondyId,
              key: 'fondyId'
            })
          }
        }
        return error
      }).catch(error => {
        alert('server error 6')
        console.log('fetch step 6 error => ',error)
        error = {
          requestError: true
        }
        return false
      })

      return error
    },
    async fetchStep7(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/7`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        this.step7 = data

        if (data?.errors) {
          if (data?.unknownError) {
            showError(data.errors[0]?.messages[0])
            error = { anyErrors: true }
          } else {
            error = data.errors

            error.forEach((err) => {
              userData().setBackError({
                payload: {
                  messages: err?.messages
                },
                key: err?.name
              })
            })
          }
        } else {
          if (data?.fondyId) {
            userData().setUserOne({
              payload: data.fondyId,
              key: 'fondyId'
            })
          }
          if (data?.contractNumber) {
            userData().setUserOne({
              payload: data.contractNumber,
              key: 'contractNumber'
            })
          }
        }
        return error
      }).catch(error => {
        alert('server error 7')
        console.log('fetch step 7 error => ',error)
        return false
      })

      return error
    },
    async fetchStep8(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/8`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        this.step8 = data

        if (data?.errors) {
          if (data?.unknownError) {
            showError(data.errors[0]?.messages[0])
            error = { anyErrors: true }
          } else {
            error = data.errors

            error.forEach((err) => {
              userData().setBackError({
                payload: {
                  messages: err?.messages
                },
                key: err?.name
              })
            })
          }
        }
        return error
      }).catch(error => {
        alert('server error 8')
        console.log('fetch step 8 error => ',error)
        return false
      })

      return error
    },
    async fetchStep9(){ 
      let error;

      await axios.post(`${CONSTANTS.BASE_URL}osago/${userData().getLeng}/9`,{
        params: {
          userData: cloneDeep(userData().getUser),
          key: userData().getUserKey, 
          internetSalesToken: userData().getInternetSalesToken,
          token: userData()?.getTokenId,
          telegramToken: userData().getTelegramToken,
          telegramSign: userData().telegramSign,
          telegramGroup: userData().telegramGroup
        }
      }).then(({data})=>{
        this.step9 = data
      }).catch(error => {
        alert('server error 9')
        console.log('fetch step 9 error => ',error)
        return false
      })

      return error
    },
  }
})
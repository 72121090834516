<template>
  <img
    class="wio-img"
    :src="imageData.src"
    :alt="imageData.alt"
    :title="imageData.title"
    :srcset="srcset"
  />
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    imageData: {
      type: [Object, String],
      default: () => ({})
    }
  },
  computed: {
    srcset () {
      return this.imageData.srcMobile
        ? `${this.imageData.srcMobile} 768w, ${this.imageData.src} 4000w`
        : false
    }
  }
}
</script>

<style lang="scss" scoped>
.wio-img{
  display: block;
  width: 100%;
}
</style>
<template>
  <div class="wio-step wio-step-8">
    <div class="btn-back-wrapper" :class="{ 'loading-disabled': loading }">
      <UIButton
        :text="stepData.btnPrevText"
        class="btn-go-back"
        @click="$emit('prevStep')"
      />
    </div>
    <UIStepHeading
      :items="stepList"
      :active-index="5"
      :way-flag="dataUser.wayIndex"
      :class="{ 'loading-disabled': loading }"
    />
    <div class="form-wrapper">
      <iframe
        name="portmoneFrame"
        width="372"
        height="800"
        frameborder="0"
        class="portmoneFrame"
        ref="portmoneFrame"
        :style="`opacity: ${loading ? 0 : 1}`"
        >
      </iframe>
      <div v-if="loading" class="loader-wrapper">
        <UILoader/>
      </div>
      
    </div>
    <form
      action="https://www.portmone.com.ua/gateway/"
      method="post"
      target="portmoneFrame"
      accept-charset="utf-8"
    >
      <input type="hidden" name="bodyRequest" :value="paymentValue" />
      <input type="hidden" name="typeRequest" value="json" />
      <input ref="test" type="submit" value="Portmone.com" style="opacity: 0" />
    </form>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userData } from "@/store/userData";
import { stepData } from "@/store/stepData";
import CONSTANTS from "@/helper/constants";

export default {
  name: "wioStep8",
  setup() {
    const userStore = userData();
    const stepStore = stepData();
    return {
      userStore,
      stepStore,
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      error: false,
      socket: null,
      timeOutData: null
    };
  },
  created() {
    this.GTMPush()

    if (this?.stepData?.payLink) {
      this.userStore.setUserOne({
        key: "payLink",
        payload: this?.stepData?.payLink,
      });
    }
  },
  mounted() {
    const evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    this.$refs.test.dispatchEvent(evt);

    this.webSocketInit()
  },
  computed: {
    paymentValue() {
      console.log(this.stepData?.portmone)
      return JSON.stringify({
        ...this.stepData?.portmone,
         style:{
          type: "light",
          bcMain: "#ffffff",
          backgroundColorButtons: this.userStore.primaryColor,
          colorTextAndIcons: "#002947",
         }
        });
    },
    dataUser() {
      return this.userStore.getUser;
    },
    stepData() {
      return this.stepStore.getStep8;
    },
    ifBackErrors() {
      let result = false;

      if (!Object.keys(this.errors).length) {
        return result;
      }

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key]) {
          result = true;
        }
      });

      return result;
    },
    errors() {
      return this.userStore?.getBackErrors;
    },
    errorText() {
      return this.stepData?.paymentErrorText || "front fake error text";
    },
    stepList() {
      return this.stepData.stepList;
    },
  },
  methods: {
    ...mapActions(userData, ["setBackError"]),
    webSocketInit(){
      if(window.WebSocket){
        this.socket = new window.WebSocket(`${CONSTANTS.BASE_URL.replace(/(http)(s)/, "wss").replace("api/", "pws/")}Fvev5c0k59VZ1jRLWuj5URyF5idSdHWm`);

        // Connection opened
        this.socket.addEventListener("open", () => {
          this.timeOutData = setInterval(()=>{
            this.socket.send(JSON.stringify({
              event: "pusher:ping",
              data: {}
            }));
          },30000)
          this.socket.send(JSON.stringify({
            event: "pusher:subscribe",
            data: {
                channel: `SendMessage_${this.dataUser.contractNumber}`,
                channel_data: {
                  contractId:1,
                  status:0
                }
            }
          }));
        });

        // Listen for messages
        this.socket.addEventListener("message", (event) => {
          const res = JSON.parse(event.data || "");
          if(res.data){
            const status = JSON.parse(res.data || "")?.status
            if(status === 'success'){
              this.GTMPushPurchase()
              if(userData().redirectUrl){
                window.location.href = userData().redirectUrl;
                return
              } 
              this.$emit("changeStep");
            }
          }
        });

        this.socket.addEventListener("close", () => {
          clearInterval(this.timeOutData) 
        });
      }
    },
    GTMPush(){
      if(window && window.dataLayer){
        const self = this

        console.log('GTM add_payment_info')

        window.dataLayer.push({
          event: 'add_payment_info',
          ecommerce: {
            payment_type: 'Онлайн оплата',
            value: self.dataUser?.offer?.franchiseActive?.price?.price,
            currency: 'UAH',
            items: [{
              item_name: 'widget_osago',
              item_id: self.dataUser?.offer?.id,
              price: self.dataUser?.offer?.franchiseActive?.price?.price,
              item_brand: self.dataUser?.offer?.company?.title,
              item_category: self.dataUser?.type?.name,
              item_category2: self.dataUser?.vehicle?.name,
              item_category3: self.dataUser?.offer?.about?.list,
              item_variant: self.dataUser?.offer?.offerFranchise,
              quantity: 1
            }]
          }
        })
      }
    },
    GTMPushPurchase(){
      if(window && window.dataLayer){
        const self = this

        console.log('GTM purchase')

        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: self.dataUser?.contractId,
            contract_number: self.dataUser?.contractNumber,
            value: self.dataUser?.offer?.franchiseActive?.price?.price,
            currency: 'UAH',
            items: [{
              item_name: 'widget_osago',
              item_id: self.dataUser?.offer?.id,
              price: self.dataUser?.offer?.franchiseActive?.price?.price,
              item_brand: self.dataUser?.offer?.company?.title,
              item_category: self.dataUser?.type?.name,
              item_category2: self.dataUser?.vehicle?.name,
              item_category3: self.dataUser?.offer?.about?.list,
              item_variant: self.dataUser?.offer?.offerFranchise,
              quantity: 1
            }]
          }
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.wio-step-8 {
  position: relative;
  z-index: 2;
  min-height: 300px;

  .portmoneFrame{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border: 1px solid $grey-400;
    border-radius: $base-border-radius;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);
    max-width: 100% !important;
  }

  #frameholder {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .error {
    max-width: 1000px;
    margin: 0 auto;
  }

  .form-wrapper{
    position: relative;

    .loader-wrapper{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
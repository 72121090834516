import { defineStore } from 'pinia'

export const userData = defineStore({
  id: 'user',
  state: ()=>({
    user: {
      carNumber: null,
      wayIndex: 1,
    },
    backErrors: {},
    lang: 'ua',
    tokenId: null,
    primaryColor: null,
    internetSalesToken: null,
    key: null,
    telegramToken: null,
    telegramSign: null,
    telegramGroup: null,
    backUrl: null,
    redirectUrl: null,
  }),
  getters: {
    getUser: (state) => state.user,
    getBackErrors: (state) => state.backErrors,
    getLeng: (state) => state.lang,
    getTokenId: (state) => state.tokenId,
    getPrimaryColor: (state) => state.primaryColor,
    getInternetSalesToken: (state) => state.internetSalesToken,
    getUserKey: (state) => state.key,
    getTelegramToken: (state) => state.telegramToken,
    getTelegramSign: (state) => state.telegramSign,
    getTelegramGroup: (state) => state.telegramGroup,
    getBackUrl: (state) => state.backUrl,
    getRedirectUrl: (state) => state.redirectUrl,
  },
  actions: {
    createdUserKey(){
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        const newKey = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        this.user.key = newKey
    },
    setInternetSalesToken(token){
      this.internetSalesToken = token
    },
    setUserOne({key, payload}){
      this.user[key] = payload
    },
    setBackError({key, payload}){
      this.backErrors[key] = payload
    },
    setLang(lang){
      this.lang = lang
    },
    setTokenId(id){
      this.tokenId = id
    },
    setPrimaryColor(color){
      this.primaryColor = color
    },
    setUserKey(key){
      this.key = key
    },
    setTelegramToken(token){
      this.telegramToken = token
    },
    setTelegramSign(sign){
      this.telegramSign = sign
    },
    setTelegramGroup(group){
      this.telegramGroup = group
    },
    setBackUrl(url){
      this.backUrl = url
    },
    setRedirectUrl(url){
      this.redirectUrl = url
    },
    resetAll(){
      this.user = {
        wayIndex: 1,
      }
    }
  }
})
<template>
  <div class="price">
    <div :class="['price__old', { 'price__old--no-sell': !oldPrice }]">
      <div class="price__old--wrapper">
        <span class="price__old--price">{{ oldPrice || 0 }} </span>
        <span class="price__old--currency">{{ price.currency }}</span>
      </div>
      <div v-if="sellPercent" class="price__old--cell">
        -{{ sellPercent || 0 }}{{ sellCurrency || "%" }}
      </div>
      <div v-if="oldPrice && currentPrice" class="price__promo">
        <div class="price__promo--text">
          {{ `promo -${oldPrice - currentPrice} ${price.currency}` }}
        </div>
      </div>
    </div>
    <div :class="['price__main', { 'price__main--sell': oldPrice }]">
      <span class="price--price">{{ currentPrice }} </span>
      <span class="price--currency">{{ price.currency }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferPrice",
  props: {
    price: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
  computed: {
    currentPrice() {
      return this.price?.price;
    },
    oldPrice() {
      return this.price?.oldPrice;
    },
    sellPercent() {
      return this.price?.sell;
    },
    sellCurrency() {
      return this.price?.sellCurrency;
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__main {
    font-size: 30px;
    line-height: 1.25em;
    font-weight: 600;
    display: flex;
    flex-wrap: nowrap;

    @include media(">=lg") {
      font-size: 42px;
    }

    &--sell {
      color: $green-dark;
    }
  }

  &--currency {
    text-transform: lowercase;
    margin-left: 7px;
  }

  &__old {
    font-size: 14px;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;

    @include media("<md") {
      justify-content: flex-end;
      padding-right: 20px;
    }

    @include media(">=lg") {
      font-size: 16px;
    }

    &--wrapper {
      text-decoration-line: line-through;
      opacity: 0.5;
      display: flex;
      flex-wrap: nowrap;
    }

    &--cell {
      background: $green-dark;
      border-radius: 10px 0 10px 0;
      padding: 1px 5px;
      margin-left: 10px;
      margin-top: -2px;
      color: $white;
      font-size: 14px;
      min-width: 44px;
      display: flex;
      justify-content: center;
      line-height: 1.5em;

      @include media(">=lg") {
        padding: 5px;
        margin-top: -5px;
      }
    }

    &--no-sell {
      opacity: 0;
      visibility: none;

      @include media("<md") {
        display: none;
      }
    }
  }

  &__promo {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding: 4px 8px;
    border: 1px solid $primary;
    border-radius: $base-border-radius-s;
    margin: 7px 0 10px 10px;
    color: $primary;
    font-size: $base-font-size-s;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: $primary;
      opacity: 0.05;
      z-index: 1;
    }

    &--text {
      position: relative;
      z-index: 2;
    }
  }
}
</style>
